<template>
  <b-overlay :show="isLoading">
    <!-- Brightcove Player -->
    <video-js :id="brightcovePlayerId" :data-account="this.accountId" :data-player="this.playerId" :data-video-id="this.videoId" data-embed="default" controls="">
    </video-js>
  </b-overlay>
</template>

<script>
  export default {
    name: 'BrightcovePartnerPlayer',
    data() {
      return {
        isLoading: true,
        script: null,
        brightcovePlayerId: this.$uuid.v1(),
        brightcovePlayer: null,
      };
    },
    props: {
      accountId: String,
      playerId: String,
      videoId: String,
    },
    computed: {
      scriptUrl() {
        return `https://players.brightcove.net/${this.accountId}/${this.playerId}_default/index.min.js`;
      },
    },
    mounted() {
      this.script = document.createElement('script');
      this.script.onload = this.onScriptLoaded;
      this.script.setAttribute('src', this.scriptUrl);

      document.head.appendChild(this.script);
    },
    beforeDestroy() {
      screen.orientation.unlock();
      if (this.brightcovePlayer) this.brightcovePlayer.dispose();

      document.head.removeChild(this.script);
    },
    methods: {
      onScriptLoaded() {
        const self = this;
        // eslint-disable-next-line no-undef
        videojs.getPlayer(this.brightcovePlayerId).ready(function () {
          self.brightcovePlayer = this;

          self.brightcovePlayer.on('fullscreenchange', function () {
            if (self.brightcovePlayer.isFullscreen()) {
              setTimeout(function () {
                screen.orientation
                  .lock('landscape')
                  .then(function () {
                    console.error('landscape orientation locked');
                  })
                  .catch(function (error) {
                    console.error(error);
                  });
              }, 100);
            }
            else{
              screen.orientation.unlock();
            }
          });

          /*
          self.brightcovePlayer.on('loadedmetadata', function () {
            self.brightcovePlayer.requestFullscreen();
          });
          */

          self.isLoading = false;
          self.$emit('loaded');
        });
      },
    },
  };
</script>

<style lang="scss">
  .video-js {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 0px !important;
  }

  .b-skeleton-img {
    height: 100%;
  }
</style>
