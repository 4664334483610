import axios from 'axios';

import { consoleColors } from 'src/utils/console-color';

export const refreshPageMixin = {
  data() {
    return {
      currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
      newVersion: false,
      newHash: ''
    }
  },
  computed: {
    checkVersionFile() {
      return process.env.VUE_APP_CHECK_VERSION_FILE;
    }
  },
  methods: {
    initVersionCheck(frequency) {
      setInterval(() => {
        this.checkVersion();
      }, frequency);
    },
    async checkVersion() {
      try {
        const fileResponse = await axios.create({
          baseURL: `${window.location.origin}`,
          headers: {
            'Content-type': 'application/json'
          }
        }).get('/' + this.checkVersionFile + '?t=' + new Date().getTime());

        this.newHash = fileResponse.data.hash;

        this.newVersion = this.hasHashChanged(this.currentHash, this.newHash);
        if (this.newVersion)
          console.debug(`%cNewVersion: ${JSON.stringify({ currentHash: this.currentHash, newHash: this.newHash })}`,
            consoleColors.events);
      } catch (error) {
        if (!error.response) {
          this.errorStatus = 'Error: Network Error'
        } else {
          this.errorStatus = error.response.data.message;
        }
      }
    },
    hasHashChanged(currentHash, newHash) {
      if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
        return true;
      }

      return currentHash !== newHash;
    },
    reloadApp() {
      this.currentHash = this.newHash;
      window.location.reload();
    }
  }
};
