<template>
  <b-col cols="12" sm="12" md="12" lg="10" class="pt-4 pb-4">
    <template v-if="!networkingHasAccess">
      <NetworkingNoAccessWidget/>
    </template>
    <template v-if="networkingHasAccess && networkingChatAgreement">
      <b-col align-self="center" cols="12" md="12" class="p-4 mb-4 networkingSearch">
        <h3 class="mb-4">Participants</h3>
        <b-form inline>
          <label class="sr-only" for="inline-form-input-name">Name</label>
          <b-form-input
            v-model="nameValue"
            type="search"
            placeholder="Search for people and network"
            @keydown.native="onTextInputKeydown"
            class="mb-2 mb-sm-0 col-xl-3 col-sm-12"
          />

          <!-- Status -->
          <!--
        <b-col lg="2">
          <treeselect v-model="statusValues" :options="statusOptions" :multiple="true" :appendToBody="true" placeholder="Status" :limit="0" :limitText="count => `Status (Online)`" />
        </b-col>
        -->
          <!-- Country -->
          <b-col lg="3">
            <treeselect
              v-model="countriesValues"
              :options="countriesOptions"
              :multiple="true"
              placeholder="Countries/Regions"
              :appendToBody="true"
              :limit="0"
              :limitText="(count) => `Countries/Regions (${count} applied)`"
            />
          </b-col>
          <!-- Speciality -->
          <b-col lg="5">
            <treeselect
              v-model="specialitiesValues"
              :options="specialitiesOptions"
              :multiple="true"
              :appendToBody="true"
              placeholder="Specialities"
              :limit="0"
              :limitText="(count) => `Specialities (${count} applied)`"
            />
          </b-col>
          <b-col lg="1">
            <b-button variant="primary" @click="onSearchClick">Search</b-button>
          </b-col>
        </b-form>
      </b-col>

      <!-- 
      -------------------------------------------------------------------------------------------------
      Listing
      ------------------------------------------------------------------------------------------------- 
      -->
      <b-overlay id="resultsContainer" :show="isLoading" no-center rounded="sm">
        <b-col align-self="center" cols="12" class="p-4 networkingSearch" v-if="searchResults">
          <h4 class="mb-4">{{ totalRows }} results</h4>

          <b-col :key="result.id" v-for="result in searchResults" class="pt-4 userItem">
            <div class="session__details__header">
              <b-row class="pb-4">
                <b-col sm="2">
                  <b-avatar square :src="result.photoUrl" class="w-100"> </b-avatar>
                  <!--      
                <b-avatar badge badge-offset="-0.5em" square :src="result.photoUrl" class="w-100" :variant="result.isOnline ? 'success' : 'danger'">
                </b-avatar>
                -->
                  <!-- variant="danger" for offline variant="success" for online -->
                </b-col>
                <b-col sm="10">
                  <b-row>
                    <b-col lg="9">
                      <h5 class="mb-1">
                        {{ result.name }}
                        <!-- <a href="#" class="ml-1 mr-2"><i class="lab la-linkedin-in"></i></a>
                      <a href="#"><i class="lab la-twitter"></i></a> -->
                      </h5>
                      <!--
                    <p>
                      <b-badge pill variant="light">ESC Pro Member</b-badge>
                    </p>
                    -->
                      <p
                        :class="{
                          'mb-2': getPlaceOfWork(result.custom.institution, result.custom.country) !== '',
                          'mb-0': getPlaceOfWork(result.custom.institution, result.custom.country) === '',
                        }"
                      >
                        <fragment v-if="result.custom.specialty">
                          <b>Specialty:</b>
                          {{ result.custom.specialty }}
                        </fragment>
                      </p>

                      <p v-if="getPlaceOfWork(result.custom.institution, result.custom.country) !== ''" class="mb-0">
                        <b>Place of work: </b>
                        {{ getPlaceOfWork(result.custom.institution, result.custom.country) }}
                      </p>
                    </b-col>
                    <b-col lg="3" class="text-right">
                      <b-link
                        :to="`/${eventRoute}/networking/messages?r=${result.id}`"
                        :disabled="currentUser.escId && currentUser.escId == result.id"
                        class="btn btn-primary mb-3"
                      >
                        <i class="las la-comments mr-2"></i> Start a chat
                      </b-link>
                    </b-col>
                  </b-row>

                  <!--<p v-if="result.custom.quote" class="userQuote mb-1">
                  <span>
                    {{ result.custom.quote }}
                  </span>
                </p>-->

                  <p v-if="result.custom.linkedInUrl || result.custom.twitterUrl" class="userSocial">
                    <b>Follow me:</b>
                    <a v-if="result.custom.linkedInUrl" :href="result.custom.linkedInUrl" target="_blank" class="ml-1 mr-2"> <i class="lab la-linkedin-in"></i> </a>
                    <a v-if="result.custom.twitterUrl" :href="result.custom.twitterUrl" target="_blank"><i class="lab la-twitter"></i> </a>
                  </p>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-col>

        <!-- Pagination -->
        <b-pagination
          v-if="searchResults && searchResults.length > 0"
          @change="onPageChange"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          pills
          class="mt-3"
        ></b-pagination>
      </b-overlay>
    </template>

    <!-- Networking Consent -->
    <NetworkingConsent v-if="networkingHasAccess"/>
  </b-col>
</template>

<script>
import { MediaApiService } from 'src/services/media-api';
import { mapGetters, mapState } from 'vuex';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import NetworkingConsent from 'src/components/NetworkingConsent';
import NetworkingNoAccessWidget from 'src/components/Widgets/NetworkingNoAccessWidget';

export default {
  name: 'NetworkingSearch',
  components: { Treeselect, NetworkingConsent,NetworkingNoAccessWidget },
  data() {
    return {
      isLoading: true,
      nameValue: null,
      statusValues: null,
      statusOptions: [
        {
          id: 'online',
          label: 'Online',
        },
      ],
      countriesValues: null,
      countriesOptions: [],
      specialitiesValues: null,
      specialitiesOptions: [],
      searchResults: null,
      currentPage: 1,
      totalRows: 0,
      perPage: 20,
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapState({
      eventRoute: (state) => state.app.eventRoute,
      eventId: (state) => state.app.eventId,
      networkingChatAgreement: (state) => state.app.chatParticipantConfig.networkingChatAgreement,
      networkingHasAccess: (state) => state.app.chatParticipantConfig.hasAccess
    }),
  },
  async mounted() {
    const { data: countriesData } = await MediaApiService.getCountries();
    this.countriesOptions = countriesData;

    const { data: specialitiesData } = await MediaApiService.getSpecialities();
    this.specialitiesOptions = specialitiesData;

    await this.search();
  },
  methods: {
    onTextInputKeydown(e) {
      if (e.which === 13) {
        this.onSearchClick();
        return;
      }
    },
    async onSearchClick() {
      this.currentPage = 1;
      this.isLoading = true;
      await this.search();
    },
    async search() {
      const countries = this.countriesValues;
      const specialities = this.specialitiesValues;
      const name = this.nameValue?.trim();
      const onlyOnline = this.statusValues?.length > 0;

      const { data: searchResultsData } = await MediaApiService.searchParticipants({
        eventId: this.eventId,
        countries: countries?.length > 0 ? countries : null,
        specialities: specialities?.length > 0 ? specialities : null,
        name: name !== '' ? name : null,
        onlyOnline: onlyOnline,
        pageNumber: this.currentPage,
      });

      this.searchResults = searchResultsData.results;
      this.totalRows = searchResultsData.totalResults;
      this.isLoading = false;
    },
    getCountryDescription(code) {
      const country = this.countriesOptions.find((x) => x.id == code);
      return country?.label ?? code;
    },
    getPlaceOfWork(institution, countryCode) {
      let placeOfWork = '';
      if (institution && institution !== '') placeOfWork += institution;
      if (institution && institution !== '' && countryCode && countryCode !== '') placeOfWork += ', ';
      if (countryCode && countryCode !== '') placeOfWork += this.getCountryDescription(countryCode);
      return placeOfWork;
    },
    async onPageChange(page) {
      // const resultsContainerTop = document.querySelector('#resultsContainer').offsetTop;
      // window.scrollTo({ top: resultsContainerTop, behavior: 'smooth' });

      this.currentPage = page;

      this.isLoading = true;
      await this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/theme';

@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

#resultsContainer {
  min-height: 78px;
}

::v-deep .spinner-border {
  position: absolute;
  top: 10%;
  left: 50%;
  width: 3rem;
  height: 3rem;
}

.userItem:last-child .session__details__header {
  border: 0;
}

.networkingSearch {
  background-color: #fff;
  border-radius: 0.5rem;
}

.profilePhoto {
  width: 100%;
  border-radius: 0.5rem;
}

.userQuote {
  font-style: italic;
  position: relative;
}

.userQuote:before {
  font-family: Georgia, serif;
  content: '“';
  font-size: 5em;
  color: #f7e7e8;
  float: left;
  line-height: 0.8;
  margin-right: 0.1em;
  max-height: 40px;
}

.userQuote:after {
  font-family: Georgia, serif;
  content: '”';
  font-size: 5em;
  color: #f7e7e8;
  float: left;
  line-height: 0.7;
  position: absolute;
  right: 0;
}

.badge-light {
  background: #f1f1f1;
}

.session__details__header {
  border-bottom: 2px solid #f1f1f1;
}

.userSocial i {
  position: relative;
  top: 3px;
  font-size: 1.6em;
}

.userSocial .la-linkedin-in {
  color: #0a66c2;
}

.userSocial .la-twitter {
  color: #1d9bf0;
}

@media (max-width: 1200px) {
  .form-inline .form-control {
    margin-bottom: 1em !important;
  }
}

@media (max-width: 992px) {
  .session__details__header .text-right {
    text-align: left !important;
  }
}

@media (max-width: 576px) {
  .main-container.pl-0.container-fluid.pr-4 {
    padding-left: 1rem !important;
  }
}
/* UPDATE STYLE*/
.networkingSearch .vue-treeselect {
  border-color: #e9ecef;
}
::v-deep .networkingSearch .vue-treeselect__placeholder,
::v-deep .vue-treeselect__limit-tip-text {
  font-size: 0.89rem;
}
::v-deep .networkingSearch .vue-treeselect__placeholder,
::v-deep .networkingSearch .vue-treeselect__control-arrow {
  color: #000;
}
::v-deep .networkingSearch .vue-treeselect__menu {
  font-size: 0.8em !important;
}
::v-deep .networkingSearch .vue-treeselect__menu div {
  text-align: left !important;
}

#resultsContainer .badge {
  font-size: 90%;
}
.b-avatar {
  height: 100%;
}
::v-deep .b-avatar .b-avatar-img {
  border-radius: 10%;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
::v-deep .b-avatar .b-avatar-badge {
  width: 2em;
  height: 2em;
  border: 3px solid #fff;
}
::v-deep .badge-danger .badge-primary {
  background-color: #dc3545 !important;
}
::v-deep .badge-success .badge-primary {
  background-color: #5ad539 !important;
}
</style>
