import { MediaType } from 'src/enums/media-type';

class QueryHelper {
  static extractMediaMetadata(furl) {
    let media = { id: -1, type: null };

    if (furl.indexOf('-') > 0) {
      const splitArr = furl.split('-')[0];

      const mediaType = splitArr.charAt(0) == 'S' ? MediaType.SESSION : MediaType.PRESENTATION;
      const mediaId = parseInt(splitArr.substring(1, splitArr.length));

      media = { id: mediaId, type: mediaType, furl: furl };
    }

    return media;
  }

  static extractChannelParams(furl) {
    let params = { channelId: -1 };

    //todo: channel furl...

    const channelId = parseInt(furl);
    params = { channelId: channelId };

    return params;
  }

  static extractSessionId(furl) {
    const splitArr = furl.split('-');
    return Number.parseInt(splitArr[0]);
  }

  /*
    //
    //Remove login process query parameters after treatment
    //
    static removeLoginProcessParameters() {
      var newUrl = QueryHelper.removeUrlParameter('token', null);
      newUrl = QueryHelper.removeUrlParameter('expires', newUrl);
      newUrl = QueryHelper.removeUrlParameter('escId', newUrl);
      newUrl = QueryHelper.removeUrlParameter('email', newUrl);
      newUrl = QueryHelper.removeUrlParameter('title', newUrl);
      newUrl = QueryHelper.removeUrlParameter('firstName', newUrl);
      newUrl = QueryHelper.removeUrlParameter('lastName', newUrl);
  
      history.replaceState({}, '', newUrl);
    }
  
    static removeForceSsoProcessParameters() {
      //console.log('removeCheckQueryPlatformAccessFallbackProcessParameters');
      var newurl = QueryHelper.removeUrlParameter('forcesso', newurl);
      history.replaceState({}, '', newurl);
    }
  
    static removeCheckQueryPlatformAccessFallbackProcessParameters() {
      //console.log('removeCheckQueryPlatformAccessFallbackProcessParameters');
      var newurl = QueryHelper.removeUrlParameter('hpa', newurl);
      history.replaceState({}, '', newurl);
    }
  */

  //
  //Remove parameter to query string
  //
  static addUrlParameter(queryString,parameter, value) {
    var resultQueryString = queryString ?? '';
    if (parameter && value) {
      if (resultQueryString.length == 0) {
        resultQueryString += '?';
      } else {
        resultQueryString += '&';
      }
      resultQueryString += `${parameter}=${encodeURIComponent(value)}`
    }
    return resultQueryString;
  }

  //
  //Remove parameter from query string without redirect v2
  //
  static removeUrlParameter(parameter, url) {
    //console.log('removeUrlParameter '+parameter+','+url);
    if (!url) url = window.location.href;
    var urlParts = url.split('?');

    if (urlParts.length >= 2) {
      // Get first part, and remove from array
      var urlBase = urlParts.shift();

      // Join it back up
      var queryString = urlParts.join('?');

      var prefix = encodeURIComponent(parameter) + '=';
      var parts = queryString.split(/[&;]/g);

      // Reverse iteration as may be destructive
      for (var i = parts.length; i-- > 0; ) {
        // Idiom for string.startsWith
        if (parts[i].lastIndexOf(prefix, 0) !== -1) {
          parts.splice(i, 1);
        }
      }
      url = urlBase;
      // Add remaining query parameters
      if (parts.length > 0) {
        url = url + '?' + parts.join('&');
      }
    }

    return url;
  }

  static getParameterByName(name, url) {
    if (!url) url = window.location.href;
    //console.log('getParameterByName '+name+' '+url);
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}

export { QueryHelper };
