<template>
  <div>
    <div v-if="!isLoading && !sessionAvailable">
      <!-- No live at this time -->
      <b-alert :show="isLive" variant="congress">
        <font-awesome-icon icon="info-circle" size="lg" class="alert_icon la-t-plus-1 mr-2 float-left" />
        There are currently no live sessions taking place.
      </b-alert>

      <!-- No replay at this time -->
      <b-alert :show="isReplay" variant="congress">
        <font-awesome-icon icon="info-circle" size="lg" class="alert_icon la-t-plus-1 mr-2 float-left" />
        Replays will be available shortly – watch this space !
      </b-alert>
    </div>

    <!-- New replays available -->
    <div @click="refreshReplays()" v-if="isReplay && newReplaysAvailable" class="alert_icon refresh-list__alert">
      <b-alert show variant="congress" class="text-center">
        New replays available: please click here to refresh the list.
        <font-awesome-icon icon="redo" size="1x" class="la-t-plus-1 ml-2" />
      </b-alert>
    </div>

    <div>
      <!-- IsLoadingTemplate -->
      <template v-if="isLoading">
        <b-col cols="6" md="4" lg="3" v-for="index in 4" :key="index" class="card-deck pb-2 pb-md-4 px-0 mx-0">
          <b-skeleton-img no-aspect height="320px"></b-skeleton-img>
        </b-col>
      </template>

      <!-- MainTemplate -->
      <template v-if="!isLoading">
        <!-- channel and days filter in ondemand mode -->
        <b-row class="no-gutters" v-if="isReplay">
          <b-col cols="12" md="8" lg="3" class="pb-2 pb-md-4 px-1 px-md-2">
            <treeselect
              v-model="daysValues"
              :options="daysOptions"
              :multiple="true"
              placeholder="Days"
              :appendToBody="true"
              @input="onTreeSelectInput"
              :limit="0"
              :limitText="(count) => `Days (${count} applied)`"
            />
          </b-col>

          <b-col cols="12" md="4" lg="6" class="pb-2 pb-md-4 px-1 px-md-2">
            <treeselect
              v-model="channelValues"
              :options="channelOptions"
              :multiple="true"
              :placeholder="channelFilterLabel"
              :appendToBody="true"
              @input="onTreeSelectInput"
              :limit="0"
              :limitText="(count) => `${channelFilterLabel} (${count} applied)`"
            />
          </b-col>
        </b-row>
        <div v-if="isLive">
          <b-row v-if="onlineSessions && onlineSessions.length > 0">
            <b-col cols="12" class="text-left pb-2 px-3">
              <span class="channeltype-row__text font-black">Online</span>
            </b-col>
            <b-col cols="12" md="4" lg="3" v-for="session in onlineSessions" :key="session.id" class="card-deck pb-2 pb-md-4 px-0 mx-0">
              <SessionCard
                :currentSession="session"
                :isLive="session.isLive"
                :mode="mode"
                :forceOnlineChannel="true"
                :isLiveSession="session.isLive"
                :forceProgress="true"
              ></SessionCard>
            </b-col>
          </b-row>
          <b-row v-if="onsiteSessions && onsiteSessions.length > 0" class="mt-3">
            <b-col cols="12" class="text-left pb-2 px-3">
              <span class="channeltype-row__text font-black"> {{ locationCity }}</span>
            </b-col>
            <b-col cols="12" md="4" lg="3" v-for="session in onsiteSessions" :key="session.id" class="card-deck pb-2 pb-md-4 px-0 mx-0">
              <SessionCard
                :currentSession="session"
                :isLive="session.isLive"
                :mode="mode"
                :forceOnlineChannel="false"
                :forceProgress="true"
                :hideWatchButton="true"
                :isLiveSession="true"
              ></SessionCard>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-row>
            <b-col cols="12" md="6" lg="4" xl="3" v-for="session in sessions" :key="session.id" class="card-deck pb-2 pb-md-4 px-0 mx-0">
              <SessionCard :currentSession="session" :isLive="session.isLive" :mode="mode"></SessionCard>
            </b-col>
          </b-row>
        </div>
      </template>

      <!-- IsLoadingMoreTemplate -->
      <template v-if="isLoadingMore">
        <b-col cols="12"></b-col>
        <b-col cols="6" md="4" lg="3" v-for="index in 4" :key="index" class="card-deck pb-2 pb-md-4 px-1 px-md-2">
          <b-skeleton-img no-aspect height="320px"></b-skeleton-img>
        </b-col>
      </template>

      <!-- LoadMore (Button) -->
      <b-col cols="12">
        <b-button pill variant="congress" @click.prevent="loadMore()" v-if="sessionAvailable && loadMoreVisible && !isLoadingMore">
          Load more <i class="las la-sync"></i>
        </b-button>
      </b-col>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { constants } from 'src/constants';
  import { MediaApiService } from 'src/services/media-api';
  import Treeselect from '@riophae/vue-treeselect';
  import '@riophae/vue-treeselect/dist/vue-treeselect.css';
  import hybridEventMixin from 'src/mixins/hybridEventMixin';
  import SessionCard from 'src/components/Cards/SessionCard';

  export default {
    name: 'SessionsBlock',
    mixins: [hybridEventMixin],
    props: {
      // eventId: Number,
      mode: {
        type: String,
        default: 'Live',
        validator: function (value) {
          // The value must match one of these strings
          return value !== null && ['Live', 'Coming', 'Replay'].indexOf(value) !== -1;
        },
      },
    },
    components: {
      SessionCard,
      Treeselect,
    },
    data() {
      return {
        isLoading: true,
        isLoadingMore: false,
        sessions: [],
        skip: 0,
        loadMoreVisible: false,
        newReplaysAvailable: false,
        sessionAvailable: false,
        fetchLiveNowSessionsJob: null,
        fetchReplaySessionsJob: null,
        channelValues: null,
        channelOptions: [],
        daysValues: null,
        daysOptions: [],
      };
    },
    computed: {
      isReplay() {
        return this.mode == 'Replay';
      },
      isComing() {
        return this.mode == 'Coming';
      },
      isLive() {
        return this.mode == 'Live';
      },
      poolingLiveSessionsInterval() {
        return parseInt(process.env.VUE_APP_POOLING_LIVE_SESSIONS_INTERVAL);
      },
      poolingReplaySessionsInterval() {
        return parseInt(process.env.VUE_APP_POOLING_REPLAY_SESSIONS_INTERVAL);
      },
      onlineSessions() {
        return (
          (this.locationTypeId === constants.EVENT_LOCATION_ONLINE || this.locationTypeId === constants.EVENT_LOCATION_HYBRID) &&
          this.sessions?.filter((s) => s.isOnline)
        );
      },
      onsiteSessions() {
        return (
          (this.locationTypeId === constants.EVENT_LOCATION_ONSITE || this.locationTypeId === constants.EVENT_LOCATION_HYBRID) &&
          this.sessions?.filter((s) => s.isOnsite)
        );
      },
      channelFilterLabel() {
        return this.showChannelOnline ? 'Channels' : 'Rooms';
      },
      ...mapGetters(['isPoolingEnabled']),
    },
    watch: {
      isPoolingEnabled(enabled) {
        if (enabled) {
          if (this.isLive) this.fetchLiveNowSessions();
          if (this.isComing) this.fetchComingSessions();
          if (this.isReplay) this.fetchReplaySessions(); // this.refreshLoadMoreReplayButton();
        }
      },
    },
    mounted() {
      if (this.isLive) {
        this.fetchLiveNowSessions();

        // Polling live sessions...
        this.fetchLiveNowSessionsJob = setInterval(() => {
          if (this.isPoolingEnabled) {
            this.fetchLiveNowSessions();
          }
        }, this.poolingLiveSessionsInterval);
      }

      if (this.isComing) this.fetchComingSessions();

      if (this.isReplay) {
        this.getChannelsOptions();
        this.getDaysOptions();

        this.fetchReplaySessions();

        // Polling replay sessions...
        this.fetchReplaySessionsJob = setInterval(() => {
          if (this.isPoolingEnabled) {
            this.fetchReplaySessions(); //this.refreshLoadMoreReplayButton();
          }
        }, this.poolingReplaySessionsInterval);
      }
    },
    beforeDestroy() {
      clearInterval(this.fetchLiveNowSessionsJob);
      clearInterval(this.fetchReplaySessionsJob);
    },
    methods: {
      fetchLiveNowSessions() {
        MediaApiService.getAllLiveNowSessions(this.eventId).then(({ data }) => {
          this.isLoading = false;
          if (data) {
            //data.filter((sess) => sess.isLiveNow);
            this.sessions = data;
            let nbSession = data.length;
            this.sessionAvailable = nbSession > 0;
          }
        });
      },
      fetchComingSessions() {
        MediaApiService.getComingSessions(this.eventId, this.skip, constants.LOAD_MORE_SESSIONS_COUNT + 1, false).then(({ data }) => {
          this.isLoading = false;
          if (data) {
            // data.filter((sess) => !sess.isLiveNow);
            let nbSession = data.length;
            this.loadMoreVisible = nbSession > constants.LOAD_MORE_SESSIONS_COUNT;
            if (this.loadMoreVisible) {
              data.splice(-1, 1);
            }
            this.sessions = data;
            this.skip += Math.min(nbSession, constants.LOAD_MORE_SESSIONS_COUNT); //constants.LOAD_MORE_SESSIONS_COUNT;
            this.sessionAvailable = nbSession > 0;
          }
        });
      },
      fetchReplaySessions() {
        //console.log('fetchReplaySessions');

        const channelsSelected = this.channelValues;
        const daysSelected = this.daysValues;

        //No replays displayed fetch replays from 0
        if (!this.sessionAvailable) {
          MediaApiService.getReplaySessions(
            this.eventId,
            this.skip,
            constants.LOAD_MORE_SESSIONS_COUNT + 1,
            channelsSelected?.length > 0 ? channelsSelected : null,
            daysSelected?.length > 0 ? daysSelected : null
          ).then(({ data }) => {
            this.isLoading = false;
            if (data) {
              let nbSession = data.length;
              this.loadMoreVisible = nbSession > constants.LOAD_MORE_SESSIONS_COUNT;
              if (this.loadMoreVisible) {
                data.splice(-1, 1);
              }
              this.sessions = data;
              this.sessionAvailable = nbSession > 0;
              if (this.sessionAvailable) {
                this.skip += Math.min(nbSession, constants.LOAD_MORE_SESSIONS_COUNT); //constants.LOAD_MORE_SESSIONS_COUNT;
              }
            }
          });
        }
        //At least one replay displayed, check for new replays
        else {
          //console.log('fetchcheckNewReplaysAvailable');
          if (!this.isLoading) this.checkNewReplaysAvailable();
          /*
        MediaApiService.getReplaySessions(this.eventId, this.skip, 1).then(({ data }) => {
          if (data) {
            this.loadMoreVisible = data.length > 0;
          }
        });
        */
        }
      },
      //Check if new replays available comparing 1st dispayed item and 1st iem from fetch
      checkNewReplaysAvailable() {
        const channelsSelected = this.channelValues;
        const daysSelected = this.daysValues;
        MediaApiService.getReplaySessions(
          this.eventId,
          0,
          1,
          channelsSelected?.length > 0 ? channelsSelected : null,
          daysSelected?.length > 0 ? daysSelected : null
        ).then(({ data }) => {
          if (data && data.length > 0) {
            //console.log(data[0].id);
            //console.log(this.sessions[0].id);
            this.newReplaysAvailable = data[0].id != this.sessions[0].id;
          }
        });
      },
      //Refresh replays list. Resets sessionAvailable, skip and dissmiss new replays alert
      refreshReplays() {
        //console.log('refreshReplays');
        this.isLoading = true;
        this.sessionAvailable = false;
        this.skip = 0;
        this.newReplaysAvailable = false;
        this.fetchReplaySessions();
      },
      /*
    refreshLoadMoreReplayButton() {
      MediaApiService.getReplaySessions(this.eventId, this.skip, 1).then(({ data }) => {
        if (data) {
          this.loadMoreVisible = data.length > 0;
        }
      });
    },
    */
      loadMore() {
        if (this.mode == 'Live') {
          this.loadMoreLiveNowSessions();
        }
        if (this.mode == 'Coming') {
          this.loadMoreComingSessions();
        }
        if (this.mode == 'Replay') {
          this.loadMoreReplaySessions();
        }
      },
      loadMoreLiveNowSessions() {
        this.isLoadingMore = true;
        MediaApiService.getLiveNowSessions(this.eventId, this.skip, constants.LOAD_MORE_SESSIONS_COUNT + 1, false).then(({ data }) => {
          this.isLoadingMore = false;
          if (data) {
            let nbSession = data.length;
            this.loadMoreVisible = nbSession > constants.LOAD_MORE_SESSIONS_COUNT;
            if (this.loadMoreVisible) {
              data.splice(-1, 1);
            }
            this.sessions = [...this.sessions, ...data];
            this.skip += Math.min(nbSession, constants.LOAD_MORE_SESSIONS_COUNT); //constants.LOAD_MORE_SESSIONS_COUNT;
          }
        });
      },
      loadMoreComingSessions() {
        this.isLoadingMore = true;
        MediaApiService.getComingSessions(this.eventId, this.skip, constants.LOAD_MORE_SESSIONS_COUNT + 1, false).then(({ data }) => {
          this.isLoadingMore = false;
          if (data) {
            let nbSession = data.length;
            this.loadMoreVisible = nbSession > constants.LOAD_MORE_SESSIONS_COUNT;
            if (this.loadMoreVisible) {
              data.splice(-1, 1);
            }
            this.sessions = [...this.sessions, ...data];
            this.skip += Math.min(nbSession, constants.LOAD_MORE_SESSIONS_COUNT); //constants.LOAD_MORE_SESSIONS_COUNT;
          }
        });
      },
      loadMoreReplaySessions() {
        this.isLoadingMore = true;
        const channelsSelected = this.channelValues;
        const daysSelected = this.daysValues;
        MediaApiService.getReplaySessions(
          this.eventId,
          this.skip,
          constants.LOAD_MORE_SESSIONS_COUNT + 1,
          channelsSelected?.length > 0 ? channelsSelected : null,
          daysSelected?.length > 0 ? daysSelected : null
        ).then(({ data }) => {
          this.isLoadingMore = false;
          if (data) {
            let nbSession = data.length;
            this.loadMoreVisible = nbSession > constants.LOAD_MORE_SESSIONS_COUNT;
            if (this.loadMoreVisible) {
              data.splice(-1, 1);
            }
            this.sessions = [...this.sessions, ...data];
            this.skip += Math.min(nbSession, constants.LOAD_MORE_SESSIONS_COUNT); //constants.LOAD_MORE_SESSIONS_COUNT;
          }
        });
      },
      getChannelsOptions() {
        MediaApiService.getChannelsOptions(this.eventId).then(({ data }) => {
          if (data) {
            this.channelOptions = data;
          }
        });
      },
      onTreeSelectInput() {
        this.sessionAvailable = false;
        this.skip = 0;
        this.fetchReplaySessions();
      },
      getDaysOptions() {
        MediaApiService.getDaysOptions(this.eventId).then(({ data }) => {
          if (data) {
            this.daysOptions = data;
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  .button__load-more {
    color: $secondary;
    background-color: var(--congress);
    border-radius: 6px;
  }

  .comming-next__title {
    display: inline-block;
    background-color: var(--congress);
    color: $secondary;
    border-radius: 2px;
    font-size: 1.2em;
  }

  .refresh-list__alert {
    cursor: pointer;
  }

  .alert_icon {
    color: var(--congress-dark);
  }

  .channeltype-row__text {
    color: $grey-dark;
    text-transform: uppercase;
  }
  
</style>
