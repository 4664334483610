import { OPEN_MEDIA_MODAL, CLOSE_MEDIA_MODAL, OPEN_CHANNEL_MODAL, CLOSE_CHANNEL_MODAL } from 'src/store/actions';
import { SET_MEDIA_MODAL_VISIBILITY, PURGE_MEDIA_MODAL_METADATA, SET_MEDIA_MODAL_METADATA, SET_CHANNEL_MODAL_VISIBILITY, SET_CHANNEL_MODAL_PARAMS, PURGE_CHANNEL_MODAL_PARAMS } from 'src/store/mutations';
import { store } from 'src/store';
import { MediaApiService } from 'src/services/media-api';
import { SET_IS_LOADING } from 'src/store/mutations';

const initialState = {
  media: {
    show: false,
    metadata: {
      type: null,
      id: -1
    }
  },
  channel: {
    show: false,
    params: null
  }
};

const state = { ...initialState };

const getters = {
  isMediaModalOpen(state) {
    return state.media.show;
  },
  isChannelModalOpen(state) {
    return state.channel.show;
  }
};

const actions = {
  [OPEN_MEDIA_MODAL]({ commit }, metadata) {
    commit(SET_MEDIA_MODAL_METADATA, metadata);
    commit(SET_MEDIA_MODAL_VISIBILITY, true);
  },
  [CLOSE_MEDIA_MODAL]({ commit }) {
    commit(SET_MEDIA_MODAL_VISIBILITY, false);
    commit(PURGE_MEDIA_MODAL_METADATA);
  },
  [OPEN_CHANNEL_MODAL]({ commit }, channelId) {
    commit(SET_CHANNEL_MODAL_PARAMS, channelId);
    commit(SET_CHANNEL_MODAL_VISIBILITY, true);
  },
  [CLOSE_CHANNEL_MODAL]({ commit }) {
    commit(SET_CHANNEL_MODAL_VISIBILITY, false);
    commit(PURGE_CHANNEL_MODAL_PARAMS);
  }
};

const mutations = {
  [SET_MEDIA_MODAL_VISIBILITY](state, show) {
    /*state.media.show = show;*/
    //Ressources moved to esc365 hook

    if (show && store.state.app.ressourcesMovedTo365) {
      //console.log('Replays closed - moved to 365');
      store.commit(SET_IS_LOADING, true);
      MediaApiService.getScientificSessions(state.media.metadata.id).then(({ data }) => {
        const esc365Url = process.env.VUE_APP_ESC365_BASE_URL;
        const esc365MediaUrl = `${esc365Url}/Session/${data.scientificSessionId}`;
        //console.log('Redirecting to ' + esc365MediaUrl);
        window.location.href = esc365MediaUrl;
      });
    } else {
      state.media.show = show;
    }
  },
  [SET_MEDIA_MODAL_METADATA](state, metadata) {
    state.media.metadata = metadata;
  },
  [PURGE_MEDIA_MODAL_METADATA](state) {
    state.media.metadata.type = null;
    state.media.metadata.id = -1;
  },
  [SET_CHANNEL_MODAL_VISIBILITY](state, show) {
    state.channel.show = show;

    //Ressources moved to esc365 hook
    /*
        if (show && store.state.app.ressourcesMovedTo365) {
            //console.log('Replays closed - moved to 365');
            store.commit(SET_IS_LOADING, true);
            MediaApiService.getScientificSessions(state.media.metadata.id).then(({ data }) => {
                const esc365Url = process.env.VUE_APP_ESC365_BASE_URL;
                const esc365MediaUrl = `${esc365Url}/Session/${data.scientificSessionId}`;
                //console.log('Redirecting to ' + esc365MediaUrl);
                window.location.href = esc365MediaUrl;
            });
        }
        */
  },
  [SET_CHANNEL_MODAL_PARAMS](state, params) {
    state.channel.params = params;
  },
  [PURGE_CHANNEL_MODAL_PARAMS](state) {
    state.channel.params = null;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
