<template>
  <div class="mosaic-wrapper">
    <div class="mosaic-container">
      <div class="mosaic-bg" :style="{ backgroundImage: `url('${publicPath}img/events/${eventId}/mosaic-bg.png?v=${this.appVersion}')` }"></div>

      <b-container>
        <b-row>
          <b-col cols="12" lg="5" class="mosaic-header mt-2 mt-lg-5">
            <div class="title-heading text-center text-lg-left ml-lg-5">
              <h3 class="heading text-white mb-4 mt-4">EACVI - Wall of fame</h3>
              <p class="para-desc text-white">We will miss seeing you, but to capture the moment please join our wall of fame!</p>
              <div class="mt-4 pt-2 mb-2 mb-lg-4">
                <a v-if="mosaicUploadUrl" :href="mosaicUploadUrl" target="_blank" class="btn btn-outline-secondary mb-4">
                  Take your picture <i class="las la-camera la-4x la-t-plus-3"></i>
                </a>
              </div>
            </div>
          </b-col>

          <div class="col-lg-7 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0 mb-4">
            <div class="pt-3 pb-3 col-md-8 col-12 offset-md-2 mt-n5">
              <b-overlay :show="isLoading" class="embed-responsive embed-responsive-1by1">
                <div
                  id="noUpload"
                  @click.self="
                    e => {
                      e.stopPropagation();
                    }
                  "
                ></div>
                <!-- MosaicWidget -->
                <iframe
                  contenteditable="“true”"
                  :id="iframeId"
                  ref="mosaicIframe"
                  :src="mosaicIframeUrl"
                  @load="onIframeLoaded"
                  class="embed-responsive-item"
                  :class="{ 'd-none': isLoading }"
                >
                </iframe>
              </b-overlay>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'MosaicWidget',
    props: {
      lang: {
        type: String,
        default: 'en'
      },
      mosaicUploadUrl: {
        type: String
      }
    },
    data() {
      return {
        iframeId: 'mosaicIframe',
        isLoading: true,
        publicPath: process.env.BASE_URL
      };
    },
    computed: {
      ...mapState({
        appVersion: state => state.app.version,
        eventId: state => state.app.eventId
      }),
      mosaicIframeUrl() {
        return `${process.env.VUE_APP_MOSAIC_IFRAME_URL}?lg=${this.lang}`;
      }
      /*
    mosaicUploadUrl() {
      return process.env.VUE_APP_MOSAIC_UPLOAD_URL;
    },
    */
    },
    methods: {
      onIframeLoaded() {
        // this.$refs.mosaicIframe.contentDocument.document.body.style.display = 'none';
        this.isLoading = false;
        this.$emit('loaded');
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  #noUpload {
    height: 40px;
    width: 99.99%;
    // padding:0px 10px;
    background-color: white;
    background-image: url('/congress/img/events/980/mosaic-logo.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 5px 2px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    cursor: default;
  }

  #app .mosaic-wrapper .b-overlay-wrap {
    min-height: auto;
  }
  .embed-responsive {
    height: 100%;
    border-radius: 10px !important;
  }

  .mosaic-wrapper {
    margin-top: 4rem;
  }

  .mosaic-bg {
    position: absolute;
    left: -39px;
    right: 0;
    height: 100%;
    width: calc(100% + 4.9rem);
    // background-image: url('~@/assets/images/congress/mosaic-bg.jpg');
    // background-color: #c6168d;
    background-size: cover;
    // border-radius: 12px;
  }

  @media (max-width: 767.98px) {
    .mosaic-bg {
      position: absolute;
      left: -59px;
      right: 0;
      height: 100%;
      width: calc(100% + 4.25rem);
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1325px) {
    .mosaic-wrapper .container .mt-5 {
      margin-top: 0 !important;
    }

    .mosaic-wrapper .col-lg-7 .mt-n5 {
      margin-top: 3rem !important;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 991px) {
    .mosaic-header {
      margin-bottom: 2rem !important;
    }
  }

  @media only screen and (max-width: 1325px) {
    .mosaic-wrapper {
      margin-top: 1rem;
    }
  }

  @include media-breakpoint-down(md) {
  }
</style>
