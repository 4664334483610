<template>
  <b-col cols="12" sm="12" md="8" lg="9" class="pt-4 pb-4">
    <template v-if="!networkingHasAccess">
      <NetworkingNoAccessWidget />
    </template>
    <b-row v-if="networkingHasAccess">
      <!-- Networking Messages -->
      <NetworkingChat v-if="networkingChatAgreement" />

      <!-- Networking Consent -->
      <NetworkingConsent />
    </b-row>
  </b-col>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import NetworkingChat from 'src/components/NetworkingChat';
import NetworkingConsent from 'src/components/NetworkingConsent';
import NetworkingNoAccessWidget from 'src/components/Widgets/NetworkingNoAccessWidget';

export default {
  name: 'NetworkingMessages',
  components: { NetworkingChat, NetworkingConsent, NetworkingNoAccessWidget },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapState({
      eventId: (state) => state.app.eventId,
      networkingChatAgreement: (state) => state.app.chatParticipantConfig.networkingChatAgreement,
      networkingHasAccess: (state) => state.app.chatParticipantConfig.hasAccess,
    }),
  },
  mounted() {
    // If needed...
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/theme';

@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

/* TEST DISPLAY */
</style>

<style>
.consentSwitch .card-body {
  padding: 1.25rem 2rem;
}
.consentSwitch .card-title {
  color: #000;
  font-size: 1.1em;
}
.consentSwitch p {
  color: #858585;
  font-size: 90%;
}
.consentSwitch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #28a745;
  border-color: #28a745;
}
</style>
