<template>
  <fragment>
    <!-- 
    ========================================================================================================================================================================
    HERO BANNER
    ========================================================================================================================================================================
     -->
    <b-row class="hero__banner">
      <b-col v-if="!isMobileAppPage && !isHomePage" cols="12"
        class="hero__bg pt-5 px-3 px-md-0 d-flex justify-content-left"
        :style="{ backgroundImage: `url('/congress/img/events/${eventId}/hero-bg.png?v=${this.appVersion}')` }">
        <div class="eventInfo">
          <h1>My Programme</h1>
          <div class="eventDate mt-3">Your favorite sessions are listed below. Feel free to add them to your own
            calendar.</div>
          <div class="eventDate">Please note that the programme times are displayed in local times for {{ locationEvent
            }}.</div>
        </div>
      </b-col>
    </b-row>
    <!-- 
    ========================================================================================================================================================================
    END HERO BANNER
    ========================================================================================================================================================================
     -->

    <b-container fluid class="px-5 pb-0" :class="isTimelineExpanded ? 'd-block d-md-none' : 'd-block'">
      <b-row>
        <!-- ESC Banner(s) -->
        <b-col cols="10" md="12" offset="2" offset-md="0">
          <EscBanners />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="pt-3 px-3">
          <!-- Not logged -->
          <template v-if="!isLogged">
            <LogginWidget headerText="Please login" message="Please sign in to view all your favourite sessions." />
          </template>

          <!-- OverviewTabs -->
          <OverviewTabs v-else />


        </b-col>
      </b-row>
      <b-row>
        <!-- IndustryBanners Banner(s) -->
        <b-col cols="10" md="12" offset="2" offset-md="0" class="pt-3">
          <IndustryBanners />
        </b-col>
      </b-row>
    </b-container>

      <!-- Suggestions -->
      <RecommendationBlock />

      <!-- Register Promo -->
    <RegisterPromoWidget />
    <!-- NEW CARDS FROM SLIDER -->
    <div class="authoredSlidesBlock px-0">
      <h3 class="px-5 mb-3">You may also be interested in</h3>
      <AuthoredSlidesBlock />
    </div>
  </fragment>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import openModalMixin from 'src/mixins/openModalMixin';
import { constants } from 'src/constants';
import EscBanners from 'src/components/Banners/EscBanners';
import IndustryBanners from 'src/components/Banners/IndustryBanners';
import RegisterPromoWidget from 'src/components/Widgets/RegisterPromoWidget';
import LogginWidget from 'src/components/Widgets/LogginWidget';
import AuthoredSlidesBlock from 'src/components/Blocks/AuthoredSlidesBlock.vue';
import RecommendationBlock from 'src/components/Blocks/RecommendationBlock.vue';
import OverviewTabs from './components/OverviewTabs';

export default {
  name: 'Overview',
  mixins: [openModalMixin],
  components: {
    EscBanners,
    IndustryBanners,
    RegisterPromoWidget,
    LogginWidget,
    RecommendationBlock,
    AuthoredSlidesBlock,
    OverviewTabs,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['isTimelineExpanded', 'appVersion']),
    ...mapState({
      eventId: (state) => state.app.eventId,
      isLogged: (state) => state.auth.loggedIn,
      preCongressMode: (state) => state.app.preCongressMode,
      postCongressMode: (state) => state.app.postCongressMode,
      appVersion: (state) => state.app.version,
      locationEvent: (state) => state.app.locationEvent,

      isMobileAppPage() {
        const pagesWithoutTimeline = [
          `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_PRE_HOME}`,
          `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_HAPPENING_NOW}`,
          `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_INTERACTIVITY}`,
        ];
        return pagesWithoutTimeline.map((x) => `/${this.eventRoute}/${x}`).includes(this.$route.path);
      },

      isHomePage() {
        return this.$route.path === `/${this.eventRoute}` || this.$route.path === `/${this.eventRoute}/` || this.$route.path === `/${this.eventRoute}/home`;
      },
    }),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '~@/styles/theme';

h1.block__header__title {
  font-size: 1.8em;
}

h2.block__header__subtitle {
  font-size: 1.15em;
}

.authoredSlidesBlock {
  background-color: #fff;
}
</style>
