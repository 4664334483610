<template>
  <div v-if="result" class="card search__card">
    <div class="card-header d-block">
      <div class="d-flex pb-2">
        <span v-if="result.source && result.source.startTimeUnixEpoc" class="search__card__header__date font-bold">
          {{ result.source.startTimeUnixEpoc | formatUnixEpocDayPart }} • {{ result.source.startTimeUnixEpoc | formatUnixEpocTimePart }} -
          {{ result.source.endTime | formatUnixEpocTimePart }}
        </span>
      </div>

      <div class="d-block text-left lh-1">
        <div v-if="result.source && result.source.session && result.source.session.room" class="session__channel d-inline-flex align-items-center font-regular mr-2">
          <span class="mr-1 material-icons-outlined"> place </span> <span>{{ this.showRoom(result.source.session.room) }}</span>
        </div>

        <div
          v-if="result.source && result.source.session && result.source.session.channel"
          class="session__location--online d-inline-flex align-items-center font-regular"
        >
          <span class="mr-1 material-icons-round"> cast </span><span>{{ this.showChannelRoom(result.source.session.channel) }}</span>
        </div>
      </div>
    </div>

    <div class=" card-body search__card__body">
      <!-- Title -->
      <h4
        class="card-title mb-1"
        v-if="result.source && result.source.title"
        v-html="result.highlight['title.text'] ? result.highlight['title.text'][0] : result.source.title"
      ></h4>

      <!-- Presenter -->
      <div v-if="result.source && result.source.contributors" class="search__card__header__author font-regular">
        by
        <span
          v-if="result.source.contributors"
          v-html="result.highlight['contributors.fullname.text'] ? result.highlight['contributors.fullname.text'][0] : result.source.contributors[0].fullname"
        ></span>
      </div>
      <!-- snippets -->
      <!-- content -->
      <blockquote class="search__card__body__snippets pt-4" v-if="abstractContentSearch && query">
        <span>Content: </span><span v-html="abstractContentSearch"></span>
      </blockquote>
      <!-- topic -->
      <blockquote class="search__card__body__snippets pt-4" v-if="result.source && result.highlight['topic.text']">
        <span>Topic: </span> <span v-html="result.highlight['topic.text'] ? result.highlight['topic.text'][0] : result.source.topic"></span>
      </blockquote>
      <!-- institution -->
      <!-- <blockquote class="search__card__body__snippets pt-4" v-else-if="result.data.institution && result.data.institution.snippet">
        <span>Institution: </span><span v-html="result.data.institution.snippet"></span>
      </blockquote> -->
      <!-- authors -->
      <!-- <blockquote class="search__card__body__snippets pt-4" v-else-if="result.data.authors && result.data.authors.snippet">
        <span>Authors: </span><span v-html="result.data.authors.snippet"></span>
      </blockquote> -->
    </div>

    <!-- View action -->
    <div class=" card-footer search__card__footer pt-0">
      <a v-if="ressourcesMovedTo365 && result.source && result.source.dbId" :href="`${esc365AbstractUrl(result.source.dbId)}`" class="search__card__footer__action font-regular">
        <i class="las la-3x la-t-plus-2 la la-external-link mr-1"></i>
        View abstract
      </a>
      <b-row v-else-if="!ressourcesMovedTo365" >
        <b-col  cols="6" class="mb-2 text-left">
          <a  v-if="result.source.researchGatewayLink" target="_blank" class="search__card__footer__action font-regular" :href="result.source.researchGatewayLink">
            <i class="las la-4x la-t-plus-2 las la-chalkboard"></i>
            View Resources
          </a>
        </b-col>
        <b-col cols="6">
          <span class="search__card__footer__action font-regular" @click.stop.prevent="onViewClick">
            <i class="las la-4x la-t-plus-2 la-file-image"></i>
            View abstract
          </span>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { HtmlHelper } from 'src/utils/html-helper';
  import esc365Mixin from 'src/mixins/esc365Mixin';

  export default {
    name: 'AbstractResultCard',
    mixins: [esc365Mixin],
    components: {},
    props: {
      result: Object,
      query: String,
    },
    data() {
      return {
        abstractContentSearch: '',
      };
    },
    mounted() {
      // If needed...
      if (this.result.highlight && this.result.highlight['abstract_content']) this.getAbstractContentSearch(this.result.highlight['abstract_content'][0]);
    },
    watch: {
      query() {
        this.getAbstractContentSearch(this.result.highlight['abstract_content'][0]);
      },
    },
    computed: {
      ...mapState({
        eventRoute: (state) => state.app.eventRoute,
        ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
        preCongressMode: (state) => state.app.preCongressMode,
        postCongressMode: (state) => state.app.postCongressMode,
      }),
    },
    methods: {
      getAbstractContentSearch(values) {
        let emNembre = values.replaceAll('<em>', '');
        emNembre = emNembre.replaceAll('</em>', '');
        this.abstractContentSearch = emNembre.slice(0, 255);
        if (this.query) {
          this.abstractContentSearch = emNembre.replaceAll(this.query, '<em>' + this.query + '</em>');
          this.abstractContentSearch = this.abstractContentSearch.includes(this.query) ? this.abstractContentSearch : '';
        }
      },
      showRoom(room) {
        let rst = 'On Demand';
        if (room) {
          rst = room ? room : '';
        }

        return rst;
      },
      showChannelRoom(channel) {
        let rst = '';
        if (channel) {
          channel = channel ? 'Online' : '';
          rst = channel;
        }

        return rst;
      },
      extractTopicFromSnippet(rawTopics) {
        let rawTopicsList = rawTopics?.split('|');
        let rawTopicsToDisplay = rawTopicsList?.find((rt) => rt.includes('<em>'));
        return rawTopicsToDisplay;
      },
      formatContentSnippet(rawContentSnippet) {
        return HtmlHelper.decodeHTMLEntities(rawContentSnippet);
      },
      onViewClick() {
        let eventDetails = { presentationId: this.result.source.dbId };
        this.$emit('abstract-view', eventDetails);

        //console.log('this.result.data.event_id.raw ' + this.result.data.event_id.raw);
        //console.log('this.result.data.title.raw ' + this.result.data.title.raw);
        //console.log('this.result.data.presentation_id.raw ' + this.result.data.presentation_id.raw);
        //console.log('this.result.data.digital_session_id.raw ' + (this.result.data.digital_session_id?this.result.data.digital_session_id.raw:0));

        // console.log('scientificSessionId',JSON.stringify(this.scientificSessionId));

        /* DataLayer */
        this.$gtm.push({
          event: 'viewAbstract',
          eventId: this.result.source.event.id,
          title: this.result.source.title,
          presentationId: `P${this.result.source.presentations}`,
          digitalSessionId: `${this.result.source.digitalSessionIds ? this.result.source.digitalSessionIds[0] : 0}`,
          uniqId: `SESS${this.result.source.dbId ? this.result.source.dbId : 0}`,
        });

        /* DataLayer */
        //this.$gtm.push({ event: 'onFavClick', eventId: this.eventId, title: this.title, digitalSessionId: this.digitalSessionId, action: !this.isFavorite ? 'add' : 'remove' });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .search__card {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 6px;
    background: white;
    border: solid 1px $grey-lighter;
  }

  .search__card__header {
    &__author {
      font-size: 0.9rem;
      color: $grey;
    }
  }

  .search__card__header__date {
    font-size: 0.8rem;
    color: $grey;
  }

  .search__card__header__button {
    display: inline-block;
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 0px;
    padding: 7px;
    border-left: solid 1px $grey-lighter;
    border-bottom: solid 1px $grey-lighter;
    border-radius: 0 6px;
  }

  .search__card__footer {
    margin-top: auto;
    width: 100%;
    text-align: right;
  }

  .card-footer.search__card__footer {
    margin-top: auto;
    padding: 1rem 1.25rem;
    width: 100%;
    text-align: right;
  }

  .search__card__footer__action {
    color: var(--congress);
    margin-right: 6px;
    cursor: pointer;
    &:hover,
    :focus {
      text-decoration: none;
    }
  }

  .search__card__body__snippets {
    font-size: 0.8em;
  }

  ::v-deep em {
    font-style: italic !important;
  }
  ::v-deep blockquote em {
    font-style: italic;
    font-weight: bold;
  }
</style>
