<template>
  <fragment v-if="preCongressMode && homeCmsData && homeCmsData.quizz_active">
    <div class="quiz-topBar-bg d-xl-block d-lg-none d-md-none d-sm-none bv-d-xs-down-none position-absolute text-center">&nbsp;</div>
    <div class="quiz-topBar-text d-xl-block d-lg-none d-md-none d-sm-none bv-d-xs-down-none position-absolute text-center">
      <h6 class="font-weight-bold">{{ homeCmsData.quizz_text }}</h6>
      <p>
        <a :href="homeCmsData.quizz_link" target="_blank">{{ homeCmsData.quizz_link_text }}&nbsp;<i class="las la-arrow-right la-3x"></i></a>
      </p>
    </div>
  </fragment>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'TakeQuizzWidget',
    computed: {
      ...mapState({
        homeCmsData: state => state.app.homeCmsData,
        preCongressMode: (state) => state.app.preCongressMode
      })
    },
    mounted() {
      // If needed...
    }
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
</style>
