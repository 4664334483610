<template>
  <!-- main content column -->
  <b-col cols="12" sm="12" md="12" lg="12">
    <b-row no-gutters>
      <!-- Overview -->
      <b-col cols="12">
        <Timeline :eventId="eventId" :expanded="true" :toggable="false" entity="Channel" />
      </b-col>
    </b-row>
    <v-tour name="overview-tour" :steps="steps" :callbacks="callbacks"></v-tour>
  </b-col>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';

  import openModalMixin from 'src/mixins/openModalMixin';
  import toursManagerMixin from 'src/mixins/toursManagerMixin';

  import Timeline from 'src/components/Timeline/Schedule';

  export default {
    name: 'Schedule',
    mixins: [openModalMixin, toursManagerMixin],
    components: {
      Timeline
    },
    data() {
      return {
        query: '',
        steps: [
          {
            target: '[data-v-step-schedule="0"]', // We're using document.querySelector() under the hood
            header: {
              //title: 'Date'
            },
            content: `Select a date and discover the daily schedule.<br><br>Click on the icon to save a session to My Programme.`,
            params: {
              placement: 'bottom',
              enableScrolling: false,
              highlight: true
            }
          },
          {
            target: '[data-v-step-schedule="1"]',
            header: {
              //title: 'Channels'
            },
            content: `Discover the programme by channel.`,
            params: {
              placement: 'bottom',
              enableScrolling: false,
              highlight: true
            }
          }
        ],
        callbacks: {
          onSkip: () => this.onTourCompleted('schedule'),
          onFinish: () => this.onTourCompleted('schedule')
        }
      };
    },
    computed: {
      ...mapGetters(['isTimelineExpanded']),
      ...mapState({
        eventId: state => state.app.eventId
      })
    },
    mounted() {
      if (!this.tours || !this.tours.schedule) {
        this.$nextTick(() => {
          this.$tours['overview-tour'].start();
        });
      }
    },
    methods: {
      onSearchRequested(query) {
        this.query = query;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';
</style>
