<template>
  <b-card  :img-src="currentSession.thumbnailURL" :title="currentSession.title" class="session__card">
    <template #header>
      <div class="d-flex pb-2">
        <div class="session__time font-bold" v-if="!currentSession.isOnDemand">
          {{ currentSession.startTime | formatUnixEpocDayPart }} • {{ currentSession.startTime | formatUnixEpocTimePart }} -
          {{ currentSession.endTime | formatUnixEpocTimePart }}
        </div>
        <div class="session__time" v-else-if="currentSession.isOnDemand">On Demand</div>
        <span class="session__favorite__button" v-if="!Ismobile">
          <FavoriteButton
            :title="currentSession.title"
            :digitalSessionId="currentSession.id"
            :scientificSessionId="currentSession.scientificSessionId"
            width="20"
            height="20"
          />
        </span>
      </div>
      <div class="d-block text-left lh-1">
        <div class="session__type d-inline-flex align-items-center font-regular">
          <span class="mr-1 material-icons-outlined"> co_present </span> <span>{{ currentSession.sessionType }}</span>
        </div>
      </div>
      <div class="d-block text-left lh-1">
        <div class="session__channel d-inline-flex align-items-center font-regular mr-2">
          <span class="mr-1 material-icons-outlined"> place </span><span> {{ currentSession.channelOnSiteDisplayName }}</span>
        </div>

        <div  v-if="currentSession.channel" class="session__location--online d-inline-flex align-items-center font-regular">
          <span class="mr-1 material-icons-round"> cast </span> <span> Online </span>
        </div>
      </div>
    </template>
    <div class="session__subtitle" v-if="currentSession.subTitle">
      {{ currentSession.subTitle }}
    </div>

    <!-- <div class="session__time" v-if="isLive">({{ currentSession.duration }}mn)</div> -->

    <template #footer>
      <b-row class="no-gutters">
        <b-col v-if="isLiveSession" cols="12" xl order="1" order-xl="1" class="session__progress">
          <b-progress v-if="isLive || forceProgress" :value="currentSession.progress" :max="currentSession.duration" height="0.6em"></b-progress>
        </b-col>

        <b-col
          v-if="isLiveSession && !forceOnlineChannel && !interactivity && !Ismobile"
          cols="12"
          xl="session-btn"
          order="2"
          order-xl="2"
          class="text-center text-xl-right pt-3 pt-md-0 pb-1 pb-xl-0 pl-xl-3 position-static ml-auto"
        >
          <router-link v-if="!ressourcesMovedTo365" :to="`/${eventRoute}/sessions/${currentSession.id}`" class="session_details text-nowrap stretched-link">
            See details <i class="las la-4x la-t-plus-2 la-arrow-right"></i
          ></router-link>
          <a v-else :href="`${esc365SessionUrl(currentSession.scientificSessionId)}`" class="session_details text-nowrap stretched-link">
            See details <i class="las la-4x la-t-plus-2 la-arrow-right"></i>
          </a>
        </b-col>

        <b-col
          v-if="!hideWatchButton"
          cols="12"
          xl="session-btn"
          order="2"
          order-xl="2"
          class="text-center text-xl-right pt-3 pt-md-0 pb-1 pb-xl-0 pl-xl-3 position-static ml-auto"
        >
          <SessionButton :session="currentSession" :forceInteract="isReplay" :Ismobile="Ismobile" class="d-inline-block" />
        </b-col>
      </b-row>
      <b-row v-if="isLiveSession" class="mb-2">
        <b-col v-if="showVoteButton && currentSession.votingUrl" class="text-center mt-3">
          <SessionVoteButton renderAs="button" :session="currentSession" :forceInteract="false" class="" />
        </b-col>
        <b-col v-if="showAskQuestionButton && currentSession.askQuestionUrl" class="text-center mt-3">
          <AskQuestionButton renderAs="button" :session="currentSession" :forceInteract="false" class="" />
        </b-col>
      </b-row>
      <b-row v-if="mode == 'Coming' && !interactivity && !Ismobile">
        <b-col cols="12" class="text-center text-xl-right pt-3 pt-md-0 pb-1 pb-xl-0 pl-xl-3 position-static ml-auto">
          <router-link v-if="!ressourcesMovedTo365" :to="`/${eventRoute}/sessions/${currentSession.id}`" class="session_details text-nowrap stretched-link">
            See details <i class="las la-4x la-t-plus-2 la-arrow-right"></i
          ></router-link>
          <a v-else :href="`${esc365SessionUrl(currentSession.scientificSessionId)}`" class="session_details text-nowrap stretched-link">
            See details <i class="las la-4x la-t-plus-2 la-arrow-right"></i>
          </a>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
  import { mapState } from 'vuex';
  import SessionButton from 'src/components/Buttons/SessionButton';
  import SessionVoteButton from 'src/components/Buttons/SessionVoteButton';
  import AskQuestionButton from 'src/components/Buttons/AskQuestionButton';
  import hybridEventMixin from 'src/mixins/hybridEventMixin';
  import FavoriteButton from 'src/components/Buttons/FavoriteButton';
  import esc365Mixin from 'src/mixins/esc365Mixin';

  export default {
    name: 'SessionCard',
    mixins: [hybridEventMixin,esc365Mixin],
    components: {
      SessionButton,
      SessionVoteButton,
      AskQuestionButton,
      FavoriteButton,
    },
    props: {
      currentSession: Object,
      isLive: Boolean,
      isLiveSession: Boolean,
      mode: String,
      forceOnlineChannel: Boolean,
      forceProgress: Boolean,
      hideWatchButton: Boolean,
      showVoteButton: Boolean,
      showAskQuestionButton: Boolean,
      Ismobile: Boolean,
      interactivity: Boolean,
    },
    computed: {
      isReplay() {
        return this.mode == 'Replay';
      },
      isComing() {
        return this.mode == 'Coming';
      },
      publicPath() {
        return process.env.BASE_URL;
      },
      ...mapState({
        eventRoute: (state) => state.app.eventRoute,
        ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
      }),
    },
    methods: {
      navigateToSession(event) {
      if (this.interactivity || event.target.closest(".session__favorite__button, .search__card__footer__action")) {
        return;
      }
      this.$router.push(`/${this.eventRoute}/sessions/${this.currentSession.id}`);
    },

    },
    mounted() {},
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .session__progress {
    margin: auto;
  }

  .session_details {
    color: var(--congress);
  }

  .session_details:focus,
  .session_details:hover {
    color: var(--congress-dark);
    text-decoration: none;
  }

  .session__subtitle {
    font-size: 0.8em;
  }
  /* update card style */
  .card-title {
    color: #000;
  }

  .session__card{
    cursor: pointer;
  }

  .session__card img {
    border-radius: 2rem;
  }

  .card.session__card .card-header {
    display: block;
  }
  .card.session__card {
    border-radius: 2rem;
    border: 0;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .card-img-top {
    border-radius: 2rem;
  }

  .session__card .card-header {
    display: flex;
    background: #fff;
    border: 0;
    padding-top: 1rem;
    padding-bottom: 0;
  }

  .session__card .session__time {
    font-size: 0.9rem;
    color:var(--congress);
  }

  .session__card .session__favorite__button {
    margin-left: auto;
    cursor: pointer;
    z-index: 100;
  }

  .session__card .material-icons-outlined,
  .session__card .material-icons-round {
    font-size: 16px;
  }

  .card-title {
    font-family: 'Interstate WGL Black';
    font-size: 1.2em;
  }

  .session__card .card-body {
    padding-top: 0.5rem;
  }

  .card-text p {
    margin-bottom: 0;
  }

  .card-footer {
    padding-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1.4rem;
  }

  @include media-breakpoint-up(xl) {
    .col-xl-session-btn {
      flex: 0 0 88px;
    }
  }
</style>
