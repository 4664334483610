<template>
  <fragment v-if="((session.isLive || session.isInInterlude) && session.channelId) || session.isReplay || forceInteract">
    <!-- Render as button -->
    <b-button pill variant="congress" v-if="renderAs && renderAs === 'button'" @click.stop.prevent="onWatchClick" class="card-link text-white">
      <!-- Watch -->
      <template v-if="!isExternal">
        <font-awesome-icon icon="play-circle" size="lg" />
        Watch
      </template>
      <!-- Join -->
      <template v-if="isExternal">
        <font-awesome-icon v-if="isLoading" icon="spinner" spin />
        <font-awesome-icon v-if="!isLoading" icon="users" size="lg" />
        Join
      </template>
    </b-button>

    <!-- Render as link -->
    <b-link v-else @click.stop.prevent="onWatchClick" class="card-link stretched-link">
      <!-- Watch -->
      <template v-if="!isExternal">
        <font-awesome-icon icon="play-circle" size="lg" />
        Watch
      </template>
      <!-- Join -->
      <template v-if="isExternal">
        <font-awesome-icon v-if="isLoading" icon="spinner" spin />
        <font-awesome-icon v-if="!isLoading" icon="users" size="lg" />
        Join
      </template>
    </b-link>
  </fragment>
</template>

<script>
  import { MediaApiService } from 'src/services/media-api';
  import { MediaType } from 'src/enums/media-type';
  import { OPEN_CHANNEL_MODAL, OPEN_MEDIA_MODAL } from 'src/store/actions';
  import { mapState } from 'vuex';

  export default {
    name: 'SessionButton',
    props: {
      session: Object,
      forceInteract: Boolean, // Only true if isReplay ?
      renderAs: {
        type: String,
        required: false,
        default: 'link',
        validator: function (value) {
          // The value must match one of these strings
          return value === null || ['button', 'link'].indexOf(value) !== -1;
        },
      },
      Ismobile: Boolean,
    },
    data() {
      return {
        isLoading: false,
      };
    },
    computed: {
      ...mapState({
        eventRoute: (state) => state.app.eventRoute,
        ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
      }),
      isExternal() {
        //!force interact added to avoid external link opening on replays with external link
        return !this.forceInteract && this.session.hasExternalLink;
      },
    },
    methods: {
      onWatchClick() {
        //console.log(` this.session.id ${this.session.id}`);
        //console.log(` this.session.channelId ${this.session.channelId}`);
        //console.log(` this.isExternal ${this.isExternal}`);
        //console.log(` this.eventRoute ${this.eventRoute}`);
        if (this.isExternal) {
          this.isLoading = true;

          MediaApiService.getSessionExternalLink(this.session.id).then(({ data }) => {
            if (data) {
              this.isLoading = false;

              if (data.hasAccess) {
                window.open(data.externalLink, '_blank'); // If an eternal link is provided (zoom for example), open in new tab
              } else {
                const metadata = { type: MediaType.SESSION, id: this.session.id };
                this.$store.dispatch(OPEN_MEDIA_MODAL, metadata);
                /*
                // If not access, open the channel modal to display it
                const params = { channelId: this.session.channelId };
                this.$store.dispatch(OPEN_CHANNEL_MODAL, params);
                */
              }
            }
          });
        } else if (this.session.isLive || this.session.isInInterlude) {
          if (!this.Ismobile) {
            const params = { channelId: this.session.channelId };
            this.$store.dispatch(OPEN_CHANNEL_MODAL, params);
          } else {
            //const escid = this.$route.query.escid;
            //this.$router.push(`/${this.eventRoute}/mobile-app/resource?eChanId=${this.session.encryptedChannelId}`);
            //this.$router.push(`/partner/media/resources?eChanId=${this.session.encryptedChannelId}&eescid=${escid}`);
            this.$router.push(`/partner/media/resources?eChanId=${this.session.encryptedChannelId}`);
          }
        } else {
          const metadata = { type: MediaType.SESSION, id: this.session.id };
          this.$store.dispatch(OPEN_MEDIA_MODAL, metadata);
          // console.log('🚀 ~ file: SessionButton.vue ~ line 95 ~ onWatchClick ~ metadata', metadata);
        }
      },
    },
  };
</script>

<style scoped>
  .session__card .card-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: '';
    background-color: rgba(0, 0, 0, 0);
  }
</style>
