<template>
  <div>
    <b-jumbotron header="Sorry – this session is restricted to ESC Members only who have registered for ESC Congress." 
    lead="This content is only available to ESC Professional Members, Association Ivory, Silver or Gold Members and Fellows who have registered for the event." 
    v-if="isMemberExclusive">
      <b-button v-if="showButton" variant="congress" @click="becomeAmember" class="mr-1">
        <i class="las la-5x la-t-plus-3 la-user-circle pr-1"></i>
       Become a member
      </b-button>
      <b-button v-if="showButton" variant="congress" @click="register" class="ml-1">
        <i class="las la-5x la-t-plus-3 la-user-circle pr-1"></i>
        Register Now
      </b-button>
    </b-jumbotron>
    <b-jumbotron :header="this.headerText" :lead="this.message" v-else> 
      <b-button v-if="showButton" variant="congress" @click="register" >
        <i class="las la-5x la-t-plus-3 la-user-circle pr-1"></i>
        {{ buttonText }}
      </b-button>
      <span class="ml-3">
        <a href="#" @click="checkAccess"><font-awesome-icon icon="play-circle" size="lg" /> {{ linkText }}</a>
      </span>
    </b-jumbotron>
  </div>
</template>
<script>
import { register, refreshAccess } from 'src/services/auth';

export default {
  name: 'RegisterWidget',
  props: {
    headerText: String,
    message: String,
    showButton: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: 'Register now',
    },
    linkText: String,
    isMemberExclusive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    returnUrl() {
      return window.location.origin + this.$route.fullPath;
    },
  },
  methods: {
    register() {
      register(this.returnUrl);
    },
    becomeAmember() {
      window.location = "https://www.escardio.org/The-ESC/Membership";
    },
    checkAccess() {
      refreshAccess(this.returnUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/theme';
@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

@include media-breakpoint-up(sm) {
  .jumbotron {
    padding: 1.5rem 4rem;
  }
}

.display-3 {
  font-size: 2rem;
}

.lead {
  font-size: 1rem;
}
</style>