<template>
<b-tabs>
    <!-- Replay -->
      <b-tab :active="postCongressMode">
        <!-- After Congress -->
        <b-alert :show="postCongressMode" variant="congress">
          <font-awesome-icon icon="info-circle" size="lg" class="alert_icon la-t-plus-1 mr-2 float-left" />
          <!--
          Missed it? Watch it again. On demand, available until {{ this.confCmsData.date_on_demand_close | formatUnixEpocDayPartWithYear }}.
          -->
          {{ this.confCmsData.postcongress_on_demand_message }}
        </b-alert>
        <!-- Always (after & druring congress) -->
        <SessionsBlock :eventId="this.eventId" mode="Replay"></SessionsBlock>
      </b-tab>
    </b-tabs>
</template>
<script>
  import { mapState } from 'vuex';

  import SessionsBlock from 'src/components/Blocks/SessionsBlock';

  export default {
    name: 'OnDemand',
    components: {
      SessionsBlock,
    },
    props: {
      eventId: Number,
    },
    data() {
      return {
        on_demand_close_date: null,
        next_congress_dates: null,
        congress_name: null,
      };
    },
    computed: {
      ...mapState({
        eventBaseTitle: (state) => state.app.eventBaseTitle,
        postCongressMode: (state) => state.app.postCongressMode,
        confCmsData: (state) => state.app.confCmsData,
      }),
    },
    mounted() {
      // If needed...
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  .alert_icon {
    color: var(--congress-dark);
  }
</style>