<template>
  <!-- RouterView -->
  <router-view />
</template>

<script>
export default {
  name: 'PartnerWrapper',
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
