<template>
  <Banners :v-if="this.sessionId && this.sessionBanners" :banners="this.sessionBanners" />
</template>

<script>
  import Banners from 'src/components/Banners/Banners';
  import { mapState } from 'vuex';

  export default {
    name: 'IndustryBanners',
    components: { Banners },
    props: {
      sessionId: Number
    },
    computed: {
      ...mapState({
        confCmsData: state => state.app.confCmsData
      }),
      sessionBanners() {
        return this.getModalBanners(this.sessionId);
      }
    },
    mounted() {
      // If needed...
    },
    methods: {
      getModalBanners(sessionId) {
        // DISCUSS: Refresh configuration every 10 minutes?
        if (!this.confCmsData || !this.confCmsData?.modal_banners_blocks || !sessionId) return [];
        return this.confCmsData?.modal_banners_blocks?.filter(x => x.banner_session_id == sessionId);
      }
    }
  };
</script>
