<template>
  <div class="search__card p-4">
    <div class="search__card__header">
      <div class="d-flex align-items-center">
        <b-img
          class="search__card__header__picture mr-3"
          :src="result.source.thumbnail ? result.source.thumbnail : defaultPicture"
          fluid
          alt="Speaker's Picture"
        ></b-img>
        <div>
          <span
            class="search__card__header__name font-black"
            v-html="result.highlight['fullname.text'] ? result.highlight['fullname.text'][0] : result.source.fullName"
          ></span>
          <div class="lh-1">
            <span
              class="search__card__header__institution font-regular"
              v-if="result.source.institution && result.source.institution.name"
              v-html="result.highlight['institution.name.text'] ? result.highlight['institution.name.text'][0] : result.source.institution.name"
            ></span>
            <span
              class="search__card__header__institution font-regular"
              v-if="result.source.institution && result.source.institution.country"
              v-html="`, ${result.highlight['institution.country.text'] ? result.highlight['institution.country.text'][0] : result.source.institution.country}`"
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div class="search__card__body">
      <template v-if="result.source.primarySpeciality">
        <span class="search__card__body__specialities_title font-black">Specialities</span>
        <span
          class="search__card__body__specialities"
          v-if="result.source.primarySpeciality"
          v-html="result.highlight['primary_speciality.text'] ? result.highlight['primary_speciality.text'][0] : result.source.primarySpeciality"
        ></span>
      </template>
      <blockquote class="search__card__body__snippets pt-4" v-if="result.highlight['institution.name.text']">
        <span>Institution : </span>
        <span v-html="result.highlight['institution.name.text'][0]"></span>
      </blockquote>
      <blockquote class="search__card__body__snippets pt-4" v-else-if="result.highlight['related_content.title.text']">
        <span>Related content: </span>
        <span v-html="result.highlight['related_content.title.text'][0]"></span>
      </blockquote>
      <blockquote class="search__card__body__snippets pt-4" v-else-if="result.highlight['secondary_specialities.text']">
        <span>Specialities: </span>
        <span v-html="result.highlight['secondary_specialities.text'][0]"></span>
      </blockquote>
      <blockquote class="search__card__body__snippets pt-4" v-else-if="result.highlight['topics_hierarchy.text']">
        <span>Topic: </span>
        <span v-html="result.highlight['topics_hierarchy.text'][0]"></span>
      </blockquote>
      <blockquote class="search__card__body__snippets pt-4" v-else-if="result.highlight['related_content.topics_hierarchy.text']">
        <span>Topic: </span>
        <span v-html="result.highlight['related_content.topics_hierarchy.text'][0]"></span>
      </blockquote>
    </div>

    <!-- View action -->
    <div class="search__card__footer text-right">
      <a v-if="ressourcesMovedTo365 && result.source.dbId" :href="`${esc365PersonUrl(result.source.dbId)}`" class="btn btn-congress rounded-pill"> See details </a>
      <router-link
        v-else-if="!ressourcesMovedTo365 && result.source.digitalUrl && result.source.digitalUrl"
        :to="`/${eventRoute}${result.source.digitalUrl}`"
        class="btn btn-congress rounded-pill"
      >
        See details
      </router-link>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import DefaultPicture from '@/assets/images/platform/default-picture.jpg';
  import esc365Mixin from 'src/mixins/esc365Mixin';

  export default {
    name: 'SpeakerResultCard',
    mixins: [esc365Mixin],
    props: {
      result: Object,
    },
    data() {
      return {
        defaultPicture: DefaultPicture,
      };
    },
    mounted() {
      // If needed...
    },
    computed: {
      ...mapState({
        eventRoute: (state) => state.app.eventRoute,
        ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
      }),
      photoUrl() {
        return this.result.thumbnail;
      },
    },
    methods: {
      extractTopicFromSnippet(rawTopics) {
        let rawTopicsList = rawTopics?.split('|');
        let rawTopicsToDisplay = rawTopicsList?.find((rt) => rt.includes('<em>'));
        return rawTopicsToDisplay;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .search__card {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 2rem;
    border: 0;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    background: white;
    border: 0;
  }

  .search__card__header {
    font-size: 1.2rem;

    &__institution {
      margin-top: 4px;
      font-size: 0.9rem;
    }

    &__picture {
      width: 100px;
      border-radius: 1.25rem;
      height: auto;
      border: solid 1px #e2e2e2;
    }
  }

  .search__card__header__button {
    display: inline-block;
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: 0px;
    padding: 7px;
    border-left: solid 1px $grey-lighter;
    border-bottom: solid 1px $grey-lighter;
    border-radius: 0 6px;
  }

  .search__card__body {
    margin-top: 1rem;

    &__specialities {
      display: block;
      font-size: 0.92rem;
    }
  }

  .search__card__footer {
    margin-top: auto;
    padding-top: 2rem;
  }

  .search__card__footer__action {
    color: var(--congress);
    font-size: 0.9rem;

    &:hover,
    :focus {
      text-decoration: none;
    }
  }

  .search__card__body__snippets {
    font-size: 0.8em;
  }

  ::v-deep em {
    font-style: italic !important;
  }
  ::v-deep blockquote em {
    font-style: italic;
    font-weight: bold;
  }
</style>
