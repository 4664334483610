<template>
  <div>
    <div class="timeLine px-lg-3">
      <b-row class="p-0 p-md-2 mb-4 text-center font-black">
        <b-col
          :key="eventDay.toString()"
          v-for="(eventDay, index) in eventDays"
          class="date__text"
          :class="{ selected: isSelectedDay(eventDay) }"
          :style="{ cursor: !isSelectedDay(eventDay) ? 'pointer' : null, color: isSelectedDay(eventDay) ? 'var(--congress)' : null }"
          :data-v-step-schedule="index == 0 && ($screen.lg || $screen.xl || $screen.xxl) ? 0 : null"
        >
          <span @click="onDayClick(eventDay)" class="d-block">{{ eventDay | formatUnixEpocDayPart }}</span>
        </b-col>
      </b-row>
    </div>

    <b-row>
      <!-- IsLoadingTemplate -->
      <template v-if="isLoading">
        <b-col cols="6" md="4" lg="3" v-for="index in 4" :key="index" class="pb-2 pb-md-4 px-1 px-md-2">
          <b-skeleton-img no-aspect height="320px"></b-skeleton-img>
        </b-col>
      </template>

      <!-- MainTemplate -->
      <template v-if="!isLoading && resultsFiltered.length > 0">
          <b-col v-for="result in resultsFiltered" :key="result.id" cols="12" sm="6" md="6" lg="4" xl="3" class="mb-4 card-deck m-0 p-0">
            <ListSessionCard :session="result.session" @favorite-remove="onFavoriteRemove" />
          </b-col>
      </template>

      <!-- No result for the day -->
      <template v-if="!isLoading && resultsFiltered.length <= 0">
        <h5 class="px-3">No matches were found for the day selected.</h5>
      </template>

      <!-- IsLoadingMoreTemplate -->
      <template v-if="isLoadingMore">
        <b-col cols="12"></b-col>
        <b-col cols="6" md="4" lg="3" v-for="index in 4" :key="index" class="pb-2 pb-md-4 px-1 px-md-2">
          <b-skeleton-img no-aspect height="320px"></b-skeleton-img>
        </b-col>
      </template>

      <!-- LoadMore (Button) -->
      <!-- <b-col cols="12">
        <b-button variant="congress" @click.prevent="loadMoreResult()" v-if="!isLoading && loadMoreVisible && !isLoadingMore">
          Load more <i class="las la-sync"></i>
        </b-button>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
  import ListSessionCard from 'src/components/Cards/ListSessionCard';
  import { constants } from 'src/constants';
  import { DateHelper } from 'src/utils/date-helper';
  import { mapGetters, mapState } from 'vuex';
  import { MediaApiService } from 'src/services/media-api';

  export default {
    name: 'ListSessionBlock',
    components: {
      ListSessionCard,
    },
    props: {
      items: Array,
    },
    data() {
      return {
        isLoading: true,
        isLoadingMore: false,
        results: [],
        resultsToDisplay: [],
        //skip: 0,
        loadMoreVisible: false,
        daySelected: null,
        eventDays: [],
        resultsFiltered: [],
        timeFromServer: null,
      };
    },
    computed: {
      ...mapGetters(['eventStartDate', 'eventEndDate']),
      ...mapState({
        eventId: (state) => state.app.eventId,
        eventStartDate: (state) => state.app.eventStartDate,
        eventEndDate: (state) => state.app.eventEndDate,
      }),
    },
    async mounted() {
      await this.fetchtime();
      this.fetchResults();
      this.refreshDaysList();
      this.noDaysSelected();

    },
    methods: {
      fetchResults() {
        //console.log('this.results '+JSON.stringify(this.results));
        //console.log('this.results ' + this.results.length);
        this.results = this.items;
        //console.log(this.results);
        let nbResults = this.results.length;
        let nbDisplayedResults = this.resultsToDisplay.length;
        let nbResultsToTake = Math.min(constants.LOAD_MORE_SESSIONS_COUNT, nbResults - nbDisplayedResults);
        if (nbResultsToTake > 0) {
          this.resultsToDisplay = this.results.slice(nbDisplayedResults, nbDisplayedResults + nbResultsToTake);
          this.resultsFiltered = this.resultsToDisplay;
        }
        this.loadMoreVisible = this.resultsToDisplay.length < this.results.length;

        this.isLoading = false;

        //console.log('this.resultsToDisplay '+JSON.stringify(this.resultsToDisplay));
        //console.log('this.resultsToDisplay ' + this.resultsToDisplay.length);
      },
      loadMoreResult() {
        this.isLoadingMore = true;

        let nbResults = this.results.length;
        let nbDisplayedResults = this.resultsToDisplay.length;
        let nbResultsToTake = Math.min(constants.LOAD_MORE_SESSIONS_COUNT, nbResults - nbDisplayedResults);
        //console.log('nbResultsToTake ' + nbResultsToTake);
        let resultsToAppend = this.results.slice(nbDisplayedResults, nbDisplayedResults + nbResultsToTake);
        //console.log('resultsToAppend ' + JSON.stringify(resultsToAppend));
        if (nbResultsToTake > 0) {
          this.resultsToDisplay = [...this.resultsToDisplay, ...resultsToAppend];
          this.resultsFiltered = [...this.resultsToDisplay, ...resultsToAppend];
        }
        this.loadMoreVisible = this.resultsToDisplay.length < this.results.length;

        this.isLoadingMore = false;

        //console.log('this.resultsToDisplay ' + this.resultsToDisplay.length);
      },
      onFavoriteRemove(e) {
        //console.log('onFavoriteRemove ' + JSON.stringify(e));
        //console.log('this.resultsToDisplay ' + this.resultsToDisplay.length);
        //console.log('this.results '+JSON.stringify(this.results));
        //console.log('this.resultsToDisplay '+JSON.stringify(this.resultsToDisplay));
        if (e != null && e.digitalSessionId != null) {
          //console.log('Filtering...');
          this.resultsToDisplay = this.resultsToDisplay.filter((x) => x.digitalSessionId != e.digitalSessionId);
          this.results = this.results.filter((x) => x.digitalSessionId != e.digitalSessionId);
        }
        //console.log('this.resultsToDisplay ' + this.resultsToDisplay.length);
      },

      refreshDaysList() {
        const startDate = DateHelper.onSiteDateTime(this.eventStartDate);
        const endDate = DateHelper.onSiteDateTime(this.eventEndDate);
        //console.log(startDate, endDate);
        this.eventDays = DateHelper.getDates(startDate, endDate);
        //console.log(this.eventDays);
      },
      isSelectedDay(day) {
        return this.$options.filters.formatUnixEpocDayPart(day) === this.$options.filters.formatUnixEpocDayPart(this.timeToDisplay);
      },
      moveToDayByDay(day) {
        this.timeToDisplay = day.getTime();
        // if (this.$route.path.includes(constants.ROUTE_PROGRAMME) && this.$route.query.day != this.timeToDisplay) {
        //   this.$router.push({ query: { day: this.timeToDisplay } });
        // }
        this.daySelected = day;

        this.resultsFiltered = this.results;
        const dayDateOnly = new Date(day.getFullYear(), day.getMonth(), day.getDate());
        this.resultsFiltered = this.results.filter((x) => {
          const sessionStartDate =  DateHelper.onSiteDateTime(x.session.startTime);
          const sessionDateOnly = new Date(sessionStartDate.getFullYear(), sessionStartDate.getMonth(), sessionStartDate.getDate());
          return sessionDateOnly.getTime() === dayDateOnly.getTime();
        });
      },
      onDayClick(date) {
        if (!this.isSelectedDay(date)) this.moveToDayByDay(date);
      },
      noDaysSelected(){
        if (!this.daySelected && this.results) {
          const serverTime = this.timeFromServer ? DateHelper.onSiteDateTime(this.timeFromServer) : null;
          if (serverTime >= DateHelper.onSiteDateTime(this.eventStartDate) && serverTime <= DateHelper.onSiteDateTime(this.eventEndDate)) {
            this.daySelected = serverTime;
            this.moveToDayByDay(this.daySelected);
          } 
          else {
              this.daySelected = DateHelper.onSiteDateTime(this.results[0]?.session.startTime);
              this.moveToDayByDay(this.daySelected);
            }
          }
        },
        
    async fetchtime() {
        const { data } = await MediaApiService.getTime();
        this.timeFromServer = data;
        //console.log('timeFromServer', this.timeFromServer);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';


</style>
