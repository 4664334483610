<template>
  <section class="timeline" :data-expanded="expandedData" :data-toggable="toggable">
    <!-- Time line header -->

    <!-- timeline items -->
    <div class="timeline-items pt-3" :class="toggable ? ' mr-4' : 'mr-0'" v-if="searchResultsByChannel.length">
      <!-- timeline full (all rows) -->
      <div class="time-line-full">
        <!-- timeline full items -->
        <div v-if="slidesCount > 0">
          <b-carousel ref="timelineCarousel" :interval="0" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
            <b-carousel-slide v-for="slideNumber in slidesCount" :key="slideNumber">
              <template #img>
                <b-row class="channels-row no-gutters w-100" data-v-step-search-calendar="0">
                  <!-- Previous -->
                  <b-col v-if="needChannelCarousel" cols="carousel__control">
                    <div class="carousel__control carousel__control--previous" @click="onCarouselPrevClick()" :class="{ invisible: isSliding }">
                      <i class="las la-6x la-t-plus-1 la-angle-left"></i>
                    </div>
                  </b-col>

                  <b-col v-for="channel in getChannelsBySlide(slideNumber)" :key="channel.id">
                    <span class="channels-row__text" v-html="$options.filters.formatChannelName(channel.name)"></span>
                  </b-col>

                  <!-- Next -->
                  <b-col v-if="needChannelCarousel" cols="carousel__control">
                    <div class="carousel__control carousel__control--next" @click="onCarouselNextClick()" :class="{ invisible: isSliding }" data-v-step-schedule="1">
                      <i class="las la-6x la-t-plus-1 la-angle-right"></i>
                    </div>
                  </b-col>
                </b-row>
                <div class="sessions-row-container">
                  <b-row class="sessions-row" :style="`min-height:${20 + dayDuration / (1000 * stepSizeFactor)}px`" align-h="center">
                    <b-col cols="carousel__timeline">
                      <fragment v-for="index in Math.ceil(dayDuration / (1000 * 60 * 30))" :key="index">
                        <div :style="`padding-left: 25px;min-height:${(1000 * 60 * 30) / (1000 * stepSizeFactor)}px;`" class="timeline-time">
                          <div v-if="showSharpTimeLine(index)" style="z-index: 2; position: absolute; width: 100%; border-top: 1px solid #e2e2e2"></div>
                          <span style="z-index: 2; margin: 5px 0px">{{ (startDayTime + (index - 1) * 1000 * 60 * 30) | formatUnixEpocTimePart }}</span>
                          <div class="channel-timeline-line"></div>
                        </div>
                      </fragment>
                    </b-col>

                    <b-col v-for="(channel, channelLoopIndex) in getChannelsBySlide(slideNumber)" :key="channel.id" class="channels-divider">
                      <div
                        class="px-2"
                        :style="`overflow:hidden;position:absolute;width:100%;height:${
                          (channel.items[0].startTime - startDayTime) / (1000 * stepSizeFactor)
                        }px;top:0px;`"
                      >
                        <div class="stripe" style="height: 100%"></div>
                      </div>

                      <div
                        v-for="item in channel.items"
                        :key="item.id"
                        class="px-1 px-md-2 session-item"
                        :style="`z-index: 3;position:absolute;width:100%;height:${(item.endTime - item.startTime) / (1000 * stepSizeFactor)}px;min-height:${
                          (item.endTime - item.startTime) / (1000 * stepSizeFactor)
                        }px;top:${(item.startTime - startDayTime) / (1000 * stepSizeFactor)}px;`"
                      >
                        <b-card no-body :class="`h-100 p-3 session-item-card text-left`">
                          <router-link
                            v-if="!ressourcesMovedTo365"
                            :to="`/${eventRoute}/sessions/${item.furl}`"
                            class="d-block stretched-link" style="height: 0">
                          </router-link>
                          <a
                            v-else
                            :href="`${esc365SessionUrl(item.scientificSessionId)}`"
                            class="d-block  stretched-link" style="height: 0">
                          </a>
                          <span class="session__favorite__button">
                            <FavoriteButton
                              :title="item.title"
                              :digitalSessionId="parseInt(item.digitalSessionId)"
                              :scientificSessionId="item.idbId"
                              width="16"
                              height="16"
                              data-v-step-search="4"
                            />
                          </span>
                          <div class="d-inline-flex">
                            <span class="session__time font-bold mr-2">
                              {{ item.startTime | formatUnixEpocTimePart }} - {{ item.endTime | formatUnixEpocTimePart }}
                            </span>
                            <span class="session__now" v-if="showLiveBadge && item.isLive"> live </span>
                            <span class="session__location--online d-inline-flex align-items-center font-regular" v-if="item.isOnline">
                              <span data-v-15c722b7="" class="mr-1 material-icons-round"> cast </span> <span>Online</span>
                            </span>
                          </div>
                          <div v-if="!isSponsoredSession(item)">
                            <div class="session__title font-black lh-1 mt-2">
                              {{ item.title }}
                            </div>
                            <div class="session__sub_title lh-1">
                              {{ item.subTitle }}
                            </div>
                          </div>
                          <div v-else>
                            <div class="sponsored-session">
                              <div class="sponsored-front">
                                <div class="session__title font-black lh-1 mt-2">Industry Session</div>
                              </div>
                              <div class="sponsored-back">
                                <div class="session__title font-black lh-1 mt-2">
                                  {{ item.title }}
                                </div>
                                <div class="session__sub_title lh-1">
                                  {{ item.subTitle }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-if="!ressourcesMovedTo365 && (item.isLive || item.isReplay)">
                            <SessionButton :session="item" :forceInteract="item.isReplay" />
                            <div class="session__progress pt-2" v-if="item.isLive">
                              <b-progress :value="item.progress" :max="item.duration" height="0.5em"></b-progress>
                            </div>
                          </div>
                        </b-card>
                      </div>

                      <div
                        class="px-2"
                        :style="`overflow:hidden;position:absolute;width:100%;height:${
                          (endDayTime - channel.items[channel.items.length - 1].endTime) / (1000 * stepSizeFactor)
                        }px;top:${(channel.items[channel.items.length - 1].endTime - startDayTime) / (1000 * stepSizeFactor)}px;`"
                      >
                        <div class="stripe" style="height: 100%"></div>
                      </div>
                      <div
                        id="scrollTarget"
                        ref="timeIndicator"
                        class="time-indicator scrollTarget"
                        v-if="(channelLoopIndex = 1 && serverTime >= startDayTime && serverTime <= endDayTime)"
                        :style="`top:${(serverTime - startDayTime) / (1000 * stepSizeFactor)}px;`"
                      ></div>
                    </b-col>

                    <b-col cols="carousel__control"></b-col>
                  </b-row>
                </div>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
    <!-- Time line footer -->
    <v-tour v-if="!isMobile" name="search-calendar-tour" :steps="steps" :callbacks="callbacks" :active="isTourActive"></v-tour>
  </section>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  //import { MediaApiService } from 'src/services/media-api';
  import { DateHelper } from 'src/utils/date-helper';
  import SessionButton from 'src/components/Buttons/SessionButton';
  import FavoriteButton from 'src/components/Buttons/FavoriteButton';
  import { constants } from 'src/constants';
  import hybridEventMixin from 'src/mixins/hybridEventMixin';
  import toursManagerMixin from 'src/mixins/toursManagerMixin';
  import openModalMixin from 'src/mixins/openModalMixin';
  import esc365Mixin from 'src/mixins/esc365Mixin';

  export default {
    name: 'SearchResultsCalendar',
    mixins: [hybridEventMixin, openModalMixin, toursManagerMixin, esc365Mixin],
    components: {
      SessionButton,
      FavoriteButton,
    },
    props: {
      channelId: Number,
      expanded: Boolean,
      toggable: {
        default: true,
        type: Boolean,
      },
      results: Array,
      daySelected: Date,
      query: String,
      selectedDocTypesFacetsValues: {
        days: [],
        types: [],
        tracks: [],
        topics: [],
        country: [],
        specialities: [],
      },
      docType: String,
      channels: Array,
      serverTime: Number,
    },
    data() {
      return {
        searchResultsByChannel: [],
        isTimelineFullFirstOpen: true,
        isSliding: false,
        allowNextDay: false,
        allowPreviousDay: false,
        dayDuration: 0,
        startDayTime: 0,
        endDayTime: 0,
        hover: {},
        isTourActive: false,
        steps: [
          {
            target: '[data-v-step-search-calendar="0"]',
            header: {
              //title: '<b>Find your programme</b>'
            },
            content: `Discover the programme by room. `,
            params: {
              placement: 'bottom',
              highlight: true,
              enableScrolling: false,
            },
          },
        ],
        callbacks: {
          onSkip: () => this.onTourCompleted('searchCalendar'),
          onFinish: () => this.onTourCompleted('searchCalendar'),
        },
      };
    },
    computed: {
      channelsPerSlide() {
        let responsivechannelsPerSlide; /* constants.CHANNELS_COUNT_BY_SLIDE_POC; */
        if (this.$screen.xxl) responsivechannelsPerSlide = 6;
        else if (this.$screen.xl) responsivechannelsPerSlide = 5;
        else if (this.$screen.lg) responsivechannelsPerSlide = 4;
        else if (this.$screen.md) responsivechannelsPerSlide = 3;
        else if (this.$screen.sm) responsivechannelsPerSlide = 2;
        else if (this.$screen.xs) responsivechannelsPerSlide = 1;
        return responsivechannelsPerSlide;
      },
      stepSizeFactor() {
        return constants.STEP_SIZE_FACTOR;
      },
      slidesCount() {
        if (!this.timeLineFull || this.timeLineFull.length == 0) return 0;

        return Math.ceil(this.timeLineFull.length / this.channelsPerSlide /* constants.CHANNELS_COUNT_BY_SLIDE_POC */);
      },
      // Timeline status data attribute
      expandedData() {
        return this.expanded ? 'expanded' : 'collapsed';
      },
      needChannelCarousel() {
        return this.timeLineFull.length > this.channelsPerSlide /* constants.CHANNELS_COUNT_BY_SLIDE_POC */;
      },
      poolingTimelineDigestInterval() {
        return parseInt(process.env.VUE_APP_POOLING_TIMELINE_DIGEST_INTERVAL);
      },
      isMobile() {
        return this.$screen.breakpoint === 'xs' || this.$screen.breakpoint === 'sm' || this.$screen.breakpoint === 'md'; // window.innerWidth <= 768; // Détecte les écrans de 768px de large ou moins comme étant des appareils mobiles
      },
      ...mapGetters(['isPoolingEnabled', 'eventStartDate', 'eventEndDate']),
      ...mapState({
        eventRoute: (state) => state.app.eventRoute,
        eventStartDate: (state) => state.app.eventStartDate,
        eventEndDate: (state) => state.app.eventEndDate,
        confCmsData: (state) => state.app.confCmsData,
        ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
      }),
      channelList() {
        return this.results;
      },
      timeLinePosition() {
        const totalDayDuration = this.endDayTime - this.startDayTime;
        return ((this.serverTime - this.startDayTime) / totalDayDuration) * 100;
      },
      timeLineFull() {
        let _timeLineFull = [];
        this.searchResultsByChannel.forEach((channel) => {
          let channelItems = channel.items.map((x) => {
            return {
              title: x.title,
              subTitle: x.subTitle,
              digitalSessionId: x.digitalSessionId,
              idbId: x.idbId,
              isOnline: x.isOnline,
              startTime: x.startTimeUnixEpoc,
              endTime: x.endTimeUnixEpoc,
              id: x.digitalSessionId,
              externalLink: null,
              rooms: x.room || x.channel ? x.room + ' ' + x.channel : 'On demand',
              hasExternalLink: x.externalLink && x.externalLink !== '',
              platformFullVideoId: x.replayVideoId,
              isInInterlude: false, //this.isInInterlude,
              isLive: x.startTimeUnixEpoc <= this.serverTime && x.endTimeUnixEpoc > this.serverTime,
              isReplay:
                x.startTimeUnixEpoc < this.serverTime &&
                x.endTimeUnixEpoc < this.serverTime &&
                x.replayVideoId &&
                x.replayVideoId !== undefined &&
                x.replayVideoId !== null &&
                x.replayVideoId !== '',
              duration: x.endTimeUnixEpoc - x.startTimeUnixEpoc,
              durationMinutes: x.calculatedDuration,
              progress: this.serverTime - x.startTimeUnixEpoc,
              scientificSessionId: x.dbId,
              channelId: x.channelId,
              isIndustry: x.isIndustry,
              furl: x.furl,
            };
          });
          _timeLineFull.push({ channelId: channel.channelId, name: channel.name, order: channel.order, items: channelItems });
        });
        _timeLineFull = _timeLineFull.filter((x) => x.items.length > 0);
        return _timeLineFull;
      },
    },
    watch: {
      async results(newResults, oldResults) {
        if (newResults && newResults !== oldResults) await this.fetchtimeLineFull();
      },
    },
    async mounted() {
      await this.fetchtimeLineFull();
      this.$nextTick(() => {
        if (!this.isMobile && !this.isTourActive) {
          this.isTourActive = true;
          if (!this.tours || !this.tours.searchCalendar) this.$tours['search-calendar-tour'].start();
        }
      });
    },
    methods: {
      isSelectedDay(day) {
        return this.$options.filters.formatUnixEpocDayPart(day) === this.$options.filters.formatUnixEpocDayPart(this.timeToDisplay);
      },
      showSharpTimeLine(index) {
        return (DateHelper.isSharpHour(this.startDayTime) && index % 2 == 1) || (!DateHelper.isSharpHour(this.startDayTime) && index % 2 == 0);
      },
      isSponsoredSession(session) {
        return session && session?.isIndustry && this.docType != 'DigitalSessionIndustry';
      },
      getSessionBgCssClass(session) {
        let bgCssClass = 'default-session';
        if (session && session?.isIndustry) bgCssClass = 'sponsored-session';

        return bgCssClass;
      },
      async fetchtimeLineFull() {
        this.$emit('is-loading', true);
        this.searchResultsByChannel = [];
        if (this.channels?.length && this.results?.length) {
          this.channels.forEach((channel) => {
            let channelItems = this.results.filter((x) => x.source.room == channel.label);
            channelItems = channelItems.map((x) => x.source);
            this.searchResultsByChannel.push({ channelId: channel.id, name: channel.labelZone, order: channel.order, items: channelItems });
          });
          this.searchResultsByChannel = this.searchResultsByChannel.filter((x) => x.items.length > 0);

          /*
        let timeLine = [];
        let this.serverTime = this.serverTime;
        if (this.channels?.length && this.results?.length) {
          this.channels.forEach((channel) => {
            let channelItems = this.results.filter((x) => x.source.room == channel.label);
            channelItems = channelItems.map((x) => {
              return {
                title: x.source.title,
                subTitle: x.source.subTitle,
                digitalSessionId: x.source.digitalSessionId,
                idbId: x.source.idbId,
                isOnline: x.source.isOnline,
                startTime: x.source.startTimeUnixEpoc,
                endTime: x.source.endTimeUnixEpoc,
                id: x.source.digitalSessionId,
                externalLink: null,
                rooms: x.source.room || x.source.channel ? x.source.room + ' ' + x.source.channel : 'On demand',
                hasExternalLink: x.source.externalLink && x.source.externalLink !== '',
                platformFullVideoId: x.source.replayVideoId,
                isInInterlude: false, //this.isInInterlude,
                isLive: x.source.startTimeUnixEpoc <= this.serverTime && x.source.endTimeUnixEpoc > this.serverTime,
                isReplay:
                  x.source.startTimeUnixEpoc < this.serverTime &&
                  x.source.endTimeUnixEpoc < this.serverTime &&
                  x.source.replayVideoId &&
                  x.source.replayVideoId !== undefined &&
                  x.source.replayVideoId !== null &&
                  x.source.replayVideoId !== '',
                duration: x.source.endTimeUnixEpoc - x.source.startTimeUnixEpoc,
                durationMinutes: x.source.calculatedDuration,
                progress: this.serverTime - x.source.startTimeUnixEpoc,
                scientificSessionId: x.source.dbId,
                channelId: x.source.channelId,
                isIndustry: x.source.isIndustry,
                furl: x.source.furl,
              };
            });
            timeLine.push({ channelId: channel.id, name: channel.label, order: channel.order, items: channelItems });
          });
          timeLine = timeLine.filter((x) => x.items.length > 0);
          */

          //Other way to do the same thing. Not sure which is fastest
          /*
          timeLine = this.results?.reduce((acc, result) => {
            const room = result.source.room;
            const channel = this.channels.find((x) => x.label === room);
            let accChannel = acc?.find((x) => x.name === room);
            if (!accChannel) {
              accChannel = { channelId: channel.id, name: channel.label, order: channel.order, items: [] };
              accChannel.items.push(result.source);
              acc.push(accChannel);
            } else {
              accChannel?.items.push(result.source);
            }
            return acc;
          }, []);
          timeLine = timeLine.sort((a, b) => a.order - b.order);
          */

          /*
          let timeLineFlat = this.searchResultsByChannel.flatMap((x) => x.items);
          this.startDayTime = timeLineFlat.reduce((a, b) => (a.startTime < b.startTime ? a : b)).startTime;
          this.endDayTime = timeLineFlat.reduce((a, b) => (a.endTime > b.endTime ? a : b)).endTime;
          */
          this.startDayTime = this.results?.reduce((a, b) => (a.source.startTimeUnixEpoc < b.source.startTimeUnixEpoc ? a : b)).source.startTimeUnixEpoc;
          this.endDayTime = this.results?.reduce((a, b) => (a.source.endTimeUnixEpoc > b.source.endTimeUnixEpoc ? a : b)).source.endTimeUnixEpoc;

          this.startDayTime = DateHelper.ceilToHalfHour(this.startDayTime);
          this.endDayTime = DateHelper.floorToHalfHour(this.endDayTime);
          this.dayDuration = this.endDayTime - this.startDayTime;

          this.$emit('is-loading', false);
        }
      },

      // Toggle timeLine status flag & send timeline-toggle event
      toggleTimeLine: function () {
        this.$emit('timeline-toggle');
      },
      // Carousel
      getChannelsBySlide(slideNumber) {
        const slideIndex = slideNumber - 1;

        const sliceFrom = slideIndex * this.channelsPerSlide; /*constants.CHANNELS_COUNT_BY_SLIDE_POC*/
        let sliceTo = sliceFrom + this.channelsPerSlide; /*constants.CHANNELS_COUNT_BY_SLIDE_POC*/
        if (sliceTo > this.timeLineFull.length) sliceTo = this.timeLineFull.length;

        return this.timeLineFull.slice(sliceFrom, sliceTo);
      },
      onCarouselPrevClick() {
        this.$refs.timelineCarousel.prev();
      },
      onCarouselNextClick() {
        this.$refs.timelineCarousel.next();
      },
      onSlideStart() {
        this.isSliding = true;
      },
      onSlideEnd() {
        this.isSliding = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .session__link {
    text-decoration: none;
    color: inherit;
  }

  .timeline {
    font-size: 0.9em;
    height: 100%;

    /* timeline toggable mode */
    &[data-toggable] {
      .timeline-title {
        /*cursor: pointer;*/
        /*full view disabled*/
        border-radius: 0px 0px 5px 0px;
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: $grey-light;
        background-color: $secondary;

        /* timeline title date */
        div:nth-child(1) {
          width: calc(100% - 60px);
        }

        /* timeline title button (calendar or close) */
        div:nth-child(2) {
          // display: inline-block;
          text-align: center;
          margin: auto;
          font-size: 2em;
          width: 60px;
          color: var(--congress);
        }
      }

      .timeline-toggle-action {
        cursor: pointer;
      }
    }

    /* timeline not toggable mode */
    &:not([data-toggable]) {
      .timeline-title {
        border-radius: none;

        /* timeline title date */
        div:nth-child(1) {
          width: 100%;
        }

        /* timeline title button (calendar or close) */
        div:nth-child(2) {
          display: hidden;
        }
      }
    }
  }

  /* timeline title */
  .timeline-title {
    /* timeline title date */
    div:nth-child(1) {
      color: $secondary;
      background-color: lightgray;
      text-transform: capitalize;
      font-size: 1.1em;
    }
  }

  /* timeline vertical line */
  .timeline-line {
    position: absolute;
    width: 1px;
    height: 80%;
    left: 24px;
    background: var(--congress);
  }

  /* timeline vertical line */
  .channel-timeline-line {
    width: 1px;
    height: 80%;
    margin-left: 19px;
    background: var(--congress);
  }

  /* timeline items content */
  .timeline-items {
    padding-left: 10px;
    height: 100%;
    min-height: 110vh;
    background-color: $secondary;
    border-radius: 2rem;
    border: 1px solid #e5e5e5;
    overflow: hidden;
  }

  /* timeline last item stop vertical line and disable bottom separator */
  .timeline-items .timeline-item {
    &:last-child {
      .timeline-text {
        border-bottom: none;
      }

      .timeline-line {
        height: 100%;
        background: linear-gradient(to bottom, var(--congress) 0%, $secondary 100%);
      }
    }
  }

  /* timeline item live now badge */
  .col-timeline-now {
    flex: 0 0 42px;
  }

  .timeline-now {
    position: relative;
    top: 3px;
    left: -4px;
    color: $secondary;
    background-color: var(--congress);
    font-size: 0.6em;
    text-transform: uppercase;
    text-align: center;
    padding: 2px 8px;
    border-radius: 8px;
  }

  /* timeline item bottom separator */
  .timeline-text {
    border-bottom: 1px solid $grey-lighter;
  }

  /* timeline item time */
  .col-timeline-time {
    position: relative;
    flex: 0 0 50px;
  }

  .timeline-time {
    text-align: center;
    margin: auto;
  }

  /* timeline item title */
  .timeline-text__title,
  .session__title {
    padding-right: 5px;
    overflow-wrap: anywhere;
    font-size: 1.2em;
  }

  /* timeline item subtitle */
  .timeline-text__subtitle {
    padding: 5px 0px;
    font-size: 1em;
    text-transform: uppercase;
    color: lighten($grey, 10%);
  }

  /* timeline item description */
  .timeline-text__description,
  .session__sub_title {
    padding: 5px 0px;
    font-size: 1em;
  }

  /* full view background color force for overlay */

  /* timeline collapsed */
  .timeline[data-expanded='collapsed'] {
    /* button calendar icon visible */
    .timeline-expand-action {
      display: inline;
    }

    /* button close icon hidden */
    .timeline-collapse-action {
      display: none;
    }

    /* full view hidden on both desktop and mobile */
    .time-line-full {
      display: none;
    }

    /* digest view visible on both desktop and mobile */
    .time-line-digest {
      display: block;
    }

    .date__text {
      opacity: 1 !important;
    }
  }

  /* timeline expanded */
  .timeline[data-expanded='expanded'] {
    .timeline-items {
      padding-top: 0 !important;
      padding-left: 0 !important;
    }

    /* desktop timeline expanded*/
    @include media-breakpoint-up(sm) {
      /* full view visible */
      .time-line-full {
        display: block;
        /*full view disabled*/
      }

      /* digest view hidden */
      .time-line-digest {
        display: none;
        /*full view disabled*/
      }

      /* button calendar icon hidden */
      .timeline-expand-action {
        display: none;
        /*full view disabled*/
      }

      /* button close icon visible */
      .la-times {
        display: block;
        /*full view disabled*/
      }
    }

    /* mobile timeline expanded*/
    @include media-breakpoint-down(sm) {
      /* timeline toggable mode */
      &[data-toggable] {
        /* full view hidden */
        .time-line-full {
          display: none;
        }

        /* digest view visible */
        .time-line-digest {
          padding: 0.6rem 0 0rem 0.6rem;
          display: block;
        }

        /* button calendar icon hidden */
        .timeline-expand-action {
          display: none;
        }

        /* button close icon visible */
        .timeline-collapse-action {
          display: block;
          cursor: pointer;
        }

        .date__control {
          display: none;
        }
      }

      /* timeline not toggable mode */
      &:not([data-toggable]) {
        /* full view visible */
        .time-line-full {
          display: block;
          /*full view disabled*/
        }

        /* digest view hidden */
        .time-line-digest {
          display: none;
          /*full view disabled*/
        }
      }
    }
  }

  /* full view design */
  /*full view disabled*/

  .channels-row {
    text-align: center;
    box-shadow: 4.5px 2.3px 8.7px 0.3px rgba(0, 0, 0, 0.15);
    padding: 0.5rem 0;
    align-items: center;

    div:nth-child(2) .channels-row__text {
      padding-right: 23px;
    }

    div:nth-last-child(2) .channels-row__text {
      padding-left: 23px;
    }
  }

  .channels-row__text {
    text-transform: uppercase;
    font-size: 0.96rem;
    display: block;
  }

  .sessions-row {
    font-size: 0.8rem;
    background: #f1f2f3;

    /*padding: 1.2rem 0;*/
    /*min-height: 75vh;*/
    /*
    min-height:1200px;
    height:1200px;
    max-height:1200px;
    */
    .channels-divider {
      border-right: 1px solid $grey-lighter;
      max-width: 970px;
      padding: 0;
      /*flex-basis: auto;*/
      /*ie fix center content */
    }

    div:nth-last-child(2) {
      border: none;
    }
  }

  /* Test for load more scroll */
  /*
  .sessions-row-container {

    max-height: 90vh;
    overflow: scroll;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  */

  .session-item-card {
    cursor: pointer;

    &:hover:not(a),
    :focus:not(a) {
      background-color: var(--congress-light) !important;
    }

    ::v-deep .card-link {
      &:focus,
      &:hover {
        color: var(--congress-lighter) !important;
      }
    }
  }

  /* short sessions expand on hover */
  .session-item:hover,
  .session-item:focus {
    z-index: 4 !important;
    display: grid;
    height: auto !important;
  }

  /* sponsored sessions reveal on hover */
  .session-item .sponsored-session {
    background-color: transparent;
  }

  .session-item .sponsored-session .sponsored-back {
    /*position: absolute;*/
    display: none;
    opacity: 0;
    /* animation */
    /*
    transform: scale(0);
    transition: all 1s ease-in-out;
    */
  }

  .session-item:hover .sponsored-session .sponsored-back,
  .session-item:focus .sponsored-session .sponsored-back {
    display: block;
    opacity: 1;
    /* animation */
    /*
    transform: scale(1);
    transition: all 0.25s ease-in-out;
    */
  }

  .session-item .sponsored-session .sponsored-front {
    /*position: absolute;*/
    display: block;
    opacity: 1;
    /* animation */
    /*
    transform: scale(1);
    transition: all 1s ease-in-out;
    */
  }

  .session-item:hover .sponsored-session .sponsored-front,
  .session-item:focus .sponsored-session .sponsored-front {
    display: none;
    opacity: 0;
    /* animation */
    /*
  transform: scale(0);
  transition: all 0.25s ease-in-out;
  */
  }

  .session__favorite__button {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .session__time {
    font-size: 0.9rem;
    color: var(--congress);
  }

  .session__duration {
    font-size: 1em;
    color: $grey;
  }

  .session__now {
    flex: 0 0 42px;
    /*float: right;*/
    color: $secondary;
    background-color: var(--congress);
    font-size: 0.6em;
    text-transform: uppercase;
    text-align: center;
    padding: 2px 8px;
    border-radius: 8px;
    /*margin-right: 25px;*/
    position: absolute;
    right: 30px;
    top: 9px;
  }

  .session-item-card .material-icons-outlined,
  .session-item-card .material-icons-round {
    font-size: 16px;
  }

  [class^='session__location--'] {
    font-size: 1em;
  }

  [class^='session__location--'] span {
  }

  /* Carousel */
  .carousel {
    width: 100%;
  }

  .col-carousel__timeline {
    flex: 0 0 50px;
    text-align: right;
  }

  .col-carousel__control {
    flex: 0 0 36px;

    & .carousel__control--previous {
      margin-left: 12px;
    }

    & .carousel__control--next {
      margin-right: 12px;
    }
  }

  .carousel__control {
    padding: 6px 9px;
    border-radius: 6px;
    cursor: pointer;
  }

  .date__control {
    background-color: var(--congress-dark);
    padding: 0px 4px;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    top: 2px;
  }

  .date__text {
    padding: 0px 10px;
  }

  /* Skeletons */
  .b-skeleton {
    background-color: rgb(224, 224, 224) !important;
  }

  .default-session {
    background-color: #f8f9fa;
  }

  .sponsored-session {
    /*background-color: #e6b0aa;*/
    background-color: #f8f9fa;
  }

  /* date list */
  @media (min-width: 992px) {
    .date__text {
      opacity: 0.6;
    }
  }

  .date__text:hover {
    opacity: 1;
  }

  .date__text a {
    color: #fff;
  }

  .date__text a:hover {
    text-decoration: none;
  }

  .date__text.selected {
    opacity: 1;
    position: relative;
  }

  .date__text.selected:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    left: calc(50% - 15px);
    top: -20px;
    transform: translate(50%, -50%) rotate(45deg);
    background: #fff;
  }

  .btn.btn-congress {
    background-color: #000;
    float: right;
  }

  .timeline-title div.d-lg-block {
    font-size: 1em;
  }

  .time-indicator {
    position: absolute;
    border-bottom: 1px dashed #dc3545;
    left: -50px;
    right: -50px;
    height: 2px;
    z-index: 4;
  }

  /*ONBOARDING TOUR*/
  .v-tour__target--highlighted {
    border-radius: 0.25rem;
  }
</style>
