<template>
  <!-- main content column -->
  <b-col cols="12" class="toggable-main-content pt-0 px-0 overflow-hidden" :class="isTimelineExpanded ? 'd-block d-md-none' : 'd-flex flex-column'">
    <!-- 
    ========================================================================================================================================================================
    HERO BANNER
    ========================================================================================================================================================================
     -->
    <div class="hero__banner">
      <b-col
        v-if="!isMobileAppPage && !isHomePage"
        cols="12"
        class="hero__bg pt-5 px-3 px-md-0 d-flex justify-content-left"
        :style="{ backgroundImage: `url('/congress/img/events/${eventId}/hero-bg.png?v=${this.appVersion}')` }"
      >
        <div class="eventInfo">
          <h1>Happening now</h1>
          <div class="eventDate mt-3"></div>
          <div class="eventDate">Please note that the programme times are displayed on local times in {{ locationEvent }}.</div>
        </div>
      </b-col>
    </div>
    <!-- 
    ========================================================================================================================================================================
    END HERO BANNER
    ========================================================================================================================================================================
     -->
    <b-row>
      <!-- ESC Banner(s) -->
      <b-col :cols="postCongressMode ? 12 : 10" md="12" :offset="postCongressMode ? 0 : 2" offset-md="0">
        <EscBanners filter="live" />
      </b-col>
    </b-row>
    <!-- home tabs (happening now & replays) -->
    <b-row class="no-gutters">
      <b-col cols="12" class="pt-3 pb-2 px-4">
        <HomeTabs :eventId="this.eventId" />
      </b-col>
    </b-row>
    <b-row no-gutters>
      <!-- Industry Banner(s) -->
      <b-col cols="12" md="12" offset-md="0" class="pt-1 pb-3">
        <IndustryBanners filter="live" />
      </b-col>
    </b-row>
    <b-row class="mosaic-row no-gutters">
      <b-col cols="12" class="pt-2 mosaic-container">
        <MosaicWidget v-if="isMosaicEnabled" :mosaicUploadUrl="mosaicUploadUrl" lang="en" />
      </b-col>
    </b-row>
  </b-col>

  <!-- twitter feed column -->
  <!--
    <b-col cols="2" class="twitter-widget-wrapper p-2">
      <TwitterWidget :limit=4 />
    </b-col>
    -->
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import openModalMixin from 'src/mixins/openModalMixin';
  import { constants } from 'src/constants';
  import IndustryBanners from 'src/components/Banners/IndustryBanners';
  import EscBanners from 'src/components/Banners/EscBanners';
  import MosaicWidget from 'src/components/Widgets/MosaicWidget';
  import HomeTabs from './components/HomeTabs';

  export default {
    name: 'Home',
    mixins: [openModalMixin],
    components: {
      EscBanners,
      IndustryBanners,
      HomeTabs,
      MosaicWidget,
    },
    data() {
      return {
        publicPath: process.env.BASE_URL,
      };
    },
    computed: {
      ...mapGetters(['isTimelineExpanded']),
      ...mapState({
        appVersion: (state) => state.app.version,
        eventId: (state) => state.app.eventId,
        postCongressMode: (state) => state.app.postCongressMode,
        preCongressMode: (state) => state.app.preCongressMode,
        homeCmsData: (state) => state.app.homeCmsData,
        chatParticipantConfig: (state) => state.app.chatParticipantConfig,
        locationEvent: (state) => state.app.locationEvent,
      }),
      isMosaicEnabled() {
        return this.chatParticipantConfig && this.chatParticipantConfig.mosaicEventId && this.chatParticipantConfig.mosaicEventId == this.eventId;
      },
      mosaicUploadUrl() {
        return null; //this.chatParticipantConfig && this.chatParticipantConfig.mosaicRoute;
      },
      isHomePage() {
        return this.$route.path === `/${this.eventRoute}` || this.$route.path === `/${this.eventRoute}/` || this.$route.path === `/${this.eventRoute}/home`;
      },
      isMobileAppPage() {
      const pagesWithoutTimeline = [
        `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_PRE_HOME}`,
        `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_HAPPENING_NOW}`,
        `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_INTERACTIVITY}`,
      ];
      return pagesWithoutTimeline.map((x) => `/${this.eventRoute}/${x}`).includes(this.$route.path);
    },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .home__bg {
    // background: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: no-repeat;
    background-color: var(--congress-dark);
    background-position: top center;
    border-radius: 6px;
  }

  .home__countdown__wrapper {
    color: white;
  }

  /*
.mosaic-row
{
      margin-top:500px;
}

.mosaic-container
{
  position: absolute;
  bottom:0;
  display: inline-block;

}
*/

  /* Mosaic */
  @media (max-width: 767.98px) {
    .mosaic-row {
      position: relative;
      left: 0;
      right: 0;
      margin-left: 15px !important;
      width: inherit;
      z-index: 1;
    }
  }

  @media screen and (max-width: 767px) {
    .main-container {
      padding-right: 0 !important;
    }
  }

  @media screen and (min-width: 992px) {
    .toggable-main-content > .row {
      /*margin-bottom: 300px;*/
    }

    .mosaic-row {
      /*margin-bottom: auto !important;*/
    }
  }
</style>
