
class DateHelper {
    static compareTimeDatePart(time1, time2) {
        const date1 = new Date(time1).setHours(0, 0, 0, 0);
        const date2 = new Date(time2).setHours(0, 0, 0, 0);
        if (date1.valueOf() === date2.valueOf()) {
            return 0;
        }
        else if (date1.valueOf() < date2.valueOf()) {
            return -1;
        }
        else if (date1.valueOf() > date2.valueOf()) {
            return 1;
        }
    }

    static compareDays(time1, time2) {
        const date1 = new Date(time1).setHours(0, 0, 0, 0);
        const date2 = new Date(time2).setHours(23, 59, 59, 59);
        if (date1.valueOf() === date2.valueOf()) {
            return 0;
        }
        else if (date1.valueOf() < date2.valueOf()) {
            return -1;
        }
        else if (date1.valueOf() > date2.valueOf()) {
            return 1;
        }
    }

    static addDaysToTime(time, nbDays) {
        return time + nbDays * 24 * 60 * 60 * 1000;
    }

    static addDaysToDate(date, nbDays) {
        const _date = new Date(date);
        _date.setDate(date.getDate() + nbDays);
        return _date;
    }

    static getDates(startDate, endDate) {
        const onsiteStartDate = startDate; //DateHelper.onSiteDateTime(startDate)
        const onsiteEndDate = endDate; //DateHelper.onSiteDateTime(endDate)
        const timeDiff = Math.abs(onsiteEndDate.getTime() - onsiteStartDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
        const dateArr = new Array();
        for (let i = 0; i < diffDays; i++) {
            dateArr.push(new Date(this.addDaysToDate(startDate, i)));
        }
        return dateArr;
        /*
        while (DateHelper.compareDays(currentDate, onsiteEndDate) <= 0) {
            dateArr.push(new Date(currentDate));
            currentDate = this.addDaysToDate(currentDate, 1);
        }*/
    }

    static ceilToHalfHour(time) {
        let roundedTime = new Date(time);
        let timeMinutes = roundedTime.getMinutes();
        if (timeMinutes < 30) roundedTime.setMinutes(0);
        else roundedTime.setMinutes(30);
        roundedTime.setMilliseconds(0);
        roundedTime.setSeconds(0);
        return roundedTime.getTime();
    }

    static floorToHalfHour(time) {
        let roundedTime = new Date(time);
        let timeHours = roundedTime.getHours();
        let timeMinutes = roundedTime.getMinutes();
        if (timeMinutes < 30) roundedTime.setMinutes(30);
        else { roundedTime.setMinutes(0); roundedTime.setHours(timeHours + 1); }
        roundedTime.setMilliseconds(0);
        roundedTime.setSeconds(0);
        return roundedTime.getTime();
    }

    static isSharpHour(time) {
        return new Date(time).getMinutes() === 0;
    }

    static isValidDate(d) {
        return d !== undefined && d !== null && d !== '' && !isNaN(d.valueOf());
    }

    static browserTimeZone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
        /*
        const d = new Date()
        return d.toLocaleTimeString('en-GB', { timeZoneName: 'short' });
        */
    }

    static onSiteDateTime(timestamp) {
        let timeZone
        /*
        //console.log("🚀 ~ file: date-helper.js ~ line 96 ~ DateHelper ~ onSiteDateTime ~ store.state.app.eventId", store.state.app.eventId)
        if (store.state.app.eventId === constants.ESC_ASIA_2022_EVENT_ID) {

            timeZone = 'Asia/Singapore'
        }
        else {
            timeZone = 'Europe/Paris'
        }
        */
        timeZone = 'Europe/Paris'
        //console.log("🚀 ~ file: date-helper.js ~ line 100 ~ DateHelper ~ onSiteDateTime ~ timeZone", timeZone)
        //console.log("🚀 ~ file: date-helper.js ~ line 89 ~ DateHelper ~ onsiteDateTime ~ browserDate", browserDate)
        const browserDate = DateHelper.browserDateTime(timestamp)
        const onsiteDateTimeString = browserDate.toLocaleString("en-US", { timeZone: timeZone });
        //console.log("🚀 ~ file: date-helper.js ~ line 91 ~ DateHelper ~ onsiteDateTime ~ onsiteDateTimeString", onsiteDateTimeString)
        var onsiteDateTime = new Date(onsiteDateTimeString)
        //console.log("🚀 ~ file: date-helper.js ~ line 93 ~ DateHelper ~ onsiteDateTime ~ onsiteDateTime", onsiteDateTime)
        return onsiteDateTime;
    }

    static browserDateTime(timestamp) {
        //console.log("🚀 ~ file: date-helper.js ~ line 87 ~ DateHelper ~ onsiteDateTime ~ timestamp", timestamp)
        let browserDate
        if (!timestamp) browserDate = new Date()
        else if (timestamp instanceof Date) browserDate = timestamp
        else browserDate = new Date(timestamp);

        return browserDate
    }
}

export { DateHelper };

