<template>
  <section class="timeline" :data-expanded="expandedData" :data-toggable="toggable">
    <!-- Time line header -->
    <div class="timeline-title">
      <div class="p-3 pl-4 align-middle">
        <!-- IsTimeLoadingTemplate -->
        <template v-if="isTimeLoading"> <i class="las la-3x la-t-plus-3 la-circle-notch la-spin mr-1"></i> Synchronization... </template>

        <!-- MainTemplate (time) -->
        <template v-if="!isTimeLoading">
          <!-- Time line header -->
          <template v-if="!expanded">
            <span class="date__text">
              {{ timeFromServer | formatUnixEpocDayPart }}
            </span>
          </template>
          <!-- Full calendar header -->
          <template v-else>
            <!-- desktop display -->
            <div v-if="$screen.lg || $screen.xl || $screen.xxl" class="d-none d-lg-block" order-lg="1">
              <span
                :key="eventDay.toString()"
                v-for="(eventDay, index) in eventDays"
                class="date__text"
                :class="{ selected: isSelectedDay(eventDay) }"
                :style="{ cursor: !isSliding && !isLoading && !isSelectedDay(eventDay) ? 'pointer' : null }"
                :data-v-step-schedule="index == 0 && ($screen.lg || $screen.xl || $screen.xxl) ? 0 : null"
              >
                <span @click="onDayClick(eventDay)">{{ eventDay | formatUnixEpocDayPart }}</span>
              </span>
              <a
                v-if="this.confCmsData && this.confCmsData.paag_doc && this.confCmsData.paag_doc.filename"
                :href="this.confCmsData.paag_doc.filename"
                target="_blank"
                class="btn btn-congress"
                >Download the Programme at a glance</a
              >
            </div>
            <!-- mobile display -->
            <div class="d-lg-none" order="1">
              <span class="date__control date__control--previous" @click="onPreviousDayClick()" :class="{ invisible: isSliding || isLoading || !allowPreviousDay }">
                <i class="las la-07x la-t-minus-2_5 la-white la-angle-left"></i>
              </span>
              <span class="date__text" :data-v-step-schedule="$screen.xs || $screen.sm || $screen.md ? 0 : null">
                {{ timeToDisplay | formatUnixEpocDayPart }}
              </span>
              <span class="date__control date__control--next" @click="onNextDayClick()" :class="{ invisible: isSliding || isLoading || !allowNextDay }">
                <i class="las la-07x la-t-minus-2_5 la-white la-angle-right"></i>
              </span>
            </div>
          </template>
        </template>
      </div>

      <div v-if="toggable" @click="toggleTimeLine" class="timeline-toggle-action">
        <i class="las la-2x la-t-plus-6 la-calendar-alt timeline-expand-action"></i>
        <i class="las la-07x la-t-plus-1 la-angle-right timeline-expand-action"></i>
        <i class="las la-2x la-t-plus-6 la-times timeline-collapse-action"></i>
      </div>
    </div>

    <!-- timeline items -->
    <div class="timeline-items pt-3" :class="toggable ? ' mr-4' : 'mr-0'">
      <!-- timeline digest (one row only) -->
      <div class="time-line-digest">
        <!-- IsLoadingTemplate -->
        <template v-if="isLoading">
          <b-row v-for="index in 6" :key="index" class="timeline-item pb-2 no-gutters">
            <b-col cols="timeline-now pr-1">
              <b-skeleton></b-skeleton>
            </b-col>

            <b-col cols="timeline-time">
              <div class="timeline-time px-1">
                <b-skeleton></b-skeleton>
              </div>
              <div class="timeline-line"></div>
            </b-col>

            <b-col class="timeline-text pl-2 pr-2">
              <div class="timeline-text__title"><b-skeleton width="65%"></b-skeleton></div>
              <div class="timeline-text__subtitle"><b-skeleton width="90%"></b-skeleton></div>
            </b-col>
          </b-row>
        </template>

        <!-- MainTemplate (timeline digest items) -->
        <template v-if="!isLoading">
          <b-row v-for="sessionTimeLineItem in timeLine" :key="sessionTimeLineItem.Id" class="timeline-item pb-2 no-gutters">
            <b-col cols="timeline-now">
              <div class="timeline-now" v-if="showLiveBadge && sessionTimeLineItem.isLive">live</div>
            </b-col>

            <b-col cols="timeline-time">
              <div class="timeline-time">
                {{ sessionTimeLineItem.startTime | formatUnixEpocTimePart }}
              </div>
              <div class="timeline-line"></div>
            </b-col>

            <b-col class="timeline-text pl-2 pr-2">
              <span class="session__location--onsite" v-if="sessionTimeLineItem.isOnsite">
                <i class="las la-6x la-landmark mr-1"></i> <span>{{ locationCity }}</span>
              </span>
              <span class="session__location--online" v-if="sessionTimeLineItem.isOnline"> <i class="las la-6x la-globe mr-1"></i> <span>Online</span> </span>
              <div class="timeline-text__title">{{ sessionTimeLineItem.title }}</div>
              <div class="timeline-text__description" v-if="sessionTimeLineItem.description">
                {{ sessionTimeLineItem.description }}
              </div>
              <div class="timeline-text__subtitle" v-if="sessionTimeLineItem.subTitle">
                {{ sessionTimeLineItem.subTitle }}
              </div>
            </b-col>
          </b-row>
        </template>
      </div>

      <!-- timeline full (all rows) -->
      <div class="time-line-full">
        <!-- IsLoadingTemplate -->
        <template v-if="isLoading">
          <b-carousel :interval="10">
            <b-carousel-slide>
              <template #img>
                <b-row class="channels-row no-gutters w-100">
                  <!-- Previous -->
                  <b-col cols="carousel__control">
                    <div class="carousel__control carousel__control--previous">
                      <i class="las la-6x la-t-plus-1 la-white la-angle-left"></i>
                    </div>
                  </b-col>

                  <b-col v-for="index in 3" :key="index">
                    <b-skeleton width="50%" height="30px" class="mx-auto"></b-skeleton>
                  </b-col>

                  <!-- Next -->
                  <b-col cols="carousel__control">
                    <div class="carousel__control carousel__control--next">
                      <i class="las la-6x la-t-plus-1 la-white la-angle-right"></i>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="sessions-row">
                  <b-col cols="carousel__control"></b-col>

                  <b-col v-for="index in 3" :key="index" class="channels-divider">
                    <div class="pb-2 pb-md-4 px-1 px-md-2" v-for="itemIndex in 4" :key="itemIndex">
                      <b-card no-body class="h-100 p-2" bg-variant="light">
                        <div>
                          <b-skeleton width="10%" class="d-inline-block mr-1"></b-skeleton>
                          <b-skeleton width="5%" class="d-inline-block"></b-skeleton>
                        </div>
                        <div class="session__title">
                          <b-skeleton width="50%"></b-skeleton>
                        </div>
                      </b-card>
                    </div>
                  </b-col>

                  <b-col cols="carousel__control"></b-col>
                </b-row>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </template>

        <!-- timeline full items -->
        <div v-if="!isLoading && slidesCount > 0">
          <b-carousel ref="timelineCarousel" :interval="0" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
            <b-carousel-slide v-for="slideNumber in slidesCount" :key="slideNumber">
              <template #img>
                <b-row class="channels-row no-gutters w-100">
                  <!-- Previous -->
                  <b-col v-if="needChannelCarousel" cols="carousel__control">
                    <div class="carousel__control carousel__control--previous" @click="onCarouselPrevClick()" :class="{ invisible: isSliding }">
                      <i class="las la-6x la-t-plus-1 la-white la-angle-left"></i>
                    </div>
                  </b-col>

                  <b-col v-for="channel in getChannelsBySlide(slideNumber)" :key="channel.id">
                    <span class="channels-row__text" v-html="$options.filters.formatChannelName(channel.name)"></span>
                  </b-col>

                  <!-- Next -->
                  <b-col v-if="needChannelCarousel" cols="carousel__control">
                    <div class="carousel__control carousel__control--next" @click="onCarouselNextClick()" :class="{ invisible: isSliding }" data-v-step-schedule="1">
                      <i class="las la-6x la-t-plus-1 la-white la-angle-right"></i>
                    </div>
                  </b-col>
                </b-row>
                <div class="sessions-row-container">
                  <b-row class="sessions-row" :style="`padding-top:10px;min-height:${20 + dayDuration / (1000 * stepSizeFactor)}px`" align-h="center">
                    <b-col cols="carousel__timeline">
                      <fragment v-for="index in Math.ceil(dayDuration / (1000 * 60 * 30))" :key="index">
                        <div :style="`padding-left: 25px;min-height:${(1000 * 60 * 30) / (1000 * stepSizeFactor)}px;`" class="timeline-time">
                          <div v-if="showSharpTimeLine(index)" style="z-index: 2; position: absolute; width: 100%; border-top: 1px solid #e2e2e2"></div>
                          <span style="z-index: 2; margin: 5px 0px">{{ (startDayTime + (index - 1) * 1000 * 60 * 30) | formatUnixEpocTimePart }}</span>
                          <div class="channel-timeline-line"></div>
                        </div>
                      </fragment>
                    </b-col>

                    <b-col v-for="channel in getChannelsBySlide(slideNumber)" :key="channel.id" class="channels-divider">
                      <div
                        class="px-2"
                        :style="
                          `overflow:hidden;position:absolute;width:100%;height:${(channel.items[0].startTime - startDayTime) / (1000 * stepSizeFactor)}px;top:0px;`
                        "
                      >
                        <div class="stripe" style="height: 100%"></div>
                      </div>

                      <div
                        v-for="item in channel.items"
                        :key="item.id"
                        class="px-1 px-md-2 session-item"
                        :style="
                          `z-index: 3;overflow:hidden;position:absolute;width:100%;height:${(item.endTime - item.startTime) /
                            (1000 * stepSizeFactor)}px;min-height:${(item.endTime - item.startTime) / (1000 * stepSizeFactor)}px;top:${(item.startTime -
                            startDayTime) /
                            (1000 * stepSizeFactor)}px;`
                        "
                        @click="onSessionItemClick(item)"
                      >
                        <b-card no-body :class="`h-100 p-1 session-item-card`">
                          <span class="session__favorite__button">
                            <FavoriteButton :title="item.title" :digitalSessionId="item.id" :scientificSessionId="item.scientificSessionId" width="20" height="20" />
                          </span>
                          <div>
                            <span class="session__time">
                              <!--
                              {{ item.startTime | formatUnixEpocDayPart }} - {{ item.startTime | formatUnixEpocTimePart }}
                              -->
                              {{ item.startTime | formatUnixEpocTimePart }}
                            </span>
                            <span class="session__duration">({{ item.duration }}mn)</span>
                            <span class="session__now" v-if="showLiveBadge && item.isLive"> live </span>
                            <span class="session__location--onsite ml-2" v-if="item.isOnsite">
                              <i class="las la-6x la-landmark mr-1"></i> <span>{{ locationCity }}</span>
                            </span>
                            <span class="session__location--online" v-if="item.isOnline"> <i class="las la-6x la-globe mr-1"></i> <span>Online</span> </span>
                          </div>
                          <div v-if="!isSponsoredSession(item)">
                            <div class="session__title">
                              {{ item.title }}
                            </div>
                            <div class="session__sub_title">
                              {{ item.subTitle }}
                            </div>
                          </div>
                          <div v-else>
                            <div class="sponsored-session">
                              <div class="sponsored-front">
                                <div class="session__title">Industry Session</div>
                              </div>
                              <div class="sponsored-back">
                                <div class="session__title">
                                  {{ item.title }}
                                </div>
                                <div class="session__sub_title">
                                  {{ item.subTitle }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <SessionButton :session="item" />
                            <div class="session__progress pt-2" v-if="item.isLive">
                              <b-progress :value="item.progress" :max="item.duration" height="0.5em"></b-progress>
                            </div>
                          </div>
                        </b-card>
                      </div>

                      <div
                        class="px-2"
                        :style="
                          `overflow:hidden;position:absolute;width:100%;height:${(endDayTime - channel.items[channel.items.length - 1].endTime) /
                            (1000 * stepSizeFactor)}px;top:${(channel.items[channel.items.length - 1].endTime - startDayTime) / (1000 * stepSizeFactor)}px;`
                        "
                      >
                        <div class="stripe" style="height: 100%"></div>
                      </div>
                    </b-col>

                    <b-col cols="carousel__control"></b-col>
                  </b-row>
                </div>
                <b-row v-if="showDuplicatedHeader" class="channels-row no-gutters w-100">
                  <!-- Previous -->
                  <b-col v-if="needChannelCarousel" cols="carousel__control">
                    <div class="carousel__control carousel__control--previous" @click="onCarouselPrevClick()" :class="{ invisible: isSliding }">
                      <i class="las la-6x la-t-plus-1 la-white la-angle-left"></i>
                    </div>
                  </b-col>

                  <b-col v-for="channel in getChannelsBySlide(slideNumber)" :key="channel.id">
                    <span class="channels-row__text" v-html="$options.filters.formatChannelName(channel.name)"></span>
                  </b-col>

                  <!-- Next -->
                  <b-col v-if="needChannelCarousel" cols="carousel__control">
                    <div class="carousel__control carousel__control--next" @click="onCarouselNextClick()" :class="{ invisible: isSliding }" data-v-step-schedule="1">
                      <i class="las la-6x la-t-plus-1 la-white la-angle-right"></i>
                    </div>
                  </b-col>
                </b-row>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
          <div v-if="showDuplicatedHeader" class="timeline-title">
      <div class="p-3 pl-4 align-middle">
        <!-- IsTimeLoadingTemplate -->
        <template v-if="isTimeLoading"> <i class="las la-3x la-t-plus-3 la-circle-notch la-spin mr-1"></i> Synchronization... </template>

        <!-- MainTemplate (time) -->
        <template v-if="!isTimeLoading">
          <!-- Time line header -->
          <template v-if="!expanded">
            <span class="date__text">
              {{ timeFromServer | formatUnixEpocDayPart }}
            </span>
          </template>
          <!-- Full calendar header -->
          <template v-else>
            <!-- desktop display -->
            <div v-if="$screen.lg || $screen.xl || $screen.xxl" class="d-none d-lg-block" order-lg="1">
              <span
                :key="eventDay.toString()"
                v-for="(eventDay, index) in eventDays"
                class="date__text"
                :class="{ selected: isSelectedDay(eventDay) }"
                :style="{ cursor: !isSliding && !isLoading && !isSelectedDay(eventDay) ? 'pointer' : null }"
                :data-v-step-schedule="index == 0 && ($screen.lg || $screen.xl || $screen.xxl) ? 0 : null"
              >
                <span @click="onDayClick(eventDay)">{{ eventDay | formatUnixEpocDayPart }}</span>
              </span>
              <a
                v-if="this.confCmsData && this.confCmsData.paag_doc && this.confCmsData.paag_doc.filename"
                :href="this.confCmsData.paag_doc.filename"
                target="_blank"
                class="btn btn-congress"
                >Download the Programme at a glance</a
              >
            </div>
            <!-- mobile display -->
            <div class="d-lg-none" order="1">
              <span class="date__control date__control--previous" @click="onPreviousDayClick()" :class="{ invisible: isSliding || isLoading || !allowPreviousDay }">
                <i class="las la-07x la-t-minus-2_5 la-white la-angle-left"></i>
              </span>
              <span class="date__text" :data-v-step-schedule="$screen.xs || $screen.sm || $screen.md ? 0 : null">
                {{ timeToDisplay | formatUnixEpocDayPart }}
              </span>
              <span class="date__control date__control--next" @click="onNextDayClick()" :class="{ invisible: isSliding || isLoading || !allowNextDay }">
                <i class="las la-07x la-t-minus-2_5 la-white la-angle-right"></i>
              </span>
            </div>
          </template>
        </template>
      </div>

      <div v-if="toggable" @click="toggleTimeLine" class="timeline-toggle-action">
        <i class="las la-2x la-t-plus-6 la-calendar-alt timeline-expand-action"></i>
        <i class="las la-07x la-t-plus-1 la-angle-right timeline-expand-action"></i>
        <i class="las la-2x la-t-plus-6 la-times timeline-collapse-action"></i>
      </div>
    </div>
    </div>
        <!-- Time line footer -->

  </section>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import { MediaApiService } from 'src/services/media-api';
  import { DateHelper } from 'src/utils/date-helper';
  import SessionButton from 'src/components/Buttons/SessionButton';
  import FavoriteButton from 'src/components/Buttons/FavoriteButton';
  import { constants } from 'src/constants';
  import hybridEventMixin from 'src/mixins/hybridEventMixin';

  export default {
    name: 'Timeline',
    mixins: [hybridEventMixin],
    components: {
      SessionButton,
      FavoriteButton
    },
    props: {
      // eventId: Number,
      channelId: Number,
      /*datetime: Date,*/
      entity: {
        type: String,
        required: true,
        validator: function(value) {
          // The value must match one of these strings
          return value !== null && ['Channel', 'Session'].indexOf(value) !== -1;
        }
      },
      expanded: Boolean,
      toggable: {
        default: true,
        type: Boolean
      }
    },
    data() {
      return {
        showDuplicatedHeader:false,
        isTimeLoading: true,
        isLoading: true,
        timeLine: [],
        timeLineFull: [],
        isTimelineFullFirstOpen: true,
        timeFromServer: null,
        timeToDisplay: null,
        fetchTimeLineJob: null,
        isSliding: false,
        allowNextDay: false,
        allowPreviousDay: false,
        dayDuration: 0,
        startDayTime: 0,
        endDayTime: 0,
        eventDays: [],
        hover: {}
      };
    },
    computed: {
      channelsPerSlide() {
        let responsivechannelsPerSlide; /* constants.CHANNELS_COUNT_BY_SLIDE_POC; */
        if (this.$screen.xxl) responsivechannelsPerSlide = 6;
        else if (this.$screen.xl) responsivechannelsPerSlide = 5;
        else if (this.$screen.lg) responsivechannelsPerSlide = 4;
        else if (this.$screen.md) responsivechannelsPerSlide = 3;
        else if (this.$screen.sm) responsivechannelsPerSlide = 2;
        else if (this.$screen.xs) responsivechannelsPerSlide = 1;
        return responsivechannelsPerSlide;
      },
      stepSizeFactor() {
        return constants.STEP_SIZE_FACTOR;
      },
      slidesCount() {
        if (!this.timeLineFull || this.timeLineFull.length == 0) return 0;

        return Math.ceil(this.timeLineFull.length / this.channelsPerSlide /* constants.CHANNELS_COUNT_BY_SLIDE_POC */);
      },
      // Timeline status data attribute
      expandedData() {
        return this.expanded ? 'expanded' : 'collapsed';
      },
      needChannelCarousel() {
        return this.timeLineFull.length > this.channelsPerSlide /* constants.CHANNELS_COUNT_BY_SLIDE_POC */;
      },
      poolingTimelineDigestInterval() {
        return parseInt(process.env.VUE_APP_POOLING_TIMELINE_DIGEST_INTERVAL);
      },
      ...mapGetters(['isPoolingEnabled', 'eventStartDate', 'eventEndDate']),
      ...mapState({
        eventRoute: state => state.app.eventRoute,
        eventStartDate: state => state.app.eventStartDate,
        eventEndDate: state => state.app.eventEndDate,
        confCmsData: state => state.app.confCmsData
      })
    },
    watch: {
      isPoolingEnabled(enabled) {
        if (enabled) {
          // Fetching timeline to display...
          this.fetchtime();
          // Fetching timeline digest...
          this.fetchtimeLine();

          // Fetching timeline full...
          if (this.expanded) this.fetchtimeLineFull();
        }
      },
      /*
      $route() {
      //if(this.expanded && this.toggable) this.toggleTimeLine();
      },
      */
      expanded(isExpanded) {
        //this.$router.push({ query: { qz: 1 } });
        //this.$router.push({ query: { /*isTimelineExpanded: this.isExpanded,*/ day: this.timeToDisplay } });
        if (isExpanded) {
          if (this.isTimelineFullFirstOpen) {
            this.isTimelineFullFirstOpen = false;
            this.isLoading = true;
          }
          this.fetchtimeLineFull();
        }
      }
    },
    async mounted() {
      //Retrieve server time
      if(this.$route.path.includes(constants.ROUTE_SCHEDULE)){
        this.showDuplicatedHeader = true
      }else{
        this.showDuplicatedHeader = false
      }
      await this.fetchtime();
      const qDay = this.$route.query.day;
      if(DateHelper.isValidDate(qDay)) this.timeToDisplay = Number(qDay)
      if (!this.timeToDisplay) this.timeToDisplay = this.timeFromServer;

      this.refreshDaysList();
      this.refreshDaySelectors();

      // Fetching timeline digest...
      this.fetchtimeLine();

      if (this.expanded) this.fetchtimeLineFull(); // Fetching timeline full...

      // Polling sessions time line...
      this.fetchTimeLineJob = setInterval(() => {
        if (this.isPoolingEnabled) {
          // Fetching timeline to display...
          this.fetchtime();
          // Fetching timeline content...
          this.fetchtimeLine();
          // Fetching timeline full...
          if (this.expanded) this.fetchtimeLineFull();
        }
      }, this.poolingTimelineDigestInterval);
    },
    beforeDestroy() {
      clearInterval(this.fetchTimeLineJob);
    },
    methods: {
      isSelectedDay(day) {
        //console.log('isSelectedDay ' + day + ' ' + this.timeToDisplay + ' ' + DateHelper.browserDateTime(this.timeToDisplay))
        return (
          this.$options.filters.formatUnixEpocDayPart(day) === this.$options.filters.formatUnixEpocDayPart(this.timeToDisplay)
          //day.getMonth() + day.getDate() == DateHelper.browserDateTime(this.timeToDisplay).getMonth() + DateHelper.browserDateTime(this.timeToDisplay).getDate()
        );
      },
      showSharpTimeLine(index) {
        return (DateHelper.isSharpHour(this.startDayTime) && index % 2 == 1) || (!DateHelper.isSharpHour(this.startDayTime) && index % 2 == 0);
      },
      isSponsoredSession(session) {
        //console.log(`session?.isIndustry ${session?.isIndustry}`)
        //return false;
        //return session && session?.subTitle && session?.subTitle?.toLowerCase()?.includes('sponsored by');
        //return session && session?.subTitle && (session?.subTitle?.toLowerCase()?.includes('sponsored') || session?.subTitle?.toLowerCase()?.includes('supported'));
        return session && session?.isIndustry;
      },
      getSessionBgCssClass(session) {
        let bgCssClass = 'default-session';
        if (session && session?.isIndustry) bgCssClass = 'sponsored-session';

        return bgCssClass;
      },
      getUniqueColor(n) {
        //console.log('getUniqueColor '+n);
        const rgb = [0, 0, 0];

        for (let i = 0; i < 24; i++) {
          rgb[i % 3] <<= 1;
          rgb[i % 3] |= n & 0x01;
          n >>= 1;
        }

        return '#' + rgb.reduce((a, c) => (c > 0x0f ? c.toString(16) : '0' + c.toString(16)) + a, '');
      },
      fetchtimeLine() {
        MediaApiService.getTimeLineDigest(this.eventId, this.entity, this.channelId, DateHelper.browserDateTime(this.timeFromServer)).then(({ data }) => {
          this.isLoading = false;
          if (data) this.timeLine = data;
        });
      },
      async fetchtime() {
        const { data } = await MediaApiService.getTimelineTimeToDisplay(this.eventId);
        this.timeFromServer = data;
        this.isTimeLoading = false;
        //console.log('[Timeline] Time to display (local): ' + DateHelper.browserDateTime(this.timeFromServer));
        //console.log('[Timeline] Time to display: (UTC)' + DateHelper.browserDateTime(this.timeFromServer).toUTCString());
      },
      fetchtimeLineFull() {
        MediaApiService.getTimeLineFull(this.eventId, DateHelper.browserDateTime(this.timeToDisplay)).then(({ data }) => {
          this.isLoading = false;
          if (data) {
            this.timeLineFull = data;
            //console.log('items '+this.timeLineFull?.items);
            //let sessions = [].concat.apply([], this.timeLineFull?.items);
            let firstDaySessions = this.timeLineFull.reduce((flat, next) => flat.concat(next.items[0]), []);
            let lastDaysessions = this.timeLineFull.reduce((flat, next) => flat.concat(next.items[next.items.length - 1]), []);
            //console.log('sessions '+JSON.stringify(sessions));
            this.startDayTime = firstDaySessions.reduce((a, b) => (a.startTime < b.startTime ? a : b)).startTime;
            this.endDayTime = lastDaysessions.reduce((a, b) => (a.endTime > b.endTime ? a : b)).endTime;

            this.startDayTime = DateHelper.ceilToHalfHour(this.startDayTime);
            this.endDayTime = DateHelper.floorToHalfHour(this.endDayTime);

            //console.log('startDayTime ' + DateHelper.browserDateTime(this.startDayTime));
            //console.log('endDayTime ' + DateHelper.browserDateTime(this.endDayTime));
            this.dayDuration = this.endDayTime - this.startDayTime;
            //console.log('dayDuration ' + this.dayDuration / (1000 * 60) + ' min');
            //console.log('dayDuration ' + this.dayDuration / (1000 * 60 * 60) + ' h');
          }
        });
      },
      // Toggle timeLine status flag & send timeline-toggle event
      toggleTimeLine: function() {
        this.showDuplicatedHeader = !this.showDuplicatedHeader;
        this.$emit('timeline-toggle');
      },
      // Carousel
      getChannelsBySlide(slideNumber) {
        const slideIndex = slideNumber - 1;

        const sliceFrom = slideIndex * this.channelsPerSlide; /*constants.CHANNELS_COUNT_BY_SLIDE_POC*/
        let sliceTo = sliceFrom + this.channelsPerSlide; /*constants.CHANNELS_COUNT_BY_SLIDE_POC*/
        if (sliceTo > this.timeLineFull.length) sliceTo = this.timeLineFull.length;

        return this.timeLineFull.slice(sliceFrom, sliceTo);
      },
      onCarouselPrevClick() {
        this.$refs.timelineCarousel.prev();
      },
      onCarouselNextClick() {
        this.$refs.timelineCarousel.next();
      },
      // Date selector
      moveToDay(step) {
        this.isLoading = true;
        this.timeToDisplay = DateHelper.addDaysToTime(this.timeToDisplay, step);
        if(this.$route.path.includes(constants.ROUTE_SCHEDULE) && this.$route.query.day != this.timeToDisplay) 
        {
          this.$router.push({ query: { day: this.timeToDisplay  } });
        }
        this.refreshDaySelectors();
        this.fetchtimeLineFull();
      },
      refreshDaySelectors() {
        const compareWithFirstDay = DateHelper.compareTimeDatePart(this.timeToDisplay, this.eventStartDate);
        const compareWithLastDay = DateHelper.compareTimeDatePart(this.timeToDisplay, this.eventEndDate);
        this.allowPreviousDay = compareWithFirstDay > 0;
        this.allowNextDay = compareWithLastDay < 0;
      },
      refreshDaysList() {
        const startDate = DateHelper.browserDateTime(this.eventStartDate);
        const endDate = DateHelper.browserDateTime(this.eventEndDate);
        this.eventDays = DateHelper.getDates(startDate, endDate);
        /*
        //console.log('[C] eventStartDate '+this.$options.filters.formatUnixEpocDayPart(this.eventStartDate))
        //console.log('[C] startDate '+this.$options.filters.formatUnixEpocDayPart(startDate))
        //console.log('[C] eventDays[FIRST] '+this.$options.filters.formatUnixEpocDayPart(this.eventDays[0]))
        //console.log('[C] eventEndDate '+this.$options.filters.formatUnixEpocDayPart(this.eventEndDate))
        //console.log('[C] endDate '+this.$options.filters.formatUnixEpocDayPart(endDate))
        //console.log('[C] eventDays[LAST] '+this.$options.filters.formatUnixEpocDayPart(this.eventDays[this.eventDays.length - 1]))
        */
      },
      moveToDayByDay(day) {
        this.isLoading = true;
        this.timeToDisplay = day.getTime();
        if(this.$route.path.includes(constants.ROUTE_SCHEDULE) && this.$route.query.day != this.timeToDisplay) 
        {
          this.$router.push({ query: { day: this.timeToDisplay  } });
        }
        this.fetchtimeLineFull();
      },
      onSlideStart() {
        this.isSliding = true;
      },
      onSlideEnd() {
        this.isSliding = false;
      },
      onPreviousDayClick() {
        this.moveToDay(-1);
      },
      onNextDayClick() {
        this.moveToDay(1);
      },
      onDayClick(date) {
        if (!this.isSliding && !this.isLoading && !this.isSelectedDay(date)) this.moveToDayByDay(date);
      },
      onSessionItemClick(item) {
        /*
      if (this.toggable && this.expanded) {
        this.$emit('timeline-toggle');
      }
      */
        //console.log('🚀 ~ file: Schedule.vue ~ line 584 ~ onSessionItemClick ~ eventRoute', this.eventRoute);
        //console.log('🚀 ~ file: Schedule.vue ~ line 585 ~ onSessionItemClick ~  item.furl', item.furl);

        this.$router.push({ path: `/${this.eventRoute}`, name: 'Session details', params: { sessionFurl: item.furl } });
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .timeline {
    font-size: 0.9em;
    height: 100%;

    /* timeline toggable mode */
    &[data-toggable] {
      .timeline-title {
        /*cursor: pointer;*/ /*full view disabled*/
        border-radius: 0px 0px 5px 0px;
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: $grey-light;
        background-color: $secondary;

        /* timeline title date */
        div:nth-child(1) {
          width: calc(100% - 60px);
        }

        /* timeline title button (calendar or close) */
        div:nth-child(2) {
          display: inline-block;
          text-align: center;
          margin: auto;
          font-size: 2em;
          width: 60px;
          color: var(--congress);
        }
      }

      .timeline-toggle-action {
        cursor: pointer;
      }
    }

    /* timeline not toggable mode */
    &:not([data-toggable]) {
      .timeline-title {
        border-radius: none;
        /* timeline title date */
        div:nth-child(1) {
          width: 100%;
        }
        /* timeline title button (calendar or close) */
        div:nth-child(2) {
          display: hidden;
        }
      }
    }
  }

  /* timeline title */
  .timeline-title {
    /* timeline title date */
    div:nth-child(1) {
      color: $secondary;
      background-color: var(--congress);
      text-transform: capitalize;
      font-size: 1.5em;
      display: inline-block;
    }
  }

  /* timeline vertical line */
  .timeline-line {
    position: absolute;
    width: 1px;
    height: 80%;
    left: 24px;
    background: var(--congress);
  }

  /* timeline vertical line */
  .channel-timeline-line {
    width: 1px;
    height: 80%;
    margin-left: 19px;
    background: var(--congress);
  }

  /* timeline items content */
  .timeline-items {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    padding-left: 10px;
    height: 100%;
    min-height: 110vh;
    background-color: $secondary;
  }

  /* timeline last item stop vertical line and disable bottom separator */
  .timeline-items .timeline-item {
    &:last-child {
      .timeline-text {
        border-bottom: none;
      }
      .timeline-line {
        height: 100%;
        background: linear-gradient(to bottom, var(--congress) 0%, $secondary 100%);
      }
    }
  }

  /* timeline item live now badge */
  .col-timeline-now {
    flex: 0 0 42px;
  }

  .timeline-now {
    position: relative;
    top: 3px;
    left: -4px;
    color: $secondary;
    background-color: var(--congress);
    font-size: 0.6em;
    text-transform: uppercase;
    text-align: center;
    padding: 2px 8px;
    border-radius: 8px;
  }

  /* timeline item bottom separator */
  .timeline-text {
    border-bottom: 1px solid $grey-lighter;
  }

  /* timeline item time */
  .col-timeline-time {
    position: relative;
    flex: 0 0 50px;
  }

  .timeline-time {
    text-align: center;
    margin: auto;
  }

  /* timeline item title */
  .timeline-text__title,
  .session__title {
    padding-right: 5px;
    overflow-wrap: anywhere;
    font-size: 1.2em;
  }

  /* timeline item subtitle */
  .timeline-text__subtitle {
    padding: 5px 0px;
    font-size: 1em;
    text-transform: uppercase;
    color: lighten($grey, 10%);
  }

  /* timeline item description */
  .timeline-text__description,
  .session__sub_title {
    padding: 5px 0px;
    font-size: 1em;
  }

  /* full view background color force for overlay */
  .time-line-full {
    background-color: $secondary;
  }

  /* timeline collapsed */
  .timeline[data-expanded='collapsed'] {
    /* button calendar icon visible */
    .timeline-expand-action {
      display: inline;
    }
    /* button close icon hidden */
    .timeline-collapse-action {
      display: none;
    }
    /* full view hidden on both desktop and mobile */
    .time-line-full {
      display: none;
    }
    /* digest view visible on both desktop and mobile */
    .time-line-digest {
      display: block;
    }
    .date__text {
      opacity: 1 !important;
    }
  }

  /* timeline expanded */
  .timeline[data-expanded='expanded'] {
    .timeline-items {
      padding-top: 0 !important;
      padding-left: 0 !important;
    }

    /* desktop timeline expanded*/
    @include media-breakpoint-up(sm) {
      /* full view visible */
      .time-line-full {
        display: block; /*full view disabled*/
      }
      /* digest view hidden */
      .time-line-digest {
        display: none; /*full view disabled*/
      }
      /* button calendar icon hidden */
      .timeline-expand-action {
        display: none; /*full view disabled*/
      }
      /* button close icon visible */
      .la-times {
        display: block; /*full view disabled*/
      }
    }

    /* mobile timeline expanded*/
    @include media-breakpoint-down(sm) {
      /* timeline toggable mode */
      &[data-toggable] {
        /* full view hidden */
        .time-line-full {
          display: none;
        }
        /* digest view visible */
        .time-line-digest {
          padding: 0.6rem 0 0rem 0.6rem;
          display: block;
        }
        /* button calendar icon hidden */
        .timeline-expand-action {
          display: none;
        }
        /* button close icon visible */
        .timeline-collapse-action {
          display: block;
          cursor: pointer;
        }

        .date__control {
          display: none;
        }
      }

      /* timeline not toggable mode */
      &:not([data-toggable]) {
        /* full view visible */
        .time-line-full {
          display: block; /*full view disabled*/
        }
        /* digest view hidden */
        .time-line-digest {
          display: none; /*full view disabled*/
        }
      }
    }
  }

  /* full view design */
  /*full view disabled*/

  .channels-row {
    text-align: center;
    box-shadow: 4.5px 2.3px 8.7px 0.3px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 0;
    align-items: center;

    div:nth-child(2) .channels-row__text {
      padding-right: 23px;
    }

    div:nth-last-child(2) .channels-row__text {
      padding-left: 23px;
    }
  }

  .channels-row__text {
    text-transform: uppercase;
    font-size: 0.96rem;
    display: block;
  }

  .sessions-row {
    font-size: 0.8rem;
    /*padding: 1.2rem 0;*/
    /*min-height: 75vh;*/
    /*
    min-height:1200px;
    height:1200px;
    max-height:1200px;
    */
    .channels-divider {
      border-right: 1px solid $grey-lighter;
      max-width: 970px;
      padding: 0;
      /*flex-basis: auto;*/ /*ie fix center content */
    }

    div:nth-last-child(2) {
      border: none;
    }
  }

  /* Test for load more scroll */
  /*
  .sessions-row-container {

    max-height: 90vh;
    overflow: scroll;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  */

  .session-item-card {
    cursor: pointer;
    background-color: var(--congress-lighter) !important;
    &:hover:not(a),
    :focus:not(a) {
      background-color: var(--congress-light) !important;
    }
    ::v-deep .card-link {
      &:focus,
      &:hover {
        color: var(--congress-lighter) !important;
      }
    }
  }

  /* short sessions expand on hover */
  .session-item:hover,
  .session-item:focus {
    z-index: 4 !important;
    display: grid;
    height: auto !important;
  }

  /* sponsored sessions reveal on hover */
  .session-item .sponsored-session {
    background-color: transparent;
  }

  .session-item .sponsored-session .sponsored-back {
    /*position: absolute;*/
    display: none;
    opacity: 0;
    /* animation */
    /*
    transform: scale(0);
    transition: all 1s ease-in-out;
    */
  }

  .session-item:hover .sponsored-session .sponsored-back,
  .session-item:focus .sponsored-session .sponsored-back {
    display: block;
    opacity: 1;
    /* animation */
    /*
    transform: scale(1);
    transition: all 0.25s ease-in-out;
    */
  }

  .session-item .sponsored-session .sponsored-front {
    /*position: absolute;*/
    display: block;
    opacity: 1;
    /* animation */
    /*
    transform: scale(1);
    transition: all 1s ease-in-out;
    */
  }
  .session-item:hover .sponsored-session .sponsored-front,
  .session-item:focus .sponsored-session .sponsored-front {
    display: none;
    opacity: 0;
    /* animation */
    /*
  transform: scale(0);
  transition: all 0.25s ease-in-out;
  */
  }

  .session__favorite__button {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .session__time {
    font-size: 1.2em;
  }

  .session__duration {
    font-size: 1em;
    color: $grey;
  }

  .session__now {
    flex: 0 0 42px;
    /*float: right;*/
    color: $secondary;
    background-color: var(--congress);
    font-size: 0.6em;
    text-transform: uppercase;
    text-align: center;
    padding: 2px 8px;
    border-radius: 8px;
    /*margin-right: 25px;*/
    position: absolute;
    right: 30px;
    top: 9px;
  }

  [class^='session__location--'] {
    font-size: 1em;
  }

  [class^='session__location--'] span {
  }

  /* Carousel */
  .carousel {
    width: 100%;
  }

  .col-carousel__timeline {
    flex: 0 0 50px;
    text-align: right;
  }

  .col-carousel__control {
    flex: 0 0 36px;

    & .carousel__control--previous {
      margin-left: 12px;
    }

    & .carousel__control--next {
      margin-right: 12px;
    }
  }

  .carousel__control {
    background-color: $grey;
    padding: 6px 9px;
    border-radius: 6px;
    cursor: pointer;
  }

  .date__control {
    background-color: var(--congress-dark);
    padding: 0px 4px;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    top: 2px;
  }

  .date__text {
    padding: 0px 10px;
  }

  /* Skeletons */
  .b-skeleton {
    background-color: rgb(224, 224, 224) !important;
  }

  .stripe {
    background: repeating-linear-gradient(-55deg, #eee, #eee 1px, #fff 0, #fff 5px);
    /*repeating-linear-gradient(-55deg, #eeeeee, #eeeeee 5px, #fff 5px, #fff 10px);*/
  }

  .default-session {
    background-color: #f8f9fa;
  }

  .sponsored-session {
    /*background-color: #e6b0aa;*/
    background-color: #f8f9fa;
  }

  /* date list */
  @media (min-width: 992px) {
    .date__text {
      opacity: 0.6;
    }
  }

  .date__text:hover {
    opacity: 1;
  }

  .date__text a {
    color: #fff;
  }

  .date__text a:hover {
    text-decoration: none;
  }

  .date__text.selected {
    opacity: 1;
    position: relative;
  }

  .date__text.selected:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    left: calc(50% - 15px);
    top: -20px;
    transform: translate(50%, -50%) rotate(45deg);
    background: #fff;
  }

  .btn.btn-congress {
    background-color: #000;
    float: right;
  }

  .timeline-title div.d-lg-block {
    font-size: 1em;
  }

  /*ONBOARDING TOUR*/
  .v-tour__target--highlighted {
    border-radius: 0.25rem;
  }
</style>
