<template>
  <b-container v-if="hasBoostedTerms || hasTrending || hasPopularity" fluid class="mb-5 px-5 recommendationBlock">
    <!-- recommended for you -->
    <h3 v-if="hasBoostedTerms" class="mt-5 mb-3"><span class="material-icons-outlined mr-2">workspace_premium</span>Recommended for you</h3>

    <b-card-group v-if="hasBoostedTerms" deck class="mb-5">
      <SessionCard
        :key="session.id"
        v-for="session in recommendationData.BoostedTerms.Sessions"
        :currentSession="session"
        :isLive="session.isLive"
        :mode="mode"
        :hideWatchButton="true"
        :isLiveSession="false"
        class="p-0 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-12"
      >
      </SessionCard>
    </b-card-group>
    <!-- suggested content -->
    <div v-if="hasTrending || hasPopularity">
      <h3 class="my-3"><span class="material-icons-outlined mr-2">workspace_premium</span>Suggested for you</h3>
      <!-- most popular -->
      <b-card-group v-if="hasPopularity" deck class="mb-5">
        <SessionCard
          :key="session.id"
          v-for="session in recommendationData.Popularity.Sessions"
          :currentSession="session"
          :isLive="session.isLive"
          :mode="mode"
          :hideWatchButton="true"
          :isLiveSession="false"
        class="p-0 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-12"
        >
        </SessionCard>
      </b-card-group>
      <!-- trending now -->
      <b-card-group v-else-if="hasTrending" deck class="mb-5">
        <SessionCard
          :key="session.id"
          v-for="session in recommendationData.Trending.Sessions"
          :currentSession="session"
          :isLive="session.isLive"
          :mode="mode"
          :hideWatchButton="true"
          :isLiveSession="false"
        class="p-0 col-sm-6 col-md-6 col-lg-4 col-xl-3 col-12"
        >
        </SessionCard>
      </b-card-group>
    </div>
  </b-container>
</template>

<script>
  import * as Cookies from 'js-cookie';
  import { mapState } from 'vuex';
  import { constants } from 'src/constants';
  import { MediaApiService } from 'src/services/media-api';
  import SessionCard from 'src/components/Cards/SessionCard';

  export default {
    name: 'RecommendationBlock',
    components: {
      SessionCard,
    },
    data() {
      return {
        recommendationData: null,
        hasTrending: false,
        hasBoostedTerms: false,
        hasPopularity: false,
        mode:'Coming',
      };
    },
    computed: {
      ...mapState({
        eventRoute: (state) => state.app.eventRoute,
        eventId: (state) => state.app.eventId,
      }),
    },
    async mounted() {
      await this.fetchRecommendations();
    },
    methods: {
      hasUserRecos() {
        return this.recommendationData.BoostedTerms && this.recommendationData.BoostedTerms.Sessions && this.recommendationData.BoostedTerms.Sessions.length > 0;
      },
      hasTrendingRecos() {
        return this.recommendationData.Trending && this.recommendationData.Trending.Sessions && this.recommendationData.Trending.Sessions.length > 0;
      },
      hasPopularRecos() {
        return this.recommendationData.Popularity && this.recommendationData.Popularity.Sessions && this.recommendationData.Popularity.Sessions.length > 0;
      },

      async fetchRecommendations() {
        this.isLoading = true;
        const guestUserId = Cookies.get('_dvp');
        let fetchRecommendationRequest = constants.ESC365_API_GET_USER_RECOMMENDATIONS;

        if (this.selectedRecommendationType && this.selectedRecommendationType.length > 0)
          fetchRecommendationRequest = fetchRecommendationRequest.concat(`&recommendertype=${this.selectedRecommendationType}`);

        if (this.selectedContentType && this.selectedContentType.length > 0)
          fetchRecommendationRequest = fetchRecommendationRequest.concat(`&contenttype=${this.selectedContentType}`);
        fetchRecommendationRequest = fetchRecommendationRequest.concat(`&partiId=${guestUserId}`);

        fetchRecommendationRequest = fetchRecommendationRequest.concat(`&eventId=${this.eventId}`);
        fetchRecommendationRequest = fetchRecommendationRequest.concat(`&nbItems=${4}`);

        const { data: fetchRecommendationData } = await MediaApiService.getRecommendations(fetchRecommendationRequest);
        if (!this.selectedRecommendationType && fetchRecommendationData.RecommenderTypesOptions)
          this.selectedRecommendationType = fetchRecommendationData.RecommenderTypesOptions[0].value;
        if (!this.selectedContentType && fetchRecommendationData.Contenttypes) this.selectedContentType = fetchRecommendationData.Contenttypes[0];

        this.recommendationData = fetchRecommendationData;
        this.hasTrending = this.hasTrendingRecos();
        this.hasBoostedTerms = this.hasUserRecos();
        this.hasPopularity = this.hasPopularRecos();

        if (this.hasTrending) {
          const recoId = this.recommendationData.Trending ? this.recommendationData.Trending.Sessions.map((r) => r.recoUUID) : [];
          //console.log('GTM push ',`TrendingSession eventId:${this.eventId} uuid:${recoId}`)
          this.$gtm.push({ event: 'TrendingSession', eventId: this.eventId, uuid: recoId });
        }
        if (this.hasBoostedTerms) {
          const recoId = this.recommendationData.BoostedTerms ? this.recommendationData.BoostedTerms.Sessions.map((r) => r.recoUUID) : [];
          //console.log('GTM push ',`BoostedTermsSession eventId:${this.eventId} uuid:${recoId}`)
          this.$gtm.push({ event: 'BoostedTermsSession', eventId: this.eventId, uuid: recoId });
        }
        if (this.hasPopularity) {
          const recoId = this.recommendationData.Popularity ? this.recommendationData.Popularity.Sessions.map((r) => r.recoUUID) : [];
          //console.log('GTM push ',`PopularitySession eventId:${this.eventId} uuid:${recoId}`)
          this.$gtm.push({ event: 'PopularitySession', eventId: this.eventId, uuid: recoId });
        }
      },
      filterRecos(recos) {
        return recos.filter((obj, pos, arr) => {
          return obj['content'] !== null && obj['content'] !== '' && arr.map((mapObj) => mapObj['content']).indexOf(obj['content']) === pos;
        });
      },
      sanitizeId(rawId) {
        if (!rawId) return '#';
        return rawId.substring(1, rawId.length);
      },
      extractId(rawcontent) {
        if (!rawcontent) return '#';
        return parseFloat(rawcontent);
      },
      extractTitle(rawcontent) {
        if (!rawcontent) return '#';
        return rawcontent.replace(parseFloat(rawcontent), '');
      },
      getuuid(uuid) {
        let uid = '';
        if (uuid.length > 0) {
          uid = uuid[uuid.length - 1];
        } else {
          uid = uuid[0];
        }
        return uid;
      },
      getuuidTrending(uuid) {
        let uid = '';
        if (uuid.length > 0) {
          uid = uuid[1];
        } else {
          uid = uuid[0];
        }
        return uid;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .recommendationBlock {
    background-color: #e9ecef;
  }

  h3 {
    font-size: 1.4rem;
  }
</style>
