<template>
  <div>
    <b-container fluid>
      <b-row class="content-wrapper vh-100 text-center" align-v="center">
        <b-col>
          <b-jumbotron header="Service status." lead="All services are operationnals.">
            <div class="py-3">
              <b-card-text>
                <b-link variant="primary" to="/">
                  Back to home page
                </b-link>
              </b-card-text>
            </div></b-jumbotron
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { SET_IS_LOADING } from 'src/store/mutations';
  export default {
    name: 'HealthWidget',
    mounted() {
      this.$store.commit(SET_IS_LOADING, false);
    }
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  $media-modal-bg-secondary: #656565;

  .content-wrapper {
    background: $media-modal-bg-secondary;
    background-blend-mode: multiply, multiply;
  }

  .display-3 {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }
</style>
