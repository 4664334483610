<template>
  <div>
    <b-jumbotron :header="this.headerText" :lead="this.message">
      <b-button pill variant="congress" @click="login" class="with-icon">
        <i class="las la-5x la-t-plus-3 la-user-circle pr-1"></i>
              {{buttonText}}
      </b-button>
    </b-jumbotron>
  </div>
</template>
<script>
import { login } from 'src/services/auth';

export default {
  name: 'LogginWidget',
  props: {
    headerText: String,
    message: String,
    forceCheckAccess: {
      type: Boolean,
      default: false,
    },
    buttonText:{
      type: String,
      default: 'Sign in',
    },
  },
  data() {
    return {};
  },
  computed: {
    returnUrl() {
      return window.location.origin + this.$route.fullPath;
    },
  },
  methods: {
    login() {
      login(this.returnUrl, this.forceCheckAccess);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/theme';
@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

@include media-breakpoint-up(sm) {
  .jumbotron {
    padding: 1.5rem 4rem;
  }
}

.display-3 {
  font-size: 1.6rem;
}

.lead {
  font-size: 1rem;
}
</style>