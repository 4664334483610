<template>
  <b-container fluid>
    <div class="btn__back mb-2 position-sticky float-lg-left">
      <b-button pill variant="clear" size="sm"
        class="font-regular mt-2 d-inline d-inline-flex align-items-center border-0" @click="onBackClick">
        <span class="material-icons-round mr-2"> arrow_back </span>
        Back
      </b-button>
    </div>
    <b-col cols="12" sm="12" md="8" lg="9" class="toggable-main-content m-auto"
      :class="isTimelineExpanded ? 'd-none' : 'd-block'">
      <b-row>
        <!-- ESC Banner(s) -->
        <b-col cols="10" md="12" offset="2" offset-md="0">
          <EscBanners />
        </b-col>
        <!-- SpeakerDetails -->
        <b-col cols="12" class="my-4">
          <div class="speaker__details__wrapper">
            <div class="session__details__header">
              <div class="d-flex">
                <div class="mr-3">
                  <b-img class="search__card__header__picture border" :src="photoUrl ? photoUrl : defaultPicture" fluid
                    alt="Speaker's Picture"></b-img>
                </div>
                <div class="w-100">
                  <h3 class="speaker__details__content__title mb-0">
                    <!-- Name -->
                    <template v-if="isMoreDetailsLoading">
                      <b-skeleton height="30px" width="85%"></b-skeleton>
                    </template>

                    <template v-if="!isMoreDetailsLoading"> {{ speakerDetails.firstName }} {{ speakerDetails.lastName }}
                    </template>
                  </h3>

                  <!-- Institutions -->
                  <div class="speaker_info mb-2" v-if="isMoreDetailsLoading || speakerDetails.address">
                    <template v-if="!isMoreDetailsLoading">
                      {{ fullAddressFormatted(speakerDetails.address) }}
                    </template>
                  </div>
                  <!-- Twitter -->
                  <!-- <template v-if="!isMoreDetailsLoading && speakerDetails.twitterId">
              <a :href="speakerDetails.twitterUrl" target="_blank">
                <span class="follow-speaker"><i class="la la-twitter"></i></span> <span>{{ speakerDetails.twitterId }}</span>
              </a>
            </template> -->
                  <!-- ESC Profile + Twitter -->
                  <template>
                    <b-list-group horizontal="md" class="speakerDetailsLinks">
                      <b-list-group-item v-if="speakerDetails && esc365ProfileUrl">
                        <small>
                          <a :href="esc365ProfileUrl" class="follow-speaker" target="_blank">
                            <span class="follow-speaker-365"><img src="~@/assets/images/platform/logo-esc-icon.svg"
                                alt="ESC Logo" /></span>
                            <span>ESC 365 Profile</span>
                          </a>
                        </small>
                      </b-list-group-item>
                      <b-list-group-item v-if="!isMoreDetailsLoading && speakerDetails.twitterId">
                        <small>
                          <a :href="speakerDetails.twitterUrl" class="follow-speaker" target="_blank">
                            <span class="follow-speaker-twitter"><img src="~@/assets/images/platform/logo-x.svg"
                                alt="X Logo" /></span>
                            <span>{{ speakerDetails.twitterId }}</span>
                          </a>
                        </small>
                      </b-list-group-item>
                    </b-list-group>
                  </template>
                </div>
              </div>
            </div>
            <div class="speaker__details__content mt-5">
              <!-- Specialities -->
              <h5 class="speaker__details__content__title">
                <template v-if="isMoreDetailsLoading">
                  <b-skeleton height="30px" width="85%"></b-skeleton>
                </template>

                <template v-if="!isMoreDetailsLoading && speakerDetails.specialities"> Specialities </template>
              </h5>
              <div class="speaker_info">
                <template v-if="!isMoreDetailsLoading && speakerDetails.specialities">
                  {{ speakerDetails.specialities }}
                </template>
              </div>

              <!-- Presentations -->
              <template v-if="isMoreDetailsLoading">
                <b-skeleton width="15%" class="mb-2"></b-skeleton>
                <b-skeleton-img no-aspect height="80px" width="100%" class="mb-5"></b-skeleton-img>
                <b-skeleton-img no-aspect height="80px" width="100%" class="mb-1"></b-skeleton-img>
                <b-skeleton-img no-aspect height="80px" width="100%"></b-skeleton-img>
              </template>

              <template
                v-if="!isMoreDetailsLoading && speakerDetails.presentations && speakerDetails.presentations.length > 0">
                <h5 class="mt-5 mb-3">Presentations</h5>

                <div v-for="(presentation, index) in speakerDetails.presentations" :key="index"
                  class="speaker__details__presentation px-4 py-3">
                  <div class="presentation_date" v-if="presentation.presentationStartTime">
                    {{ presentation.presentationStartTime | formatUnixEpocDayPart }} • {{
                      presentation.presentationStartTime | formatUnixEpocTimePart }}
                  </div>
                  <span v-if="presentation.presentationTitle && presentation.presentationTitle.trim() !== ''"
                    class="speaker__details__presentation__title">{{
                      presentation.presentationTitle
                    }}</span>

                  <div v-if="presentation.sessionTitle && presentation.sessionTitle.trim() !== ''" class="mb-3">
                  <span variant="link" class="d-inline-block align-items-center p-0 font-light text-left">
                    Session: <router-link class="btn-link font-light" :to="`/${eventRoute}/sessions/${presentation.digitalSessionId}`"> {{ presentation.sessionTitle }} </router-link>
                    </span>
                  </div>
                  <div>
                    <div v-if="presentation.sessionType && presentation.sessionType.trim() !== ''"
                      class="d-inline-flex align-items-center font-regular">
                      <span class="material-symbols-rounded mr-2"> co_present </span> {{ presentation.sessionType }}
                    </div>
                  </div>
                  <div>
                    <div v-if="presentation.presentationTopic && presentation.presentationTopic.trim() !== ''"
                      class="d-inline-flex align-items-center font-regular">
                      <span class="material-symbols-rounded mr-2"> ecg_heart </span>Topic: {{
                        presentation.presentationTopic }}
                    </div>
                  </div>
                  <div v-if="presentation.presentationRoles && presentation.presentationRoles.trim() !== ''">
                    <i class="las la-6x la-t-plus-2 la-user-tag mr-1"></i><span class="flags__label">Role:</span> {{
                      roleFormatted(presentation.presentationRoles) }}
                  </div>
                    <div v-if="presentation.presentationAbstract && ressourcesMovedTo365" class="presentation_abstract">
                      <b-button @click="onAbstractView(presentation)" class="presentation_abstract_text">
                        Read the abstract >
                      </b-button>
                  </div>
                </div>
              </template>

              <!-- Sessions -->
              <template v-if="isMoreDetailsLoading">
                <b-skeleton width="15%" class="mb-2"></b-skeleton>
                <b-skeleton-img no-aspect height="80px" width="100%" class="mb-5"></b-skeleton-img>
                <b-skeleton-img no-aspect height="80px" width="100%" class="mb-1"></b-skeleton-img>
                <b-skeleton-img no-aspect height="80px" width="100%"></b-skeleton-img>
              </template>

              <template v-if="!isMoreDetailsLoading && speakerDetails.sessions && speakerDetails.sessions.length > 0">
                <h5 class="mt-5">Sessions</h5>

                <div v-for="(session, index) in speakerDetails.sessions" :key="index"
                  class="speaker__details__presentation px-4 py-3">
                  <div class="presentation_date" v-if="session.sessionStartTime">
                    {{ session.sessionStartTime | formatUnixEpocDayPart }} • {{ session.sessionStartTime |
                      formatUnixEpocTimePart }}
                  </div>
                  <router-link class="btn-link font-light" :to="`/${eventRoute}/sessions/${session.sessionId}`">
                  <span v-if="session.sessionTitle && session.sessionTitle.trim() !== ''"
                    class="speaker__details__presentation__title mb-3">{{
                      session.sessionTitle
                    }}</span>
                    </router-link>
                  <div>
                    <div v-if="session.sessionType && session.sessionType.trim() !== ''"
                      class="d-inline-flex align-items-center font-regular">
                      <span class="material-symbols-rounded mr-2"> co_present </span> {{ session.sessionType }}
                    </div>
                  </div>
                  <div>
                    <div v-if="session.sessionTopic && session.sessionTopic.trim() !== ''"
                      class="d-inline-flex align-items-center font-regular">
                      <span class="material-symbols-rounded mr-2"> ecg_heart </span> {{ session.sessionTopic }}
                    </div>
                  </div>
                  <!--
                <div v-if="session.sessionStartTime">
                  <i class="las la-6x la-t-plus-2 la-clock mr-1"></i>
                  <span class="flags__label">Time: </span>
                  {{ session.sessionStartTime | formatUnixEpocTimePart }}
                </div>
                <div v-if="session.sessionRoles && session.sessionRoles.trim() !== ''">
                  <i class="las la-6x la-t-plus-2 la-user-tag mr-1"></i><span class="flags__label">Role:</span> {{ roleFormatted(session.sessionRoles) }}
                </div>
                -->
                </div>
              </template>
            </div>
          </div>
        </b-col>

        <b-col cols="12" class="pt-3">
          <!-- Register Promo -->
          <RegisterPromoWidget />
        </b-col>

        <!-- IndustryBanners Banner(s) -->
        <b-col cols="10" md="12" offset="2" offset-md="0" class="pt-3">
          <IndustryBanners />
        </b-col>
      </b-row>
    </b-col>
    <AbstractModal :presentationId="currentAbstractModalPresentationId" :visible="abstractModalVisible"
      @abstract-modal-close="onAbstractModalClose" @abstract-modal-hidden="onAbstractModalHidden" />
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import { QueryHelper } from 'src/utils/query-helper';
import { MediaApiService } from 'src/services/media-api';
import DefaultPicture from '@/assets/images/platform/default-picture.jpg';

import personsMixin from 'src/mixins/personsMixin';
import openModalMixin from 'src/mixins/openModalMixin';
import esc365Mixin from 'src/mixins/esc365Mixin';

// import FavoriteButton from 'src/components/Buttons/FavoriteButton';
import RegisterPromoWidget from 'src/components/Widgets/RegisterPromoWidget';
import EscBanners from 'src/components/Banners/EscBanners';
import IndustryBanners from 'src/components/Banners/IndustryBanners';
import AbstractModal from 'src/components/Modals/AbstractModal';

export default {
  name: 'SpeakerDetails',
  mixins: [personsMixin, openModalMixin, esc365Mixin],
  components: {
    EscBanners,
    IndustryBanners,
    // FavoriteButton,
    RegisterPromoWidget,
    AbstractModal
  },
  data() {
    return {
      isMoreDetailsLoading: true,
      userId: null,
      speakerDetails: null,
      defaultPicture: DefaultPicture,
      abstractModalPresentationId: null,
      abstractModalVisible: false
    };
  },
  computed: {
    ...mapGetters(['isTimelineExpanded']),
    ...mapState({
      eventId: (state) => state.app.eventId,
      eventRoute: (state) => state.app.eventRoute,
      ressourcesMovedTo365: state => state.app.ressourcesMovedTo365,
    }),
    photoUrl() {
      return this.speakerDetails?.photoUrl;
    },
    esc365ProfileUrl() {
      return this.esc365PersonUrl(this.userId);
    },

    showAbstractModal: {
      get() {
        return this.abstractModalVisible;
      },
      set(show) {
        if (this.abstractModalVisible != show) this.abstractModalVisible = show;
      }
    },
    currentAbstractModalPresentationId: {
      get() {
        return this.abstractModalPresentationId;
      },
      set(presentationId) {
        if (this.abstractModalPresentationId != presentationId) this.abstractModalPresentationId = presentationId;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRoute = from;
    });
  },
  mounted() {
    const speakerFurl = this.$route.params?.speakerFurl;
    //console.log(speakerFurl);
    const userId = QueryHelper.extractSessionId(speakerFurl);
    //console.log(userId);
    //console.log('eventid: ' + this.eventId);
    if (userId && userId > 0) {
      this.userId = userId;
      MediaApiService.getSpeakerMoreDetails(this.eventId, userId).then(({ data }) => {
        if (data) {
          //console.log(data);
          this.speakerDetails = data;
          this.isMoreDetailsLoading = false;
        }
      });
    }
  },
  methods: {
    onBackClick() {
      window.history.back();
    },
    onAbstractModalClose() {
      //console.log('onAbstractModalClose ' + e.presentationId);
      this.currentAbstractModalPresentationId = null;
      this.showAbstractModal = false;
    },
    onAbstractView(e) {
      //console.log('onAbstractView ' + e.presentationId);
      //console.log(e);
      this.currentAbstractModalPresentationId = e.presentationId;
      this.showAbstractModal = true;
    },
    onAbstractModalHidden() {
      //console.log('onAbstractModalClose ' + e.presentationId);
      this.currentAbstractModalPresentationId = null;
      this.showAbstractModal = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/theme';

@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';


.speaker__details__wrapper {
  background: white;
  //box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 100%;
}

.speaker__details__header__button {
  display: block; //inline-block;
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 10px;
  border-left: solid 1px $grey-lighter;
  border-bottom: solid 1px $grey-lighter;
  border-radius: 0 6px;
}

.speaker__details__content__flags {
  background: var(--congress-lighter);
  border-radius: 6px;
  font-size: 0.9rem;
  float: right;
  padding: 0.2rem 0;

  &__inner {
    padding: 0.3rem 1rem 0.4rem;
  }

  &__inner:not(:first-child) {
    border-top: solid 1px white;
  }
}

.speaker__details__presentation {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  padding: 0.6rem 0.8rem;
  border-radius: 6px;
  margin-bottom: 1rem;

  &__title {
    display: block;
    font-family: 'Interstate WGL Black';
  }

  &__person {
    display: block;
  }

  &__start-time {
    display: block;
  }

  .material-icons-outlined {
    font-size: 20px;
  }

  .material-symbols-rounded {
    font-size: 22px;
  }
}
.btn-link .material-icons-round {
  font-size: 20px;
}
.b-skeleton-img {
  margin-bottom: 0.8rem;
}

.b-custom-skeleton-wrapper {
  height: 150px;

  & .b-skeleton-img {
    margin-left: auto;
    margin-bottom: 0;
  }
}

.presentation_date {
  font-size: 0.9rem;
  font-family: $font-bold;
  color: var(--congress);
  margin-bottom: 0.8rem;
}

.speaker__details__content {
  margin-top: 0.8rem;
}

.img-speaker {
  height: 10rem;
}

.speakerDetailsLinks .list-group-item {
  padding: 0 1.25rem 0 0;
  border: 0;
}

.speaker__details__presentation__title {
  display: contents;
}

.follow-speaker .la-twitter {
  color: #1da1f2;
  margin-left: 5px;
  font-size: 1.2rem;
}

.follow-speaker {
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.follow-speaker:hover {
  text-decoration: none;
  &:after {
    content: "";
    display: block;
    height: 1px;
    background: #000000;
    margin-top: 2px;
    position: absolute;
    left: 0;
    width: 100%;
  }
}

.follow-speaker * {
  vertical-align: middle;
  height: 0.9rem;
  font-size: 0.9rem;
  line-height: 0.9rem;
}



.follow-speaker-365 img {
  height: 1rem;
  vertical-align: middle;
  margin-right: 4px;
}

.follow-speaker-twitter img {
  height: 0.8rem;
  vertical-align: middle;
  margin-right: 4px;
}

.search__card__header__picture {
  width: 100px;
  border-radius: 1.25rem;
  border-left: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.presentation_abstract {
  text-align: end;
}

.presentation_abstract_text {
  color: var(--congress);
}

@media (min-width: 1024px) {
  .btn__back {
    position: sticky;
    top: 120px;
    height: 40px;
  }

  .btn__back button {
    position: absolute;
  }
}

@media (max-width: 992px) {
  .btn__back {
    position: relative !important;
    top: 10px;
  }
}

@media (max-width: 576px) {
  .search__card__header__picture {
    width: 80px;
    top: -3rem;
  }

  .speaker__details__content__title,
  .speaker_info {
    max-width: 100%;
  }
}
</style>
