<template>
  <div class="bgEvent ">
    <b-col
      cols="12"
      class="pre-home__bg pt-5 d-flex justify-content-left"
      :style="{ backgroundImage: `url('${publicPath}img/events/${eventId}/pre-home-bg.png?v=${this.appVersion}')` }"
    >
      <div class="bgGradient"></div>
      <h3 class="liveTitle">
        <div class="d-inline-flex"><span class="material-icons-round"> sensors </span> Happening now</div>
      </h3>
    </b-col>

    <div>
      <!--  TABS TO SELECT ONSITE OR ONLINE LISTING -->
      <div v-if="isLive">
        <b-tabs content-class="mt-3" justified>
          <b-tab active>
            <div v-if="!isLoading && !onlineSessionsAvailable && Ismobile">
              <!-- After Congress -->
              <b-alert :show="postCongressMode" variant="congress">
                <font-awesome-icon icon="info-circle" size="lg" class="alert_icon la-t-plus-1 mr-2 float-left" />
                It was a pleasure to welcome you to {{ eventBaseTitle }}.
              </b-alert>
              <!-- During Congress -->
              <!-- No live at this time -->
              <b-alert :show="isLive && !postCongressMode" variant="congress">
                <font-awesome-icon icon="info-circle" size="lg" class="alert_icon la-t-plus-1 mr-2 float-left" />
                There are currently no live sessions taking place.
              </b-alert>
            </div>
            <b-row v-if="onlineSessions && onlineSessions.length > 0" no-gutters class="pb-5 mb-5">
              <b-col cols="12" class="text-left pb-2 px-3">
                <span class="channeltype-row__text font-black">Online</span>
            </b-col>
            <b-col cols="12" md="4" lg="3" v-for="session in onlineSessions" :key="session.id" class="pb-2 pb-md-4 px-1 px-md-2 mb-3">
              <SessionCard
                :currentSession="session"
                :isLive="session.isLive"
                :mode="mode"
                :forceOnlineChannel="true"
                :isLiveSession="session.isLive"
                :forceProgress="true"
                :Ismobile="Ismobile"
              ></SessionCard>
            </b-col>
          </b-row>
          </b-tab>         
        </b-tabs>
      </div>
      <!-- END TABS -->
      <!-- LISTING -->
      <!-- IsLoadingTemplate -->
      <b-row v-if="isLoading" no-gutters>
        <b-col cols="12" md="4" lg="3" v-for="index in 4" :key="index" class="pb-2 pb-md-4 px-1 px-md-2">
          <b-skeleton-img no-aspect height="320px"></b-skeleton-img>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import { constants } from 'src/constants';
  import { MediaApiService } from 'src/services/media-api';
  // import Treeselect from '@riophae/vue-treeselect';
  // import '@riophae/vue-treeselect/dist/vue-treeselect.css';
  import hybridEventMixin from 'src/mixins/hybridEventMixin';
  import SessionCard from 'src/components/Cards/SessionCard';

  export default {
    name: 'HappeningNow',
    mixins: [hybridEventMixin],
    props: {
      // eventId: Number,
      mode: {
        type: String,
        default: 'Live',
        validator: function (value) {
          // The value must match one of these strings
          return value !== null && ['Live'].indexOf(value) !== -1;
        },
      },
      votingFirst: {
        type: Boolean,
        default: false,
      },
      Ismobile: Boolean
    },
    components: {
      SessionCard,
      // Treeselect
    },
    data() {
      return {
        isLoading: true,
        isLoadingMore: false,
        sessions: [],
        skip: 0,
        loadMoreVisible: false,
        newReplaysAvailable: false,
        sessionAvailable: false,
        fetchLiveNowSessionsJob: null,
        fetchReplaySessionsJob: null,
        channelValues: null,
        channelOptions: [],
        daysValues: null,
        daysOptions: [],
        publicPath: process.env.BASE_URL,
      };
    },
    computed: {
      ...mapState({
        eventBaseTitle: (state) => state.app.eventBaseTitle,
        preCongressMode: (state) => state.app.preCongressMode,
        postCongressMode: (state) => state.app.postCongressMode,
        // confCmsData: state => state.app.confCmsData
      }),
      isReplay() {
        return this.mode == 'Replay';
      },
      isComing() {
        return this.mode == 'Coming';
      },
      isLive() {
        return this.mode == 'Live';
      },
      poolingLiveSessionsInterval() {
        return parseInt(process.env.VUE_APP_POOLING_LIVE_SESSIONS_INTERVAL);
      },
      poolingReplaySessionsInterval() {
        return parseInt(process.env.VUE_APP_POOLING_REPLAY_SESSIONS_INTERVAL);
      },
      onlineSessions() {
        return this.sessions?.filter((s) => s.isOnline);
      },
      onsiteSessions() {
        return this.sessions?.filter((s) => s.isOnsite);
      },
      channelFilterLabel() {
        return this.showChannelOnline ? 'Channels' : 'Rooms';
      },
      onlineSessionsAvailable() {
        return this.onlineSessions?.length > 0;
      },
      onsiteSessionsAvailable() {
        return this.onsiteSessions?.length > 0;
      },
      ...mapGetters(['isPoolingEnabled']),
    },
    watch: {
      isPoolingEnabled(enabled) {
        if (enabled) {
          if (this.isLive) this.fetchLiveNowSessions();
          if (this.isComing) this.fetchComingSessions();
          if (this.isReplay) this.fetchReplaySessions(); // this.refreshLoadMoreReplayButton();
        }
      },
    },
    mounted() {
      if (this.isLive) {
        this.fetchLiveNowSessions();

        // Polling live sessions...
        this.fetchLiveNowSessionsJob = setInterval(() => {
          if (this.isPoolingEnabled) {
            this.fetchLiveNowSessions();
          }
        }, this.poolingLiveSessionsInterval);
      }

      if (this.isComing) this.fetchComingSessions();

      if (this.isReplay) {
        this.getChannelsOptions();
        this.getDaysOptions();

        this.fetchReplaySessions();

        // Polling replay sessions...
        this.fetchReplaySessionsJob = setInterval(() => {
          if (this.isPoolingEnabled) {
            this.fetchReplaySessions(); //this.refreshLoadMoreReplayButton();
          }
        }, this.poolingReplaySessionsInterval);
      }
    },
    beforeDestroy() {
      clearInterval(this.fetchLiveNowSessionsJob);
      clearInterval(this.fetchReplaySessionsJob);
    },
    methods: {
      fetchLiveNowSessions() {
        MediaApiService.getAllLiveNowSessions(this.eventId, this.votingFirst, false,this.Ismobile).then(({ data }) => {
          this.isLoading = false;
          if (data) {
            //data.filter((sess) => sess.isLiveNow);
            this.sessions = data;
            let nbSession = data.length;
            this.sessionAvailable = nbSession > 0;
          }
        });
      },
      fetchComingSessions() {
        MediaApiService.getComingSessions(this.eventId, this.skip, constants.LOAD_MORE_SESSIONS_COUNT + 1, false,this.Ismobile).then(({ data }) => {
          this.isLoading = false;
          if (data) {
            // data.filter((sess) => !sess.isLiveNow);
            let nbSession = data.length;
            this.loadMoreVisible = nbSession > constants.LOAD_MORE_SESSIONS_COUNT;
            if (this.loadMoreVisible) {
              data.splice(-1, 1);
            }
            this.sessions = data;
            this.skip += Math.min(nbSession, constants.LOAD_MORE_SESSIONS_COUNT); //constants.LOAD_MORE_SESSIONS_COUNT;
            this.sessionAvailable = nbSession > 0;
          }
        });
      },
      fetchReplaySessions() {
        //console.log('fetchReplaySessions');

        const channelsSelected = this.channelValues;
        const daysSelected = this.daysValues;

        //No replays displayed fetch replays from 0
        if (!this.sessionAvailable) {
          MediaApiService.getReplaySessions(
            this.eventId,
            this.skip,
            constants.LOAD_MORE_SESSIONS_COUNT + 1,
            channelsSelected?.length > 0 ? channelsSelected : null,
            daysSelected?.length > 0 ? daysSelected : null
          ).then(({ data }) => {
            this.isLoading = false;
            if (data) {
              let nbSession = data.length;
              this.loadMoreVisible = nbSession > constants.LOAD_MORE_SESSIONS_COUNT;
              if (this.loadMoreVisible) {
                data.splice(-1, 1);
              }
              this.sessions = data;
              this.sessionAvailable = nbSession > 0;
              if (this.sessionAvailable) {
                this.skip += Math.min(nbSession, constants.LOAD_MORE_SESSIONS_COUNT); //constants.LOAD_MORE_SESSIONS_COUNT;
              }
            }
          });
        }
        //At least one replay displayed, check for new replays
        else {
          //console.log('fetchcheckNewReplaysAvailable');
          if (!this.isLoading) this.checkNewReplaysAvailable();
          /*
        MediaApiService.getReplaySessions(this.eventId, this.skip, 1).then(({ data }) => {
          if (data) {
            this.loadMoreVisible = data.length > 0;
          }
        });
        */
        }
      },
      //Check if new replays available comparing 1st dispayed item and 1st iem from fetch
      checkNewReplaysAvailable() {
        const channelsSelected = this.channelValues;
        const daysSelected = this.daysValues;
        MediaApiService.getReplaySessions(
          this.eventId,
          0,
          1,
          channelsSelected?.length > 0 ? channelsSelected : null,
          daysSelected?.length > 0 ? daysSelected : null
        ).then(({ data }) => {
          if (data && data.length > 0) {
            //console.log(data[0].id);
            //console.log(this.sessions[0].id);
            this.newReplaysAvailable = data[0].id != this.sessions[0].id;
          }
        });
      },
      //Refresh replays list. Resets sessionAvailable, skip and dissmiss new replays alert
      refreshReplays() {
        //console.log('refreshReplays');
        this.isLoading = true;
        this.sessionAvailable = false;
        this.skip = 0;
        this.newReplaysAvailable = false;
        this.fetchReplaySessions();
      },
      /*
    refreshLoadMoreReplayButton() {
      MediaApiService.getReplaySessions(this.eventId, this.skip, 1).then(({ data }) => {
        if (data) {
          this.loadMoreVisible = data.length > 0;
        }
      });
    },
    */
      loadMore() {
        if (this.mode == 'Live') {
          this.loadMoreLiveNowSessions();
        }
        if (this.mode == 'Coming') {
          this.loadMoreComingSessions();
        }
        if (this.mode == 'Replay') {
          this.loadMoreReplaySessions();
        }
      },
      loadMoreLiveNowSessions() {
        this.isLoadingMore = true;
        MediaApiService.getLiveNowSessions(this.eventId, this.skip, constants.LOAD_MORE_SESSIONS_COUNT + 1, false).then(({ data }) => {
          this.isLoadingMore = false;
          if (data) {
            let nbSession = data.length;
            this.loadMoreVisible = nbSession > constants.LOAD_MORE_SESSIONS_COUNT;
            if (this.loadMoreVisible) {
              data.splice(-1, 1);
            }
            this.sessions = [...this.sessions, ...data];
            this.skip += Math.min(nbSession, constants.LOAD_MORE_SESSIONS_COUNT); //constants.LOAD_MORE_SESSIONS_COUNT;
          }
        });
      },
      loadMoreComingSessions() {
        this.isLoadingMore = true;
        MediaApiService.getComingSessions(this.eventId, this.skip, constants.LOAD_MORE_SESSIONS_COUNT + 1, false).then(({ data }) => {
          this.isLoadingMore = false;
          if (data) {
            let nbSession = data.length;
            this.loadMoreVisible = nbSession > constants.LOAD_MORE_SESSIONS_COUNT;
            if (this.loadMoreVisible) {
              data.splice(-1, 1);
            }
            this.sessions = [...this.sessions, ...data];
            this.skip += Math.min(nbSession, constants.LOAD_MORE_SESSIONS_COUNT); //constants.LOAD_MORE_SESSIONS_COUNT;
          }
        });
      },
      loadMoreReplaySessions() {
        this.isLoadingMore = true;
        const channelsSelected = this.channelValues;
        const daysSelected = this.daysValues;
        MediaApiService.getReplaySessions(
          this.eventId,
          this.skip,
          constants.LOAD_MORE_SESSIONS_COUNT + 1,
          channelsSelected?.length > 0 ? channelsSelected : null,
          daysSelected?.length > 0 ? daysSelected : null
        ).then(({ data }) => {
          this.isLoadingMore = false;
          if (data) {
            let nbSession = data.length;
            this.loadMoreVisible = nbSession > constants.LOAD_MORE_SESSIONS_COUNT;
            if (this.loadMoreVisible) {
              data.splice(-1, 1);
            }
            this.sessions = [...this.sessions, ...data];
            this.skip += Math.min(nbSession, constants.LOAD_MORE_SESSIONS_COUNT); //constants.LOAD_MORE_SESSIONS_COUNT;
          }
        });
      },
      getChannelsOptions() {
        MediaApiService.getChannelsOptions(this.eventId).then(({ data }) => {
          if (data) {
            this.channelOptions = data;
          }
        });
      },
      onTreeSelectInput() {
        this.sessionAvailable = false;
        this.skip = 0;
        this.fetchReplaySessions();
      },
      getDaysOptions() {
        MediaApiService.getDaysOptions(this.eventId).then(({ data }) => {
          if (data) {
            this.daysOptions = data;
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  .pre-home__bg {
    position: absolute;
    top: 0;
    left:0;
    right:0;
    padding-top: 2rem!important;
    min-height: 120px;
    background-size: 100%!important;
    z-index: 0;
  }

  .bgGradient {
    bottom: -1px;
  }

  .tabs {
    margin-top: 90px;
  }

  .material-icons-round {
    font-size: 2rem;
    color: red;
    margin-right: 0.5rem;
  }

  ::v-deep.nav-tabs {
    display: none;
  }

  .button__load-more {
    color: $secondary;
    background-color: var(--congress);
    border-radius: 6px;
  }

  .comming-next__title {
    display: inline-block;
    background-color: var(--congress);
    color: $secondary;
    border-radius: 2px;
    font-size: 1.2em;
  }

  .refresh-list__alert {
    cursor: pointer;
  }

  .alert_icon {
    color: var(--congress-dark);
  }

  .channeltype-row__text {
    color: $grey-dark;
    text-transform: uppercase;
  }
  /* MOBILE APP STYLE UPDATE */
  .bgEvent {
    background-repeat: no-repeat;
    height: 100vh;
  }

  .liveTitle {
    font-size: 1.7rem;
    z-index: 2;
  }

  .liveTitle img {
    height: 25px;
  }

  .bgEvent .card {
    border: 0;
  }

  .card-body {
    padding: 0.75rem 0.5rem;
  }

  .channeltype-row__text {
    color: #000;
  }

  .channeltype-row__text i {
    position: relative;
    top: 3px;
  }
  /* tabs*/
  ::v-deep .nav-tabs {
    display: none;
  }

  .nav-tabs .nav-link.active .channeltype-row__text {
    opacity: 1;
  }

  .nav-tabs .nav-link .channeltype-row__text {
    opacity: 0.6;
  }

  .tab-pane.active {
    min-width: calc(100vw - 2rem);
  }
</style>
