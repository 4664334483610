<template>
  <b-carousel :interval="7000" fade no-hover-pause>
    <b-carousel-slide v-for="banner in banners" :key="banner.id">
      <template #img>
        <div class="mx-auto text-center">
          <a v-if="banner.banner_href.url" :href="banner.banner_href.url" :target="banner.banner_target" class="d-inline-block banner_link">
            <b-img-lazy v-bind="imgPlaceholder" :src="banner.banner_image.filename" rounded fluid :alt="`Banner #${banner.id}`" class="img--banner"></b-img-lazy>
          </a>
          <div v-else class="d-inline-block">
            <b-img-lazy v-bind="imgPlaceholder" :src="banner.banner_image.filename" rounded fluid :alt="`Banner #${banner.id}`" class="img--banner"></b-img-lazy>
          </div>
        </div>
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
  export default {
    name: 'Banners',
    props: {
      banners: Array
    },
    data() {
      return {
        imgPlaceholder: {
          center: false,
          blank: true,
          blankColor: '#bbb',
          width: 970 /* force only heigh to avoid mutlisize banners issue -> no ? */,
          height: 90
        }
      };
    }
  };
</script>

<style lang="scss" scoped>
  .banner_link .img--banner {
    cursor: pointer;
    /*
    max-width: 970px;
    margin:0;
    */
    /*
    display: inline-block !important;
    */
  }
</style>
