<template>
  <b-container fluid class="m-0 p-0 bg-gray bg-full-page">
    <b-col cols="12" class="m-0 p-0 d-block">
      <template v-if="!isMetadataLoading">
        <b-row>
          <b-col v-if="false" cols="12" class="d-lg-none">
            <!-- Informations -->
            <h1>
              {{ this.session.title }}<span v-if="this.session.subTitle"> - {{ this.session.subTitle }}</span>
            </h1>
          </b-col>
          <b-col cols="12" class="pb-2 pb-lg-4" v-if="false && EscDisclaimer && EscDisclaimer.trim() !== ''">
            <!-- ESC Disclaimer -->
            <div class="disclaimer__wrapper">
              <div class="disclaimer__text" v-html="EscDisclaimer"></div>
            </div>
          </b-col>
          <b-col cols="12" class="pb-2 pb-lg-4" v-if="false && session.disclaimer && session.disclaimer.trim() !== ''">
            <!-- Disclaimer -->
            <div class="disclaimer__wrapper">
              <div class="disclaimer__text" v-html="session.disclaimer "></div>
            </div>
          </b-col>
          <!-- Not logged -->
          <template v-if="!isLogged">
            <b-col cols="12">
              <LogginWidget
                style="background: #e9ecef; align-content: center"
                class="rounded-lg mt-5"
                :forceCheckAccess="true"
                headerText="Access restricted to registered participants"
                buttonText="Sign in"
                :message="!postCongressMode ? `Please sign in to access the congress or register - it's not too late!` : `Please sign in to access the congress.`"
                :isMemberExclusive="isMemberExclusive"
              />
            </b-col>
          </template>
          <!-- Not registered -->
          <template v-else-if="!hasAccess">
            <b-col cols="12" class="m-0 p-0">
              <RegisterWidget
                style="background: #e9ecef; align-content: center"
                headerText="Access restricted to registered participants"
                :linkText="
                  !postCongressMode ? `I have already registered and want to access the live event.` : `I have already registered and want to access the content.`
                "
                :buttonText="!postCongressMode ? `Register Now` : ``"
                :showButton="!postCongressMode"
                message="This content is only available to users who have registered to the event."
                :isMemberExclusive="isMemberExclusive"
              />
            </b-col>
          </template>
          <template v-else>
            <!-- Player -->
            <b-col cols="12" class="mb-2 mb-lg-0">
              <!-- BrightcovePlayer -->
              <BrightcovePlayer
                ref="bcPlayer"
                :key="this.videoId"
                :accountId="this.brightcoveAccountId"
                :playerId="this.brightcovePlayerId"
                :videoId="this.videoId"
                v-on:loaded="onBrightcovePlayerLoaded"
                class="modal-widget mb-lg-4"
              />
            </b-col>
            <!-- Widget -->
            <b-col v-if="false" cols="12" lg="4">
              <!-- PigeonWidget -->
              <PigeonWidget :key="this.session.interactRoomId" :pigeonId="this.session.interactRoomId" v-on:loaded="onPigeonWidgetLoaded" class="modal-widget" />
            </b-col>
          </template>
        </b-row>
      </template>
    </b-col>
  </b-container>
</template>

<script>
  import { CLOSE_CHANNEL_MODAL, DISABLE_POOLING, ENABLE_POOLING } from 'src/store/actions';
  import { mapGetters, mapState } from 'vuex';
  import { MediaApiService } from 'src/services/media-api';
  import { consoleColors } from 'src/utils/console-color.js';
  import { constants } from 'src/constants';
  import personsMixin from 'src/mixins/personsMixin';
  import { DateHelper } from 'src/utils/date-helper';
  import BrightcovePlayer from 'src/components/Players/BrightcovePlayer';
  import PigeonWidget from 'src/components/Widgets/PigeonWidget';
  import LogginWidget from 'src/components/Widgets/LogginWidget';
  import RegisterWidget from 'src/components/Widgets/RegisterWidget';
  import { Duration } from 'luxon';

  export default {
    name: 'MobileAppChannel',
    mixins: [personsMixin],
    components: {
      BrightcovePlayer,
      PigeonWidget,
      LogginWidget,
      RegisterWidget,
    },
    props: {
      channelId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        isMetadataLoading: true,
        isPigeonLoading: true,
        isMoreDetailsLoading: true,
        isFirstClickOnMoreDetails: true,
        isBcPlayerLoaded: false,
        waitingForClosing: false,
        hasAccess: false,
        session: null,
        sessionMoreDetails: null,
        sessionBanners: null,
        fetchNextSessionDetailsJob: null,
        networks: [
          { network: 'facebook', name: 'facebook', icon: require('@/assets/images/social/facebook-black.png') },
          { network: 'twitter', name: 'twitter', icon: require('@/assets/images/social/x-logo-black.png') },
          { network: 'linkedin', name: 'linkedin', icon: require('@/assets/images/social/linkedin-black.png') },
        ],
      };
    },
    metaInfo() {
      if (this.session) {
        return {
          meta: [
            { name: 'description', vmid: 'description', content: this.metaDescription },
            // OpenGraph
            { property: 'og:title', vmid: 'og:title', content: this.metaTitle },
            { property: 'og:description', vmid: 'og:description', content: this.metaDescription },
            { property: 'og:type', vmid: 'og:type', content: 'video.movie' },
            { property: 'og:url', vmid: 'og:url', content: this.metaUrl },
            // Twitter
            { property: 'twitter:title', vmid: 'twitter:title', content: this.metaTitle },
            { property: 'twitter:description', vmid: 'twitter:description', content: this.metaDescription },
          ],
        };
      }
    },
    computed: {
      ...mapGetters(['currentUser']),
      ...mapState({
        isLogged: (state) => state.auth.loggedIn,
        eventBaseTitle: (state) => state.app.eventBaseTitle,
        brightcoveAccountId: (state) => state.app.brightcoveAccountId,
        brightcovePlayerId: (state) => state.app.brightcovePlayerId,
        preCongressMode: (state) => state.app.preCongressMode,
        postCongressMode: (state) => state.app.postCongressMode,
        eventRoute: (state) => state.app.eventRoute,
      }),
      metaTitle() {
        return `${this.eventBaseTitle} | ${this.session.title}`;
      },
      metaDescription() {
        return `${this.session.title} session at ${this.eventBaseTitle}`;
      },
      hasExternalLink() {
        return this.session.hasExternalLink;
      },
      sessionId() {
        return this.session?.id;
      },
      videoId() {
        return this.session?.liveVideoId;
      },
      isMemberExclusive() {
        const memberExclusiveDigitalSessionIds = [5767, 5768, 5769, 5770];
        return memberExclusiveDigitalSessionIds.includes(this.session?.id);
      },
      EscDisclaimer() {
        return (this.session.EscDisclaimer ?? '').length == 0
          ? this.session.isIndustry
            ? constants.INDUSTRY_DEFAULT_DISCLAIMER
            : null
          : this.session.EscDisclaimer;
      },
    },
    watch: {
      // This function will run everytime data.isBcPlayerLoaded change
      isBcPlayerLoaded(isLoaded) {
        if (isLoaded && this.waitingForClosing) this.$store.dispatch(CLOSE_CHANNEL_MODAL);
      },
    },
    mounted() {
      // Disabling pooling...
      this.$store.dispatch(DISABLE_POOLING);

      // if (this.isLogged) await checkAccess(undefined, false, window.location, false);
      this.refreshSessionDetails();
    },
    beforeDestroy() {
      // Enabling pooling...
      this.$store.dispatch(ENABLE_POOLING);

      // We are reseting modal's state...
      this.resetState();

      // We are clearing jobs...
      this.clearNextSessionDetailsJob();
    },
    methods: {
      close() {
        if (!this.isLogged || this.isMetadataLoading || this.isBcPlayerLoaded || !this.hasAccess) this.$store.dispatch(CLOSE_CHANNEL_MODAL);
        else this.waitingForClosing = true;
      },
      refreshSessionDetails() {
        MediaApiService.getSessionDetailsByChannel(this.channelId).then(async ({ data }) => {
          if (data) {
            this.hasAccess = data?.hasAccess ?? false;
            this.session = data;

            if (!this.hasAccess) this.logAccessDenied();
            this.isMetadataLoading = false;

            const hasExternalLink = data?.hasExternalLink;
            if (hasExternalLink && this.hasAccess) {
              const externalLink = data?.externalLink;
              window.open(externalLink, '_blank');
              this.close();
            } else {
              this.createNextSessionDetailsJob();
            }
          }
        });
      },
      fetchNextSessionDetails() {
        let previousVideoId = this.videoId;

        MediaApiService.getSessionDetailsByChannel(this.channelId)
          .then(async ({ data }) => {
            if (data) {
              // We are reseting modal's state...
              this.resetState();
              this.hasAccess = data?.hasAccess ?? false;
              this.session = data;
              this.isMetadataLoading = false;
              this.createNextSessionDetailsJob();
              if (!this.hasAccess) this.logAccessDenied();
              const hasExternalLink = data?.hasExternalLink;

              // If video id doesn't change BC player is not reload -> player loaded falg forced to true;
              if (!hasExternalLink && this.videoId == previousVideoId) this.isBcPlayerLoaded = true;

              if (hasExternalLink && this.hasAccess) {
                const externalLink = data?.externalLink;
                window.open(externalLink, '_blank');
                this.close();
              } /*else {
                  this.createNextSessionDetailsJob();
                }*/
            }
          })
          .catch((err) => {
            if (err?.response?.status == 404) {
              // TODO: Display a message instead ?
              this.close();
            }
          });
      },
      onMoreDetailsClick() {
        if (!this.isFirstClickOnMoreDetails) return;

        this.isFirstClickOnMoreDetails = false;

        MediaApiService.getSessionMoreDetails(this.sessionId).then(({ data }) => {
          if (data) {
            this.isMoreDetailsLoading = false;
            this.sessionMoreDetails = data;
          }
        });
      },
      onBrightcovePlayerLoaded() {
        this.isBcPlayerLoaded = true;
      },
      onPigeonWidgetLoaded() {
        // If needed...
      },
      createNextSessionDetailsJob() {
        const currentTime = DateHelper.browserDateTime().getTime();
        const endTime = this.session.endTime;

        let nextRefreshTime = this.session.nextRefreshTime;
        if (!nextRefreshTime) nextRefreshTime = endTime;

        let nextRefreshDelay = this.session.nextRefreshDelay;
        if (!nextRefreshDelay) nextRefreshDelay = nextRefreshTime - currentTime; // If you want to test set: 10000;
        // if nextRefreshDelay negative set 30sec
        if (nextRefreshDelay <= 0) nextRefreshDelay = 30000;
        //console.log('nextRefreshDelay', Duration.fromMillis(nextRefreshDelay).toFormat("hh:mm:ss"));
        //console.log(`end ${this.$options.filters.formatUnixEpocTimePartForLog(endTime)}`);
        console.debug(`%cnext Session -30sec: ${this.$options.filters.formatUnixEpocTimePartForLog(nextRefreshTime)}`, consoleColors.misc);
        console.debug(`%cnext refresh in: ${Duration.fromMillis(nextRefreshDelay).toFormat('hh:mm:ss')}`, consoleColors.misc);

        // We are clearing jobs...
        this.clearNextSessionDetailsJob();

        // 🙃 -> https://stackoverflow.com/a/3468650
        if (nextRefreshDelay >= 2147483648) nextRefreshDelay = 2147483647;

        this.fetchNextSessionDetailsJob = setTimeout(this.fetchNextSessionDetails, nextRefreshDelay);
      },
      clearNextSessionDetailsJob() {
        if (this.fetchNextSessionDetailsJob) {
          clearTimeout(this.fetchNextSessionDetailsJob);
          this.fetchNextSessionDetailsJob = null;
        }
      },
      resetState() {
        this.hasAccess = false;

        this.isBcPlayerLoaded = false;
        this.waitingForClosing = false;

        this.isMetadataLoading = true;
        this.isMoreDetailsLoading = true;

        this.isFirstClickOnMoreDetails = true;

        this.session = null;
      },
      logAccessDenied() {
        if (this.$appInsights && this.currentUser) {
          const logAccessDeniedProps = {
            escId: this.currentUser?.escId,
            channelId: this.channelId,
            sessionId: this.sessionId,
            videoId: this.videoId,
            source: 'MobileAppChannel',
          };
          console.info(`%cAppInsights: logAccessDenied ${JSON.stringify(logAccessDeniedProps)}`, consoleColors.insights);
          this.$appInsights?.trackEvent({ name: 'logAccessDenied', properties: logAccessDeniedProps });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  @import '~@/styles/theme';

  $media-modal-bg-primary: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),
    radial-gradient(at top center, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.8) 120%) #989898;
  $media-modal-bg-secondary: #656565;

  $media-modal-primary: $primary;
  $media-modal-secondary: #9e9e9e;

  .session__favorite__button {
    margin-left: auto;
    cursor: pointer;
    z-index: 100;
  }

  .session__details__presentation__hour {
    color: var(--congress);
  }

  ::v-deep #media-modal > .modal-dialog {
    height: 100%;
    max-width: 100%;
    overflow-y: auto;
    margin: 0 auto;
  }

  ::v-deep #media-modal {
    padding: 0 !important;
  }

  ::v-deep #media-modal .modal-header {
    border-bottom: none;
    margin-bottom: 25px;

    .close {
      font-size: 2.5rem;
      opacity: 1;
      margin: 0 0 -1.5rem auto;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding: 0;
    }

    .close::after {
      position: absolute;
      top: 72px;
      right: 24px;
      white-space: pre;
      line-height: 1.2;
      font-size: 0.9rem;
      content: 'Close\avideo';
    }
  }

  @include media-breakpoint-down(sm) {
    ::v-deep #media-modal .modal-header {
      padding: 0.3rem 1rem;

      .close {
        font-size: 1.6rem;
        margin: 0 0 0.3rem auto;
        width: 30px;
        height: 30px;
      }

      .close::after {
        top: 42px;
        right: 17px;
        line-height: 1.3;
        font-size: 0.6rem;
      }
    }
  }

  ::v-deep #media-modal .modal-content {
    height: 100%;
    overflow-y: auto;
    background: #fff;
    background-blend-mode: multiply, multiply;
    border: none;
    border-radius: unset;
  }

  @include media-breakpoint-down(sm) {
    ::v-deep #media-modal .modal-body {
      padding: 0;
    }
  }

  .modal-widget {
    height: 100svh;
    width: 100svw;
  }

  .bg-gray {
    background: rgb(233, 236, 239);
  }

  .bg-full-page {
    height: 100svh;
    widows: 100svw;
  }

  h1 {
    font-size: 1.75rem;
  }

  h5 {
    color: $media-modal-primary;
  }

  .modal__session-subtitle {
    color: $media-modal-primary;
  }

  .modal__session-contact-label {
    display: block;
    color: #d1d1d1;
  }

  .modal__session-contact-link {
    color: $media-modal-primary;
    text-decoration: underline;
  }

  .disclaimer__wrapper {
    display: flex;
    align-items: center;
    color: $media-modal-primary;
  }

  .disclaimer__icon {
    padding-right: 10px;
  }

  .disclaimer__text {
    display: inline-block;
    font-size: 0.9em;
  }

  @include media-breakpoint-down(md) {
    .disclaimer__text {
      text-align: justify;
      font-size: 0.65em;
    }
  }

  .share-wrapper {
    color: $media-modal-primary;
  }

  .share-link {
    position: relative;
    top: -3px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
  }

  .session-duration {
    font-size: 0.85em;
  }

  .card__more-details {
    border: 0;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: inherit;
    overflow: visible;

    .card-body {
      color: $primary;
      padding: 0;
      padding-top: 2rem;
    }

    .b-skeleton-img {
      margin-bottom: 10px;
    }
  }

  .card-text__speaker {
    display: block;
  }

  .session__details__presentation {
    background: $secondary;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    padding: 0.6rem 0.8rem;
    border-radius: 6px;
    margin-bottom: 1rem;

    &__title {
      display: block;
    }

    &__person {
      display: block;
    }

    &__start-time {
      display: block;
      color: var(--congress);
      font-size: 0.9rem;
    }

    img {
      vertical-align: baseline;
    }
  }

  @include media-breakpoint-down(md) {
    .card__presentation {
      max-width: 100%;
    }

    .btn-collapse {
      display: block;
      width: 100%;
    }
  }

  .btn__back {
    top: 1rem;
    left: 1rem;
  }

  @include media-breakpoint-down(sm) {
    .btn__back {
      position: relative !important;
      top: 0.5rem;
      left: 0;
      padding-left: 1.5rem;
    }
  }

  .btn-collapse {
    color: $primary;
  }

  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }

  :not(.collapsed).btn-collapse {
    border-radius: 0.25em 0.25em 0 0;
  }

  .session__details__content__flags {
    font-size: 0.9rem;

    &__inner {
      margin-right: 1.25rem;

      .material-icons-round,
      .material-icons-outlined,
      .material-symbols-rounded {
        font-size: 22px;
      }
    }

    &__inner:last-of-type {
      margin-right: 0;
    }

    &__inner:not(:first-child) {
      border-top: solid 1px white;
    }
  }
</style>
