<template>
  <fragment>
    <div>
      <b-row>
        <!-- MainTemplate -->
        <template v-if="results">
          <!-- DigitalSession -->
          <template
            v-if="
              searchDocType == 'DigitalSession' ||
              searchDocType == 'DigitalSessionIndustry' ||
              searchDocType == 'DigitalSessionDailyHighLight' ||
              searchDocType == 'DigitalSessionEscTV'
            "
          >
            <b-col v-for="result in results" :key="result.dbId" cols="12" sm="6" md="6" lg="4" xl="3" class="mb-4 card-deck m-0 p-0">
              <SessionResultCard :result="result" :serverTime="serverTime" :docType="docType"
              />
            </b-col>
          </template>

          <!-- Speaker -->
          <template v-if="searchDocType == 'Speaker'">
            <b-col v-for="result in results" :key="result.dbId" sm="12" md="6" lg="4" class="pb-2 pb-md-4 px-1 px-md-3">
              <SpeakerResultCard :result="result" />
            </b-col>
          </template>

          <!-- Abstract -->
          <template v-if="searchDocType == 'Abstract'">
            <b-col v-for="result in results" :key="result.dbId" cols="12" sm="6" md="6" lg="4" xl="3" class="mb-4 card-deck m-0 p-0">
              <AbstractResultCard :result="result" :query="query" @abstract-view="onAbstractView" />
            </b-col>
          </template>

          <!-- Industry Stand -->
          <template v-if="resultType == 'IndustryStand'">
            <b-col v-for="result in results" :key="result.contentId" cols="12" sm="6" md="6" lg="4" class="pb-2 pb-md-4 px-1 px-md-2">
              <IndustryStandResultCard :result="result" />
            </b-col>
          </template>
        </template>
      </b-row>
    </div>
    <AbstractModal
      :presentationId="currentAbstractModalPresentationId"
      :visible="abstractModalVisible"
      @abstract-modal-close="onAbstractModalClose"
      @abstract-modal-hidden="onAbstractModalHidden"
    />
  </fragment>
</template>

<script>
  // import { mapGetters } from 'vuex';
  import SessionResultCard from 'src/components/Cards/SessionResultCard';
  import SpeakerResultCard from 'src/components/Cards/SpeakerResultCard';
  import AbstractResultCard from 'src/components/Cards/AbstractResultCard';
  import IndustryStandResultCard from 'src/components/Cards/IndustryStandResultCard';
  import AbstractModal from 'src/components/Modals/AbstractModal';

  export default {
    name: 'SearchResultsCards',
    components: {
      SessionResultCard,
      SpeakerResultCard,
      AbstractResultCard,
      IndustryStandResultCard,
      AbstractModal,
    },
    props: {
      resultType: String,
      results: Array,
      query: String,
      serverTime: Number,
      daySelected: Date,
      searchDocType: String,
      docType: String
    },
    watch: {
      results(newResults) {
        this.results = newResults;
      },
    },
    data() {
      return {
        abstractModalPresentationId: null,
        abstractModalVisible: false,
      };
    },
    mounted() {
        this.$emit('is-loading', false);
    },
    computed: {
      showAbstractModal: {
        get() {
          return this.abstractModalVisible;
        },
        set(show) {
          if (this.abstractModalVisible != show) this.abstractModalVisible = show;
        },
      },
      currentAbstractModalPresentationId: {
        get() {
          return this.abstractModalPresentationId;
        },
        set(presentationId) {
          if (this.abstractModalPresentationId != presentationId) this.abstractModalPresentationId = presentationId;
        },
      },
    },
    methods: {
      onAbstractModalClose() {
        //console.log('onAbstractModalClose ' + e.presentationId);
        this.currentAbstractModalPresentationId = null;
        this.showAbstractModal = false;
      },
      onAbstractView(e) {
        //console.log('onAbstractView ' + e.presentationId);
        this.currentAbstractModalPresentationId = e.presentationId;
        this.showAbstractModal = true;
      },
      onAbstractModalHidden() {
        //console.log('onAbstractModalClose ' + e.presentationId);
        this.currentAbstractModalPresentationId = null;
        this.showAbstractModal = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
</style>
