import { render, staticRenderFns } from "./AskQuestionButton.vue?vue&type=template&id=27a77791&scoped=true&"
import script from "./AskQuestionButton.vue?vue&type=script&lang=js&"
export * from "./AskQuestionButton.vue?vue&type=script&lang=js&"
import style0 from "./AskQuestionButton.vue?vue&type=style&index=0&id=27a77791&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27a77791",
  null
  
)

export default component.exports