<template>
  <div>
    <div v-if="!isLoading && channels && channels.length == 0">
      <!-- No upcoming sessions -->
      <b-alert :show="true" variant="congress">
        <font-awesome-icon icon="info-circle" size="lg" class="alert_icon la-t-plus-1 mr-2 float-left" />
        Stay tuned for the next session.
      </b-alert>
    </div>

    <!-- IsLoadingTemplate -->
    <template v-if="isLoading">
      <b-row class="channel-row">
        <b-col cols="12" class="text-left pb-2">
          <b-skeleton width="25%"></b-skeleton>
        </b-col>

        <b-col cols="6" md="4" lg="3" v-for="index in 4" :key="index" class="pb-2 pb-md-4 px-1 px-md-2">
          <b-skeleton-img no-aspect height="320px"></b-skeleton-img>
        </b-col>
      </b-row>
    </template>

    <!-- MainTemplate -->
    <template v-if="!isLoading && channels && channels.length > 0">
      <b-row v-for="channel in channels" :key="channel.id" class="channel-row mt-3">
        <b-col cols="12" class="text-left pb-2 font-black">
          <span class="channel-row__text">{{ channel.name }}</span>
        </b-col>

        <b-col cols="12" md="6" lg="4" xl="3" class="card-deck pb-2 pb-md-4 px-0 mx-0" v-for="item in channel.items" :key="item.id">
          <SessionCard :currentSession="item" :isLive="item.isLiveNow" mode="Coming"></SessionCard>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
  import { MediaApiService } from 'src/services/media-api';
  import SessionCard from 'src/components/Cards/SessionCard';
  import { mapGetters } from 'vuex';

  export default {
    name: 'ChannelSessionsBlock',
    props: {
      eventId: Number,
      itemTake: Number,
    },
    components: {
      SessionCard,
    },
    data() {
      return {
        isLoading: true,
        channels: null,
        fetchComingSessionsJob: null,
      };
    },
    computed: {
      poolingLiveSessionsInterval() {
        return parseInt(process.env.VUE_APP_POOLING_LIVE_SESSIONS_INTERVAL);
      },
      ...mapGetters(['isPoolingEnabled']),
    },
    mounted() {
      this.fetchComingSessions();
      // Polling coming sessions...
      this.fetchComingSessionsJob = setInterval(() => {
        if (this.isPoolingEnabled) {
          this.fetchComingSessions();
        }
      }, this.poolingLiveSessionsInterval);
    },
    methods: {
      fetchComingSessions() {
        MediaApiService.getTimeLineComing(this.eventId, this.itemTake).then(({ data }) => {
          this.isLoading = false;
          if (data) {
            this.channels = data;
          }
        });
      },
    },
    watch: {
      isPoolingEnabled(enabled) {
        if (enabled) {
          this.fetchComingSessions();
        }
      },
    },
    beforeDestroy() {
      clearInterval(this.fetchComingSessionsJob);
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .channel-row {
    @include media-breakpoint-down(lg) {
      :nth-child(6) {
        display: none;
      }
    }
    @include media-breakpoint-down(md) {
      :nth-child(5) {
        display: none;
      }
    }
    @include media-breakpoint-down(sm) {
      :nth-child(4) {
        display: none;
      }
    }
  }

  .channel-row__text {
    color: $grey-dark;
    text-transform: uppercase;
  }

  .alert_icon {
    color: var(--congress-dark);
  }
</style>
