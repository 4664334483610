<template>
  <b-modal id="media-modal" v-model="show" @show="onShow()" @hide="onHide()" :no-close-on-esc="true" hide-header hide-footer no-fade>
    <!-- Close -->
    <div class="btn__back mb-2 position-sticky float-lg-left">
      <b-button pill variant="clear" size="sm" class="font-regular mt-2 d-inline d-inline-flex align-items-center border-0" @click="close()">
        <span class="material-icons-round mr-2"> arrow_back </span>
        Back
      </b-button>
    </div>

    <b-container fluid>
      <b-col cols="12" sm="12" md="8" lg="9" class="m-auto d-block pb-5">
        <template v-if="!isMetadataLoading">
          <b-row>
            <b-col v-if="this.sessionId" cols="12" class="mb-2 mb-lg-4">
              <ModalBanners :key="this.sessionId" :sessionId="this.sessionId" />
            </b-col>

            <b-col cols="12" class="d-lg-none">
              <!-- Informations -->
              <h1>
                {{ this.session.title }}<span v-if="this.session.subTitle"> - {{ this.session.subTitle }}</span>
              </h1>
            </b-col>

            <b-col sm="12" :lg="!replayMode ? 8 : 10" :offset-lg="!replayMode ? 0 : 1" class="pb-2 pb-lg-4" v-if="disclaimer && disclaimer.trim() !== ''">
              <!-- Disclaimer -->
              <div class="disclaimer__wrapper">
                <div class="disclaimer__text" v-html="this.disclaimer"></div>
              </div>
            </b-col>

            <!-- Not logged -->
            <template v-if="!isLogged">
              <b-col cols="12" :lg="!replayMode ? 8 : 10" :offset-lg="!replayMode ? 0 : 1" class="mb-2 mb-lg-0">
                <LogginWidget
                  style="aspect-ratio: 16/9; background: #e9ecef; align-content: center"
                  class="rounded-lg"
                  :forceCheckAccess="true"
                  headerText="Access restricted to registered participants"
                  buttonText="Sign in"
                  :message="!postCongressMode ? `Please sign in to access the congress or register - it's not too late!` : `Please sign in to access the congress.`"
                />
              </b-col>
            </template>

            <!-- Not registered -->
            <template v-else-if="!hasAccess">
              <b-col cols="12" :lg="!replayMode ? 8 : 10" :offset-lg="!replayMode ? 0 : 1" class="mb-2 mb-lg-0">
                <RegisterWidget
                  style="aspect-ratio: 16/9; background: #e9ecef; align-content: center"
                  headerText="Access restricted to registered participants"
                  :linkText="
                    !postCongressMode ? `I have already registered and want to access the live event.` : `I have already registered and want to access the content.`
                  "
                  :buttonText="!postCongressMode ? `Register Now` : ``"
                  :showButton="!postCongressMode"
                  message="This content is only available to users who have registered to the event."
                />
              </b-col>
            </template>

            <template v-else>
              <!-- Player -->
              <b-col cols="12" :lg="!replayMode ? 8 : 10" :offset-lg="!replayMode ? 0 : 1" class="mb-2 mb-lg-0">
                <!-- BrightcovePlayer -->
                <BrightcovePlayer
                  ref="bcPlayer"
                  :key="this.videoId"
                  :accountId="this.brightcoveAccountId"
                  :playerId="this.brightcovePlayerId"
                  :videoId="this.videoId"
                  v-on:loaded="onBrightcovePlayerLoaded"
                  class="modal-widget mb-lg-4"
                />
              </b-col>

              <!-- Widget -->
              <b-col cols="12" lg="4" :class="{ 'd-none': replayMode }">
                <!-- PigeonWidget -->
                <PigeonWidget :key="this.session.interactRoomId" :pigeonId="this.session.interactRoomId" v-on:loaded="onPigeonWidgetLoaded" class="modal-widget" />
              </b-col>
            </template>
            <b-col :lg="!replayMode ? 5 : 10" :offset-lg="!replayMode ? 0 : 1" class="d-none d-lg-block mt-3">
              <h1 class="mb-1">
                {{ this.session.title }}
              </h1>
              <div class="mb-2">
                <span class="modal__session-subtitle" v-if="this.session.subTitle"> {{ this.session.subTitle }} </span>
                <!-- Industry link -->
                <a v-if="!preCongressMode && this.session.industryLink" class="ml-3" :href="this.session.industryLink" target="_blank">
                  <b-button variant="clear" size="sm" class="font-weight-bold">
                    <i class="las la-3x la-t-plus-2 la la-external-link mr-1"></i>
                    Exhibitor Profile
                  </b-button>
                </a>
              </div>
              <div class="session__details__content__flags d-block font-regular">
                <!-- Date + Start Time + End Time -->
                <div class="session__details__content__flags__inner d-inline-flex align-items-center">
                  <span class="mr-1 material-icons-round"> calendar_month </span>
                  <span class="flags__label">
                    {{ session.startTime | formatUnixEpocDayPart }} from {{ session.startTime | formatUnixEpocTimePart }} to
                    {{ session.endTime | formatUnixEpocTimePart }} <span class="ml-1">({{ this.session.duration }}mn)</span>
                  </span>
                </div>
                <!-- Sharing -->
                <div class="d-none session__details__content__flags__inner d-inline-flex align-items-center">
                  <span class="share-wrapper session__details__content__flags d-inline-flex align-items-center font-regular lh-1">
                    <span class="material-icons-round mr-1"> share </span> <span class="flags__label mr-1"> Share on </span>
                    <ShareNetwork
                      v-for="network in networks"
                      :network="network.network"
                      :key="network.network"
                      :url="metaUrl"
                      :title="metaTitle"
                      :description="metaDescription"
                    >
                      <span class="share-link">
                        <img :src="network.icon" :alt="network.name" />
                      </span>
                    </ShareNetwork>
                  </span>
                </div>
                <!-- end sharing -->
              </div>
            </b-col>

            <!-- More -->
            <b-col cols="12" :lg="!replayMode ? 8 : 10" :offset-lg="!replayMode ? 0 : 1" class="mt-3">
              <b-button pill v-b-toggle.collapse-more-details @click="onMoreDetailsClick" variant="dark" class="d-block with-icon">
                <i class="las la-4x la-t-plus-2 la-plus-circle when-closed mr-1"></i><span class="when-closed">More</span>
                <i class="las la-4x la-t-plus-2 la-minus-circle when-opened mr-1"></i><span class="when-opened">Less</span>
                details on this session
              </b-button>

              <b-collapse :visible="!isMoreDetailsLoading" id="collapse-more-details" class="collapse__more-details">
                <b-card class="card__more-details">
                  <!-- Empty -->
                  <template
                    v-if="
                      !this.isMoreDetailsLoading &&
                      (!this.sessionMoreDetails.chairpersons || this.sessionMoreDetails.chairpersons.length == 0) &&
                      !this.sessionMoreDetails.learningObjectives &&
                      (!this.sessionMoreDetails.presentations || this.sessionMoreDetails.presentations.length == 0)
                    "
                  >
                    <p class="card-text">There is no more information to display</p>
                  </template>

                  <!-- Chairpersons -->
                  <template v-if="this.isMoreDetailsLoading">
                    <div class="mb-4">
                      <b-skeleton width="10%" class="mb-2"></b-skeleton>
                      <b-skeleton width="20%"></b-skeleton>
                    </div>
                  </template>

                  <template v-if="!this.isMoreDetailsLoading && this.sessionMoreDetails.chairpersons && this.sessionMoreDetails.chairpersons.length > 0">
                    <h5>Chairpersons</h5>
                    <p class="card-text">
                      <span v-for="(chair, index) in this.sessionMoreDetails.chairpersons" :key="index" class="card-text__speaker">
                        <router-link :to="`/${eventRoute}/speakers/${chair.userId}`" >
                          <span @click="close()" class="btn-link font-light"> {{ fullNameWithAddress(chair) }} </span>
                        </router-link>
                      </span>
                    </p>
                  </template>

                  <!-- LearningObjectives -->
                  <template v-if="this.isMoreDetailsLoading">
                    <div class="mb-4">
                      <b-skeleton width="12%" class="mb-2"></b-skeleton>
                      <b-skeleton width="30%"></b-skeleton>
                    </div>
                  </template>

                  <template
                    v-if="!this.isMoreDetailsLoading && this.sessionMoreDetails.learningObjectives && this.sessionMoreDetails.learningObjectives.trim() !== ''"
                  >
                    <h5>More on the session</h5>
                    <p class="card-text text-white-space-pre-wrap">{{ this.sessionMoreDetails.learningObjectives | trim }}</p>
                  </template>

                  <!-- Presentations -->
                  <template v-if="this.isMoreDetailsLoading">
                    <div class="mb-4">
                      <b-skeleton width="10%" class="mb-2"></b-skeleton>
                      <b-skeleton-img no-aspect height="80px" width="80%" class="mb-2"></b-skeleton-img>
                      <b-skeleton-img no-aspect height="80px" width="80%" class="mb-1"></b-skeleton-img>
                      <b-skeleton-img no-aspect height="80px" width="80%"></b-skeleton-img>
                    </div>
                  </template>

                  <template v-if="!this.isMoreDetailsLoading && this.sessionMoreDetails.presentations && this.sessionMoreDetails.presentations.length > 0">
                    <h5 class="mt-4 mb-3">Presentations</h5>

                    <div v-for="(presentation, index) in this.sessionMoreDetails.presentations" :key="index" class="session__details__presentation px-4 py-3">
                      <span class="session__details__presentation__title font-black">{{ presentation.title }}</span>
                      <span v-for="(person, index) in presentation.persons" :key="index" class="session__details__presentation__person">
                        {{ person.role }}: 
                        <router-link :to="`/${eventRoute}/speakers/${person.userId}`">
                           <span @click="close()" class="btn-link font-light"> {{ fullNameWithAddress(person) }} </span>
                          </router-link>
                      </span>
                      <!--
                    <span v-if="!sessionMoreDetails.isOnDemand" class="card__presentation__start-time"> <i class="las la-2x la-t-plus-1 la-clock"></i> Time: {{ presentation.startTime | formatUnixEpocTimePart }}</span>
                    --></div>
                  </template>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-container>
  </b-modal>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';

  import { CLOSE_MEDIA_MODAL, DISABLE_POOLING, ENABLE_POOLING } from 'src/store/actions';
  import { MediaType } from 'src/enums/media-type';
  import { consoleColors } from 'src/utils/console-color.js';

  import personsMixin from 'src/mixins/personsMixin';

  import ModalBanners from 'src/components/Banners/ModalBanners';
  import BrightcovePlayer from 'src/components/Players/BrightcovePlayer';
  import PigeonWidget from 'src/components/Widgets/PigeonWidget';
  import LogginWidget from 'src/components/Widgets/LogginWidget';
  import RegisterWidget from 'src/components/Widgets/RegisterWidget';

  import { MediaApiService } from 'src/services/media-api';
  import { constants } from 'src/constants';
  //import { DateHelper } from 'src/utils/date-helper';
  export default {
    name: 'MediaModal',
    mixins: [personsMixin],
    components: {
      ModalBanners,
      BrightcovePlayer,
      PigeonWidget,
      LogginWidget,
      RegisterWidget,
    },
    data() {
      return {
        isMetadataLoading: true,
        isPigeonLoading: true,
        isMoreDetailsLoading: true,
        isFirstClickOnMoreDetails: true,
        isBcPlayerLoaded: false,
        waitingForClosing: false,
        hasAccess: false,
        session: null,
        sessionMoreDetails: null,
        fetchNextSessionDetailsJob: null,
        networks: [
          { network: 'facebook', name: 'facebook', icon: require('@/assets/images/social/facebook-black.png') },
          { network: 'twitter', name: 'twitter', icon: require('@/assets/images/social/x-logo-black.png') },
          { network: 'linkedin', name: 'linkedin', icon: require('@/assets/images/social/linkedin-black.png') },
        ],
      };
    },
    metaInfo() {
      if (this.isSession && this.session) {
        return {
          meta: [
            { name: 'description', vmid: 'description', content: this.metaDescription },
            // OpenGraph
            { property: 'og:title', vmid: 'og:title', content: this.metaTitle },
            { property: 'og:description', vmid: 'og:description', content: this.metaDescription },
            { property: 'og:type', vmid: 'og:type', content: 'video.movie' },
            { property: 'og:url', vmid: 'og:url', content: this.metaUrl },
            // Twitter
            { property: 'twitter:title', vmid: 'twitter:title', content: this.metaTitle },
            { property: 'twitter:description', vmid: 'twitter:description', content: this.metaDescription },
          ],
        };
      }
    },
    computed: {
      ...mapGetters(['currentUser']),
      ...mapState({
        media: (state) => state.modal.media,
        isLogged: (state) => state.auth.loggedIn,
        eventBaseTitle: (state) => state.app.eventBaseTitle,
        brightcoveAccountId: (state) => state.app.brightcoveAccountId,
        brightcovePlayerId: (state) => state.app.brightcovePlayerId,
        preCongressMode: (state) => state.app.preCongressMode,
        postCongressMode: (state) => state.app.postCongressMode,
        eventRoute: (state) => state.app.eventRoute,
      }),
      show: {
        get() {
          return this.media.show;
        },
        set(show) {
          if (!show) this.close();
        },
      },
      metaTitle() {
        return `${this.eventBaseTitle} | ${this.session.title}`;
      },
      metaDescription() {
        if (this.isSession) {
          return `${this.session.title} session at ${this.eventBaseTitle}`;
        } else {
          return this.eventBaseTitle;
        }
      },
      metaUrl() {
        return `${window.location.origin}?v=${this.session.furl}`;
      },
      replayMode() {
        return this.session.isReplay;
      },
      isSession() {
        return this.media.metadata.type == MediaType.SESSION;
      },
      sessionId() {
        return this.session?.id ?? this.media?.metadata?.id;
      },
      videoId() {
        if (!this.replayMode) return this.session.liveVideoId;
        else return this.session.replayVideoId;
      },
      hasExternalLink() {
        return !this.replayMode && this.session.hasExternalLink;
      },
      disclaimer() {
        return (this.session.disclaimer ?? '').length == 0 ? (this.session.isIndustry ? constants.INDUSTRY_DEFAULT_DISCLAIMER : null) : this.session.disclaimer;
      },
    },
    watch: {
      // This function will run everytime data.session change
      session(newSession) {
        if (newSession) {
          const oldFurl = this.$route.query.v;
          const furl = newSession.furl;

          if ((furl && !oldFurl) || (furl && furl !== oldFurl)) this.$router.push({ query: Object.assign({}, this.$route.query, { v: furl }) });
        } else {
          let query = Object.assign({}, this.$route.query);
          delete query.v;
          this.$router.replace({ query: query });
        }
      },
      // This function will run everytime data.isBcPlayerLoaded change
      isBcPlayerLoaded(isLoaded) {
        if (isLoaded && this.waitingForClosing) this.$store.dispatch(CLOSE_MEDIA_MODAL);
      },
    },
    methods: {
      close() {
        if (!this.isLogged || this.isMetadataLoading || this.isBcPlayerLoaded || !this.hasAccess) this.$store.dispatch(CLOSE_MEDIA_MODAL);
        else this.waitingForClosing = true;
      },
      async onShow() {
        // Disabling pooling...
        this.$store.dispatch(DISABLE_POOLING);

        // if (this.isLogged) await checkAccess(undefined, false, window.location, false);
        this.refreshSessionDetails();
      },
      onHide() {
        // Enabling pooling...
        this.$store.dispatch(ENABLE_POOLING);

        // We are reseting modal's state...
        this.resetState();

        // We are clearing jobs...
        // this.clearNextSessionDetailsJob();
      },
      refreshSessionDetails() {
        MediaApiService.getSessionDetails(this.sessionId).then(({ data }) => {
          if (data) {
            this.hasAccess = data?.hasAccess ?? false;
            this.session = data;

            if (!this.hasAccess) this.logAccessDenied();

            const hasExternalLink = data?.hasExternalLink && !data?.isReplay;
            //console.log('🚀 ~ file: MediaModal.vue ~ line 388 ~ MediaApiService.getSessionDetails ~ hasExternalLink', hasExternalLink);
            if (hasExternalLink && this.hasAccess) {
              const externalLink = data?.externalLink;
              window.open(externalLink, '_blank');
              this.close();
            }
            this.isMetadataLoading = false;

            // this.createNextSessionDetailsJob();
          }
        });
      },
      fetchNextSessionDetails() {
        let previousVideoId = this.videoId;
        MediaApiService.getNextSessionDetails(this.sessionId)
          .then(({ data }) => {
            if (data) {
              // We are reseting modal's state...
              this.resetState();

              this.session = data;
              // If video id doesn't change BC player is not reload -> player loaded falg forced to true;
              if (this.videoId == previousVideoId) this.isBcPlayerLoaded = true;
              this.isMetadataLoading = false;
              this.createNextSessionDetailsJob();
            }
          })
          .catch((err) => {
            if (err?.response?.status == 404) {
              // TODO: Display a message instead ?
              this.close();
            }
          });
      },
      onMoreDetailsClick() {
        if (!this.isFirstClickOnMoreDetails) return;

        this.isFirstClickOnMoreDetails = false;

        MediaApiService.getSessionMoreDetails(this.sessionId).then(({ data }) => {
          if (data) {
            this.isMoreDetailsLoading = false;
            this.sessionMoreDetails = data;
          }
        });
      },
      onBrightcovePlayerLoaded() {
        this.isBcPlayerLoaded = true;
      },
      onPigeonWidgetLoaded() {
        // If needed...
      },
      /*
      createNextSessionDetailsJob() {
        if (!this.replayMode) {
          const currentTime = DateHelper.browserDateTime().getTime();
          const endTime = this.session.endTime;

          let nextRefreshTime = this.session.nextRefreshTime;
          if (!nextRefreshTime) nextRefreshTime = endTime;

          let nextRefreshDelay = nextRefreshTime - currentTime; // If you want to test set: 10000;
          //console.log('nextRefreshDelay', nextRefreshDelay);

          //console.log(`end ${this.$options.filters.formatUnixEpocTimePartForLog(endTime)}`);
          console.debug(`%cnextRefresh: ${this.$options.filters.formatUnixEpocTimePartForLog(nextRefreshTime)}`, consoleColors.misc);

          // We are clearing jobs...
          this.clearNextSessionDetailsJob();

          // 🙃 -> https://stackoverflow.com/a/3468650
          if (nextRefreshDelay >= 2147483648)
            nextRefreshDelay = 2147483647;

          this.fetchNextSessionDetailsJob = setTimeout(this.fetchNextSessionDetails, nextRefreshDelay);
        }
      },
      clearNextSessionDetailsJob() {
        if (this.fetchNextSessionDetailsJob) {
          clearTimeout(this.fetchNextSessionDetailsJob);
          this.fetchNextSessionDetailsJob = null;
        }
      },
      */
      resetState() {
        this.hasAccess = false;

        this.isBcPlayerLoaded = false;
        this.waitingForClosing = false;

        this.isMetadataLoading = true;
        this.isMoreDetailsLoading = true;

        this.isFirstClickOnMoreDetails = true;

        this.session = null;
      },
      logAccessDenied() {
        if (this.$appInsights && this.currentUser) {
          const logAccessDeniedProps = {
            escId: this.currentUser?.escId,
            sessionId: this.sessionId,
            videoId: this.videoId,
            replayMode: this.replayMode,
            source: 'MediaModal',
          };
          console.info(`%cAppInsights: logAccessDenied ${JSON.stringify(logAccessDeniedProps)}`, consoleColors.insights);
          this.$appInsights?.trackEvent({ name: 'logAccessDenied', properties: logAccessDeniedProps });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  @import '~@/styles/theme';

  $media-modal-bg-primary: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%),
    radial-gradient(at top center, rgba(255, 255, 255, 0.4) 0%, rgba(0, 0, 0, 0.8) 120%) #989898;
  $media-modal-bg-secondary: #656565;

  $media-modal-primary: $primary;
  $media-modal-secondary: #9e9e9e;

  ::v-deep #media-modal > .modal-dialog {
    height: 100%;
    max-width: 100%;
    overflow-y: auto;
    margin: 0 auto;
  }

  ::v-deep #media-modal {
    padding: 0 !important;
  }

  ::v-deep #media-modal .modal-header {
    border-bottom: none;
    margin-bottom: 25px;

    .close {
      font-size: 2.5rem;
      opacity: 1;
      margin: 0 0 -1.5rem auto;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding: 0;
    }

    .close::after {
      position: absolute;
      top: 72px;
      right: 24px;
      white-space: pre;
      line-height: 1.2;
      font-size: 0.9rem;
      content: 'Close\avideo';
    }
  }

  @include media-breakpoint-down(sm) {
    ::v-deep #media-modal .modal-header {
      padding: 0.3rem 1rem;

      .close {
        font-size: 1.6rem;
        margin: 0 0 0.3rem auto;
        width: 30px;
        height: 30px;
      }

      .close::after {
        top: 42px;
        right: 17px;
        line-height: 1.3;
        font-size: 0.6rem;
      }
    }
  }

  ::v-deep #media-modal .modal-content {
    height: 100%;
    overflow-y: auto;
    background: #fff;
    background-blend-mode: multiply, multiply;
    border: none;
    border-radius: unset;
  }

  @include media-breakpoint-down(sm) {
    ::v-deep #media-modal .modal-body {
      padding: 0;
    }
  }

  .modal-widget {
    height: 100%;
    max-height: 70vh;
  }

  h1 {
    font-size: 1.75rem;
  }

  h5 {
    color: $media-modal-primary;
  }

  .modal__session-subtitle {
    color: $media-modal-primary;
  }

  .modal__session-contact-label {
    display: block;
    color: #d1d1d1;
  }

  .modal__session-contact-link {
    color: $media-modal-primary;
    text-decoration: underline;
  }

  .disclaimer__wrapper {
    display: flex;
    align-items: center;
    color: $media-modal-primary;
  }

  .disclaimer__icon {
    padding-right: 10px;
  }

  .disclaimer__text {
    display: inline-block;
    font-size: 0.9em;
  }

  @include media-breakpoint-down(md) {
    .disclaimer__text {
      text-align: justify;
      font-size: 0.65em;
    }
  }

  .share-wrapper {
    color: $media-modal-primary;
  }

  .share-link {
    position: relative;
    top: -3px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
  }

  .session-duration {
    font-size: 0.85em;
  }

  .card__more-details {
    border: 0;
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: inherit;
    overflow: visible;

    .card-body {
      color: $primary;
      padding: 0;
      padding-top: 2rem;
    }

    .b-skeleton-img {
      margin-bottom: 10px;
    }
  }

  .card-text__speaker {
    display: block;
  }

  .session__details__presentation {
    background: $secondary;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    padding: 0.6rem 0.8rem;
    border-radius: 6px;
    margin-bottom: 1rem;

    &__title {
      display: block;
    }

    &__person {
      display: block;
    }

    &__start-time {
      display: block;
      color: var(--congress);
      font-size: 0.9rem;
    }

    img {
      vertical-align: baseline;
    }
  }

  @include media-breakpoint-down(md) {
    .card__presentation {
      max-width: 100%;
    }

    .btn-collapse {
      display: block;
      width: 100%;
    }
  }

  .btn__back {
    top: 1rem;
    left: 1rem;
  }

  @include media-breakpoint-down(sm) {
    .btn__back {
      position: relative !important;
      top: 0.5rem;
      left: 0;
      padding-left: 1.5rem;
    }
  }

  .btn-collapse {
    color: $primary;
  }
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }

  :not(.collapsed).btn-collapse {
    border-radius: 0.25em 0.25em 0 0;
  }

  .session__details__content__flags {
    font-size: 0.9rem;

    &__inner {
      margin-right: 1.25rem;
      .material-icons-round,
      .material-icons-outlined,
      .material-symbols-rounded {
        font-size: 22px;
      }
    }

    &__inner:last-of-type {
      margin-right: 0;
    }

    &__inner:not(:first-child) {
      border-top: solid 1px white;
    }
  }
</style>
