var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('fragment',[(_vm.enableNotifications)?_c('IndustryToastManager'):_vm._e(),(_vm.enableNotifications)?_c('NewVersionToast',{attrs:{"id":"new-version-toast"}}):_vm._e(),(_vm.showHeader)?_c('div',{staticClass:"sticky-top"},[_c('Navbar')],1):_vm._e(),_c('b-container',{staticClass:"main-container px-0",class:{
      'pr-0': _vm.isLiveMode && !_vm.isPageWithoutTimeline,
      'pr-0': !_vm.isLiveMode && !_vm.isPageWithoutTimeline,
      'pr-3': _vm.isPageWithoutTimeline,
      'pl-0': !_vm.isPageWithoutTimeline,
      'pl-3': _vm.isPageWithoutTimeline,
    },attrs:{"fluid":""}},[_c('b-row',{class:{ 'm-0': _vm.preCongressMode || _vm.postCongressMode },attrs:{"no-gutters":"","align-h":!_vm.preCongressMode && !_vm.postCongressMode && !_vm.isPageWithoutTimeline ? 'center' : 'center'}},[(!_vm.isPageWithoutTimeline && !_vm.preCongressMode && !_vm.postCongressMode)?_c('b-col',{staticClass:"d-none",attrs:{"cols":"12"}},[_c('div',{staticClass:"timeline-button",attrs:{"data-expanded":_vm.timelineExpandedData},on:{"click":_vm.toggleTimeLine}},[_c('i',{staticClass:"las la-2x la-t-plus-6 la-calendar-alt"})])]):_vm._e(),(_vm.isMounted)?_c('router-view',{key:_vm.generateRouterKey()}):_vm._e()],1)],1),(_vm.isLogged && _vm.hasNetworkingAccess && !_vm.isNetworkingMessagePage && _vm.isNetworkingEnabled)?_c('NetworkingChat',{attrs:{"hidden":true}}):_vm._e(),_c('ChannelModal'),_c('MediaModal'),(_vm.showFooter)?_c('Footer'):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }