<template>
  <div class="d-none"></div>
</template>

<script>
  import { mapState } from 'vuex';
  import { DateTime } from 'luxon';
  import { getStoryBlokClient } from 'src/services/storyblok';
  import { constants } from 'src/constants';
  import { DateHelper } from 'src/utils/date-helper';
  export default {
    name: 'NewVersionToast',
    props: {
      id: String
    },
    data() {
      return {
        delay: 60 * 1000, // 1min in msec
        history: {}
      };
    },
    computed: {
      ...mapState({
        confCmsData: state => state.app.confCmsData
      })
    },
    mounted() {
      // Loading notifications history for this device...
      var localHistory = JSON.parse(window.localStorage.getItem(constants.LOCAL_NOTIFS_HISTORY_KEY));
      if (localHistory && localHistory !== null) this.history = localHistory;

      // Check notifications at the next minute...
      const now = DateHelper.browserDateTime();
      const start = this.delay - now.getSeconds() * 1000 + now.getMilliseconds();
      if (start >= 6000) {
        // Check notifications now...
        setTimeout(this.checkNotifications, 2000);
      }

      setTimeout(this.checkNotifications, start);
    },
    methods: {
      checkNotifications() {
        if (!this.confCmsData && !this.confCmsData?.notifs_blocks) return;

        const now = DateTime.utc();
        const notifications =
          this.confCmsData?.notifs_blocks?.filter(
            x =>
              DateTime.fromFormat(x.notif_start, 'yyyy-MM-dd HH:mm', { zone: 'utc' }) <= now &&
              (!x.notif_end || DateTime.fromFormat(x.notif_end, 'yyyy-MM-dd HH:mm', { zone: 'utc' }) >= now)
          ) ?? [];

        //console.log('Now -- ' + now);
        for (const notification of notifications) {
          /*
          //console.log('Notif -- ' + notification.notif_label);
          const notifStart = DateTime.fromFormat(notification.notif_start, 'yyyy-MM-dd HH:mm', { zone: 'utc' });
          //console.log('NotifStart ' + notifStart);
          //console.log('IsNotifStart_After_Now', notifStart <= now);
          const notifEnd = DateTime.fromFormat(notification.notif_end, 'yyyy-MM-dd HH:mm', { zone: 'utc' });
          //console.log('NotifEnd ' + notifEnd);
          //console.log('IsNotifEnd_Before_Now', notifEnd >= now);*/

          if (!this.history[notification._uid]) this.createIndustryToast(notification);
        }

        setTimeout(this.checkNotifications, this.delay);
      },
      createIndustryToast(notification) {
        const el = this.$createElement;
        const toastBodyNodes = [
          el('div', { class: ['toast-body-text', 'text-left'] }, [
            el('div', {
              class: ['no-margin'],
              domProps: {
                innerHTML: `<i class="las la-info-circle la-t-plus-5 la-8x"></i> ${getStoryBlokClient().richTextResolver.render(notification.notif_text)}`
              }
            })
          ]),
          el(
            'a',
            {
              props: { variant: 'congress' },
              domProps: { href: notification?.notif_href?.url ?? '#', target: notification?.notif_target },
              class: ['btn', 'btn-sm', 'btn-congress', 'mt-2', 'float-right']
            },
            ['See details']
          )
        ];

        this.$bvToast.toast([toastBodyNodes], {
          toaster: 'b-toaster-bottom-right',
          noAutoHide: false,
          noHoverPause: false,
          autoHideDelay: 300000,
          variant: 'congress-industry',
          bodyClass: 'overflow-auto'
        });

        this.flagNotification(notification._uid);
      },
      flagNotification(notificationId) {
        this.history[notificationId] = true;
        try {
          window.localStorage.setItem(constants.LOCAL_NOTIFS_HISTORY_KEY, JSON.stringify(this.history));
        } catch (e) {
          return;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep .no-margin p {
    margin: 0 !important;
  }

  ::v-deep .no-margin p:first-of-type {
    display: inline;
  }

  ::v-deep hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .toast-title-text {
    font-weight: bold;
    font-size: 1.1rem;
  }

  .b-toast-congress-industry {
    border-radius: 0.5rem;
  }

  ::v-deep .toast-header {
    display: block;
    position: absolute;
    right: 0px;
    top: 1px;
    background-color: unset;
    background-clip: unset;
    border: unset;
  }

  ::v-deep .toast-body {
    overflow: auto;
  }

  .b-toast {
    max-width: unset !important;
  }

  ::v-deep .toast {
    flex-basis: unset !important;
    max-width: unset !important;
    background-color: var(--congress-lighter) !important;
    border-inline-start-width: 6px;
    border-inline-start-color: var(--congress);
    border-radius: 0.5rem;
  }
</style>
