<template>
  <fragment>
    <!-- 
    ========================================================================================================================================================================
    HERO BANNER
    ========================================================================================================================================================================
     -->
    <b-row class="hero__banner">
      <b-col v-if="!isMobileAppPage && !isHomePage" cols="12" class="hero__bg px-3 px-md-0 pt-5 px-0 d-flex justify-content-left"
        :style="{ backgroundImage: `url('/congress/img/events/${eventId}/hero-bg.png?v=${this.appVersion}')` }">
        <div class="eventInfo">
          <h1>Abstracts</h1>
          <div class="eventDate mt-3">Discover original science, new cardiovascular research and findings for your clinical practice.</div>
          <div class="eventDate">
            Please note that the programme times are displayed on local times in {{ locationEvent }}.
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- 
    ========================================================================================================================================================================
    END HERO BANNER
    ========================================================================================================================================================================
     -->
     <b-container fluid class="px-5 mb-5" :class="isTimelineExpanded ? 'd-block d-md-none' : 'd-block'">
      <b-row>
        <!-- ESC Banner(s) -->
        <b-col cols="10" md="12" offset="2" offset-md="0">
          <EscBanners />
        </b-col>

        <!-- OverviewTabs -->
        <b-col cols="12" :lg="hasBoostedTerms || hasTrending || hasPopularity ? 9 : 12" class="pt-3 abstracts__search">
          <Search :searchDocType="'Abstract'"/>
        </b-col>

        <b-col cols="12" class="pt-3">
          <!-- Register Promo -->
          <RegisterPromoWidget />
        </b-col>

        <!-- IndustryBanners Banner(s) -->
        <b-col cols="10" md="12" offset="2" offset-md="0" class="pt-3">
          <IndustryBanners />
        </b-col>
      </b-row>

    </b-container>
  </fragment>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import openModalMixin from 'src/mixins/openModalMixin';
import toursManagerMixin from 'src/mixins/toursManagerMixin';
import { constants } from 'src/constants';
import EscBanners from 'src/components/Banners/EscBanners';
import IndustryBanners from 'src/components/Banners/IndustryBanners';
import RegisterPromoWidget from 'src/components/Widgets/RegisterPromoWidget';
import Search from 'src/components/Search/Search';
export default {
  name: 'Overview',
  mixins: [openModalMixin, toursManagerMixin],
  components: {
    EscBanners,
    IndustryBanners,
    Search,
    RegisterPromoWidget,
  },
  data() {
    return {
      userRecos: [],
      trendingRecos: [],
      popularRecos: [],
      hasTrending: false,
      hasBoostedTerms: false,
      hasPopularity: false,
      selectedRecommendationType: null,
      selectedContentType: null,
      recommendationData: null,
    };
  },
  computed: {
    ...mapState({
      eventId: (state) => state.app.eventId, eventRoute: (state) => state.app.eventRoute,
      appVersion: (state) => state.app.version,
      locationEvent: (state) => state.app.locationEvent,
    }),
    ...mapGetters(['isTimelineExpanded', 'isLogged', 'currentUser', 'appVersion']),

    isMobileAppPage() {
      const pagesWithoutTimeline = [
        `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_PRE_HOME}`,
        `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_HAPPENING_NOW}`,
        `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_INTERACTIVITY}`,
      ];
      return pagesWithoutTimeline.map((x) => `/${this.eventRoute}/${x}`).includes(this.$route.path);
    },

    isHomePage() {
      return this.$route.path === `/${this.eventRoute}` || this.$route.path === `/${this.eventRoute}/` || this.$route.path === `/${this.eventRoute}/home`;
    },
  },
  async mounted() {},
  methods: {
    hasUserRecos() {
      return this.recommendationData.BoostedTerms && this.recommendationData.BoostedTerms.Sessions && this.recommendationData.BoostedTerms.Sessions.length > 0;
    },
    hasTrendingRecos() {
      return this.recommendationData.Trending && this.recommendationData.Trending.Sessions && this.recommendationData.Trending.Sessions.length > 0;
    },
    hasPopularRecos() {
      return this.recommendationData.Popularity && this.recommendationData.Popularity.Sessions && this.recommendationData.Popularity.Sessions.length > 0;
    },

    filterRecos(recos) {
      return recos.filter((obj, pos, arr) => {
        return obj['content'] !== null && obj['content'] !== '' && arr.map((mapObj) => mapObj['content']).indexOf(obj['content']) === pos;
      });
    },
    sanitizeId(rawId) {
      if (!rawId) return '#';
      return rawId.substring(1, rawId.length);
    },
    extractId(rawcontent) {
      if (!rawcontent) return '#';
      return parseFloat(rawcontent);
    },
    extractTitle(rawcontent) {
      if (!rawcontent) return '#';
      return rawcontent.replace(parseFloat(rawcontent), '');
    },
    getuuid(uuid) {
      let uid = '';
      if (uuid.length > 0) {
        uid = uuid[uuid.length - 1];
      } else {
        uid = uuid[0];
      }
      return uid;
    },
    getuuidTrending(uuid) {
      let uid = '';
      if (uuid.length > 0) {
        uid = uuid[1];
      } else {
        uid = uuid[0];
      }
      return uid;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/theme';

@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.v-step__header {
  background: #000;
}

.v-step {
  z-index: 1;
}

/*TEST RECOMMENDATION CARD*/
.card {
  border: 0;
}

.card-body {
  padding: 0.75rem !important;
}

.card-body a {
  display: flex;
}

.card-body a:hover {
  text-decoration: none;
}

.card-text {
  margin-bottom: 0;
  font-size: 0.9em;
  line-height: 1.25;
}

.search__card__footer__action i {
  align-self: center;
}

::v-deep .nav-pills .nav-link {
  font-size: 0.83em;
}

::v-deep .card-header {
  border-bottom: 0;
  background: transparent;
}

::v-deep .tab-pane {
  padding: 0 !important;
}
</style>