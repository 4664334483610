<template>
  <fragment>
    <div class="view__filter d-flex justify-content-end mb-3" v-if="searchDocType != 'Speaker' && searchDocType != 'Abstract'" data-v-step-search="3">
      <b-button-group>
        <b-button variant="outline-dark" @click="showCardsComponent" :class="`left-radius ${buttonStyle('showCards')}`"
          ><span class="material-icons-outlined align-middle mr-1"> calendar_view_month </span> <span class="align-middle">Cards</span>
        </b-button>
        <b-button variant="outline-dark" @click="showListComponent" :class="`${buttonStyle('showList')}`"
          ><span class="material-icons-outlined align-middle mr-1"> view_agenda </span> <span class="align-middle">List</span>
        </b-button>
        <b-button variant="outline-dark" @click="showCalendarComponent" :class="`right-radius ${buttonStyle('showCalendar')}`"
          ><span class="material-icons-outlined align-middle mr-1"> view_timeline </span> <span class="align-middle">Calendar</span>
        </b-button>
      </b-button-group>
    </div>

    <div id="topStickyDays" class="timeLine px-lg-3" v-if="searchDocType != 'Speaker' && searchDocType != 'Abstract'" data-v-step-search="2">
      <b-row class="p-2 mb-4 font-black">
        <b-col
          :key="eventDay.toString()"
          v-for="(eventDay, index) in eventDays"
          class="date__text"
          :class="{ selected: isSelectedDay(eventDay), disabled: getDayDocCount(eventDay) == 0 }"
          :data-v-step-schedule="index == 0 && ($screen.lg || $screen.xl || $screen.xxl) ? 0 : null"
        >
          <span @click="onDayClick(eventDay, $event)" class="d-block"
            >{{ eventDay | formatUnixEpocDayPart }} <small>({{ getDayDocCount(eventDay) }})</small></span
          >
        </b-col>
      </b-row>
    </div>

    <SearchResultsCards
      v-if="showCards && results"
      :key="componentKey"
      ref="resultsBlock"
      :resultType="resultType"
      :results="results"
      :query="query"
      :serverTime="serverTime"
      :daySelected="daySelected"
      :searchDocType="searchDocType"
      @is-loading="OnChildLoading"
      :docType="docType"
    />

    <template v-if="showList && results">
      <div ref="timelineContainer">
        <div v-for="(time, index) in sortedTimes" :key="time">
          <b-row no-gutters>
            <b-col cols="12" sm="12" md="1" lg="1" xl="1" class="timeline-time pl-xl-4 font-bold">
              <div
                :id="time === closestTime ? 'scrollTarget' : `time${index}`"
                :class="{ sticky__lg: true, scrollTarget: time === closestTime }"
                :ref="time === closestTime ? 'scrollTarget' : ''"
              >
                {{ time }}
              </div>
            </b-col>
            <b-col v-if="false" cols="1" sm="1" md="1" lg="" xl="" class="timeline-line"> </b-col>
            <b-col cols="12" sm="12" md="10" lg="11" xl="11" class="px-0 pl-lg-5">
              <b-container v-for="result in groupedResults[time]" :key="result.dbId" fluid class="mb-3">
                <SearchResultsTimeline :result="result" :serverTime="serverTime" :daySelected="daySelected" @is-loading="OnChildLoading" :docType="docType" />
              </b-container>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>

    <SearchResultsCalendar
      v-if="showCalendar && results && channels"
      :key="componentKey"
      ref="resultsBlock"
      :resultType="resultType"
      :expanded="true"
      :toggable="false"
      :daySelected="daySelected"
      :results="results"
      @is-loading="OnChildLoading"
      :docType="docType"
      :channels="channels"
      :serverTime="serverTime"
    />
  </fragment>
</template>

<script>
  // import { mapGetters } from 'vuex';
  import SearchResultsCards from 'src/components/Search/SearchResultsCards';
  import SearchResultsTimeline from 'src/components/Search/SearchResultsTimeline';
  import SearchResultsCalendar from 'src/components/Search/SearchResultsCalendar';
  import { mapGetters, mapState } from 'vuex';
  import { DateHelper } from 'src/utils/date-helper';

  export default {
    name: 'SearchResultsBlock',
    components: {
      SearchResultsCards,
      SearchResultsCalendar,
      SearchResultsTimeline,
    },
    props: {
      componentKey: Number,
      resultsBlock: String,
      resultType: String,
      results: Array,
      query: String,
      serverTime: Number,
      searchDocType: String,
      docType: String,
      eventDays: Array,
      resultsFacets: Object,
      showCards: {
        type: Boolean,
        default: false,
      },
      showList: {
        type: Boolean,
        default: false,
      },
      showCalendar: {
        type: Boolean,
        default: false,
      },
      daySelected: {
        type: Date,
        default: null,
      },
      channels: Array,
    },
    data() {
      return {
        abstractModalPresentationId: null,
        abstractModalVisible: false,
        closestTime: null,
      };
    },
    computed: {
      ...mapGetters(['eventStartDate', 'eventEndDate']),
      ...mapState({
        eventId: (state) => state.app.eventId,
        eventStartDate: (state) => state.app.eventStartDate,
        eventEndDate: (state) => state.app.eventEndDate,
      }),
      groupedResults() {
        return this.results?.reduce((acc, result) => {
          const time = result.source.startTimeUnixEpoc ? this.formatUnixEpocTimePart(result.source.startTimeUnixEpoc) : 'On Demand';
          if (!acc[time]) {
            acc[time] = [];
          }
          acc[time].push(result);
          return acc;
        }, {});
      },
      sortedTimes() {
        if (this.groupedResults) return Object.keys(this.groupedResults).sort((a, b) => new Date(`1970/01/01 ${a}`) - new Date(`1970/01/01 ${b}`));
        else return 0;
      },
      isMobile() {
        return this.$screen.breakpoint === 'xs' || this.$screen.breakpoint === 'sm' || this.$screen.breakpoint === 'md';
      },
    },
    /*
    watch: {
      results: {
        immediate: true,
        handler() {
          this.$nextTick(() => {
            this.scrollToClosestTime();
          });
        },
      },
    },
    */
    mounted() {},
    methods: {
      showCardsComponent() {
        const onShowChangeArg = { showCards: true, showList: false, showCalendar: false };
        this.$emit('onShowChange', onShowChangeArg);
      },
      showListComponent() {
        const onShowChangeArg = { showCards: false, showList: true, showCalendar: false };
        this.$emit('onShowChange', onShowChangeArg);
        /*
        this.$nextTick(() => {
          this.scrollToClosestTime();
        });
        */
      },
      showCalendarComponent() {
        const onShowChangeArg = { showCards: false, showList: false, showCalendar: true };
        this.$emit('onShowChange', onShowChangeArg);
      },
      buttonStyle(button) {
        switch (button) {
          case 'showCards':
            return this.showCards ? 'btn-active' : 'btn-inactive';
          case 'showList':
            return this.showList ? 'btn-active' : 'btn-inactive';
          case 'showCalendar':
            return this.showCalendar ? 'btn-active' : 'btn-inactive';
        }
      },
      isSelectedDay(day) {
        return this.$options.filters.formatUnixEpocDayPart(day) === this.$options.filters.formatUnixEpocDayPart(this.daySelected);
      },
      moveToDayByDay(day) {
        this.$emit('select-day', day);
      },
      onDayClick(date, e) {
        if (this.getDayDocCount(date) == 0) e.preventDefault();
        else if (!this.isSelectedDay(date)) this.moveToDayByDay(date);
      },
      formatUnixEpocDayPart(timestamp) {
        // const date = new Date(timestamp * 1000);
        //console.log(date.toLocaleDateString())

        // const date = Vue.filter('formatUnixEpocDayPart', (timestamp))
        const date = this.$options.filters.formatUnixEpocDayPart(timestamp);

        return date;
      },
      formatUnixEpocTimePart(timestamp) {
        const date = this.$options.filters.formatUnixEpocTimePart(timestamp);

        return date;
      },
      getDayDocCount(day) {
        return this.resultsFacets?.days?.find((x) => DateHelper.onSiteDateTime(x.value).getDate() == day.getDate())?.text ?? 0;
      },
      OnChildLoading(isLoading) {
        //console.log('OnChildLoading', isLoading);
        this.$emit('is-loading', isLoading);
      },
      scrollToClosestTime() {
        //const serverTime = this.serverTime ? DateHelper.onSiteDateTime(this.serverTime) : null;
        if (
          this.$options.filters.formatUnixEpocDayPart(this.serverTime) === this.$options.filters.formatUnixEpocDayPart(this.daySelected) &&
          this.serverTime >= this.eventStartDate &&
          this.serverTime <= this.eventEndDate
        ) {
          this.closestTime = this.getClosestTime(this.$options.filters.formatUnixEpocTimePart(this.serverTime));
          if (this.closestTime) {
            this.$nextTick(() => {
              //scroll target element into view (daysBarOffset: only on not mobile as it is not needed on mobile)
              const element = document.getElementById('scrollTarget');
              if (element) {
                element.scrollIntoViewExtended({
                  behavior: 'smooth',
                  block: 'start',
                  baseOffset: this.showCalendar ? 100 : 20,
                  navOffset: true,
                  daysBarOffset: !this.isMobile,
                });
              }
            });
          }
        }
      },
      getClosestTime(time) {
        //return this.sortedTimes.find((sortedTime) => sortedTime >= time) || this.sortedTimes[this.sortedTimes.length - 1];
        //console.log('time', time);
        const lesserTimes = this.sortedTimes.filter((sortedTime) => sortedTime <= time);
        //console.log('lesserTimes', JSON.stringify(lesserTimes));
        return lesserTimes && lesserTimes.length > 0 ? lesserTimes[lesserTimes.length - 1] : this.sortedTimes[0];
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  .timeline-line {
    width: 100px;
    border-right: 3px solid var(--congress);
  }

  .timeline-time {
    color: var(--congress);
    border-right: 3px solid var(--congress);
  }

  .timeline-time:after {
    display: block;
    position: absolute;
    -webkit-box-shadow: 0 0 0 5px #fff;
    box-shadow: 0 0 0 5px #fff;
    right: -0.5rem;
    background: var(--congress);
    border-radius: 50%;
    height: 14px;
    width: 14px;
    content: '';
    top: 5px;
  }

  .date__text {
    text-align: center;
    cursor: pointer;
  }

  .date__text.selected {
    color: var(--congress);
  }

  .date__text span:hover {
    color: var(--congress);
  }

  .date__text.disabled {
    opacity: 0.5;
    cursor: default;
  }
  .date__text.disabled span:hover {
    color: #343a40;
  }

  .left-radius {
    border-top-left-radius: 55px;
    border-bottom-left-radius: 55px;
  }

  .right-radius {
    border-top-right-radius: 55px;
    border-bottom-right-radius: 55px;
  }

  .btn-outline-dark:hover {
    background-color: lightgray;
  }

  .btn-active {
    color: var(--congress);
  }

  @media (max-width: 576px) {
    .timeLine {
      color: $primary;
      background-color: transparent;
      font-size: 0.9em;
      .date__text {
        padding: 0;
      }
    }
  }

  @media (max-width: 992px) {
    .timeline-time {
      border-right: 0;
    }
    .timeline-time:after {
      display: none;
    }
  }

  @media (min-width: 992px) {
    .timeline-time .sticky__lg {
      position: sticky;
      top: 120px;
    }
    .date__text {
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -ms-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
    .date__text.selected {
      background: var(--congress);
      color: #fff;
      border-radius: 50rem;
    }
    .date__text:hover {
      background: #333;
      border-radius: 50rem;
      color: #fff;
    }
    .date__text span:hover {
      color: #fff;
    }
  }

  @media (min-width: 1024px) {
    .timeLine {
      position: sticky;
      top: 60px;
      -webkit-box-shadow: inset 0px 0px 0px 1px #ccc;
      -moz-box-shadow: inset 0px 0px 0px 1px #ccc;
      box-shadow: inset 0px 0px 0px 1px #ccc;
      z-index: 101;
    }
  }
</style>
