<template>
<b-container fluid class="mb-5 px-5">
    <b-card-group deck class="eventCards">
      <b-card
        v-for="sliderBlock in homeCmsData.slider_blocks"
        :key="sliderBlock._uid"
        :title="sliderBlock.slider_title"
        :img-src="sliderBlock.slider_image.filename"
        img-alt="Image"
        img-top
        tag="article"
        class="mb-3"
      >
        <b-card-text v-html="$options.filters.formatStoryRichText(sliderBlock.slider_text)"> </b-card-text>
        <template #footer>
          <b-button
            pill
            class="stretched-link"
            variant="congress"
            :to="sliderBlock.slider_button_link[0].link_internal ? sliderBlock.slider_button_link[0].link_value : null"
            :href="!sliderBlock.slider_button_link[0].link_internal ? sliderBlock.slider_button_link[0].link_value : null"
            :target="!sliderBlock.slider_button_link[0].link_internal && sliderBlock.slider_button_link[0].link_new_tab ? '_blank' : '_self'"
          >
            {{ sliderBlock.slider_button_link[0].link_text }}
          </b-button>
        </template>
      </b-card>
    </b-card-group>
  </b-container>
  </template>

  <script>
  import { mapState } from 'vuex';
  export default {
    name: 'AuthoredSlidesBlock',
    computed: {
    ...mapState({
      homeCmsData: (state) => state.app.homeCmsData,

    }),
  },
}
</script>