<template>
  <fragment v-if="session.votingUrl">
    <IframeModal :url="votingUrl" :title="votingTitle" :visible="showModal" @iframe-modal-close="onClose()" />
    <!-- Render as button -->
    <b-button
      variant="congress"
      v-if="renderAs && renderAs === 'button'"
      class="btn-interactivity card-link text-white d-flex justify-content-center align-items-center w-100 h-100"
      @click="onVoteClick"
    >
      <!-- Vote -->
      Vote
    </b-button>

    <!-- Render as link -->
    <b-link v-else class="card-link d-flex justify-content-center align-items-center w-100 h-100" @click="onVoteClick">
      <!-- Vote -->
      <template> Vote </template>
    </b-link>
  </fragment>
</template>

<script>
  //import { SET_IS_LOADING } from 'src/store/mutations';
  import IframeModal from 'src/components/Modals/IframeModal';
  export default {
    name: 'SessionVoteButton',
    props: {
      session: Object,
      forceInteract: Boolean,
      renderAs: {
        type: String,
        required: false,
        default: 'link',
        validator: function (value) {
          // The value must match one of these strings
          return value === null || ['button', 'link'].indexOf(value) !== -1;
        },
      },
    },
    data() {
      return {
        isLoading: false,
        votingUrl: null,
        votingTitle: null,
        showModal: false,
      };
    },
    components: {
      IframeModal,
    },
    computed: {},
    methods: {
      onVoteClick() {
        this.votingUrl = this.session.votingUrl;
        this.votingTitle = this.session.title;
        this.showModal = true;
        //this.$store.commit(SET_IS_LOADING, true);
      },
      onClose() {
        this.showModal = false;
        this.votingUrl = null;
        this.votingTitle = '';
      },
    },
  };
</script>

<style lang="scss" scoped>
.btn-interactivity
{
  padding: 0.5rem;
}
  </style>