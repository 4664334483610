<template>
  <b-container fluid>
    <div class="btn__back mb-2 position-sticky float-lg-left">
      <b-button pill variant="clear" size="sm" class="font-regular mt-3 d-inline d-inline-flex align-items-center border-0" @click="onBackClick">
        <span class="material-icons-round mr-2"> arrow_back </span>
        Back
      </b-button>
    </div>

    <b-col cols="12" sm="12" md="8" lg="9" class="toggable-main-content m-auto" :class="isTimelineExpanded ? 'd-none' : 'd-block'">
      <b-row>
        <!-- ESC Banner(s) -->
        <b-col cols="10" md="12" offset="2" offset-md="0">
          <EscBanners />
        </b-col>

        <!-- SessionDetails -->
        <b-col cols="12" class="my-4">
          <div class="session__details__wrapper">
            <div class="session__details__content">
              <b-row class="no-gutters">
                <b-col cols="12">
                  <h3 class="session__details__content__title">
                    <!-- Title -->
                    <template v-if="isMoreDetailsLoading">
                      <b-skeleton height="30px" width="85%"></b-skeleton>
                    </template>

                    <template v-if="!isMoreDetailsLoading && sessionMoreDetails.title">
                      {{ sessionMoreDetails.title }}
                    </template>
                  </h3>
                </b-col>
                <b-col cols="12" xl="3" xxl="1" v-if="sessionMoreDetails && sessionMoreDetails.categories && sessionMoreDetails.categories.length > 0">
                  <span class="pl-xl-2 m-auto">
                    <span v-for="(categorie, index) in sessionMoreDetails.categories" :key="index">
                      <img
                        v-if="categorieImage(categorie)"
                        :src="categorieImage(categorie)"
                        :alt="categorie.description"
                        class="session__details__categorie_img img-fluid"
                      />
                    </span>
                  </span>
                </b-col>
              </b-row>
              <h5 class="mb-3 font-light" v-if="sessionMoreDetails && (sessionMoreDetails.subTitle || sessionMoreDetails.industryLink || sessionMoreDetails.tutorialLink)">
                <!-- SubTitle -->
                <template v-if="isMoreDetailsLoading">
                  <b-skeleton height="30px" width="85%"></b-skeleton>
                </template>

                <template v-if="!isMoreDetailsLoading && sessionMoreDetails.subTitle">
                  {{ sessionMoreDetails.subTitle }}
                </template>

                <!-- Industry link -->
                <template v-if="!isMoreDetailsLoading && !preCongressMode && sessionMoreDetails.industryLink">
                  <a class="industry-link" :href="sessionMoreDetails.industryLink" target="_blank">
                    <b-button variant="clear" size="sm" class="font-weight-bold">
                      <i class="las la-3x la-t-plus-2 la la-external-link mr-1"></i>
                      Exhibitor Profile
                    </b-button>
                  </a>
                </template>

                <!-- Tutorial register link -->
                <template v-if="!isMoreDetailsLoading && !preCongressMode && sessionMoreDetails.tutorialLink">
                  <a class="industry-link" :href="sessionMoreDetails.tutorialLink" target="_blank">
                    <b-button variant="clear" size="sm" class="font-weight-bold">
                      <i class="las la-3x la-t-plus-2 la la-external-link mr-1"></i>
                      Register
                    </b-button>
                  </a>
                </template>
              </h5>

              <b-row class="no-gutters">
                <b-col cols="12" class="mb-3">
                  <template v-if="isMoreDetailsLoading">
                    <div class="b-custom-skeleton-wrapper">
                      <b-skeleton-img no-aspect height="100%" width="65%"></b-skeleton-img>
                    </div>
                  </template>

                  <template v-if="!isMoreDetailsLoading">
                    <div class="pt-1 pb-4" v-if="sessionMoreDetails.isLive || sessionMoreDetails.isInInterlude || sessionMoreDetails.isReplay">
                      <SessionButton
                        v-if="sessionMoreDetails.isLive || sessionMoreDetails.isInInterlude || sessionMoreDetails.isReplay"
                        renderAs="button"
                        :session="sessionMoreDetails"
                        :forceInteract="sessionMoreDetails.isReplay"
                        class="session__card__footer__action"
                      />
                      <b-progress
                        v-if="sessionMoreDetails.isLive"
                        class="mt-3 session__progress"
                        :value="sessionMoreDetails.progress"
                        :max="sessionMoreDetails.duration"
                        height="0.6em"
                      ></b-progress>
                    </div>
                    <div class="session__details__content__flags d-block font-regular">
                      <!-- Date + Start Time + End Time -->
                      <div
                        class="session__details__content__flags__inner d-inline-flex align-items-center"
                        v-if="!sessionMoreDetails.isOnDemand && sessionMoreDetails.startTime"
                      >
                        <span class="mr-1 material-icons-round"> calendar_month </span>
                        <span class="flags__label"></span>
                        {{ sessionMoreDetails.startTime | formatUnixEpocDayPart }} from {{ sessionMoreDetails.startTime | formatUnixEpocTimePart }} to
                        {{ sessionMoreDetails.endTime | formatUnixEpocTimePart }}
                      </div>
                      <!-- Location -->
                      <div
                        class="session__details__content__flags__inner d-inline-flex align-items-center"
                        v-if="!showChannelOnline && !sessionMoreDetails.isOnDemand && sessionMoreDetails.channelOnSiteDisplayName"
                      >
                        <span class="mr-1 material-icons-outlined"> place </span>
                        {{ sessionMoreDetails.channelOnSiteDisplayName }}
                      </div>
                      <!-- is Online -->
                      <div
                        class="session__details__content__flags__inner d-inline-flex align-items-center"
                        v-if="showChannelOnline && !sessionMoreDetails.isOnDemand && sessionMoreDetails.channel"
                      >
                        <span class="mr-1 material-icons-round"> cast </span>
                        <span class="flags__label">Online </span>
                      </div>
                      <!-- Session type -->
                      <div
                        class="session__details__content__flags__inner d-inline-flex align-items-center"
                        v-if="sessionMoreDetails.sessionType && sessionMoreDetails.sessionType.trim() !== ''"
                      >
                        <span class="mr-1 material-icons-outlined"> co_present </span>
                        {{ sessionMoreDetails.sessionType }}
                      </div>
                      <!-- Topic -->
                      <div
                        class="session__details__content__flags__inner d-inline-flex align-items-center"
                        v-if="sessionMoreDetails.topic && sessionMoreDetails.topic.trim() !== ''"
                      >
                        <span class="mr-1 material-symbols-rounded"> ecg_heart </span>
                        {{ sessionMoreDetails.topic }}
                      </div>

                      <!-- <div class="session__details__content__flags__inner" v-if="!sessionMoreDetails.isOnDemand && sessionMoreDetails.startTime">
                        <i class="las la-6x la-t-plus-2 la-clock mr-1"></i>
                        <span class="flags__label">Start time: </span>
                        {{ sessionMoreDetails.startTime | formatUnixEpocTimePart }}
                      </div>
                      <div class="session__details__content__flags__inner" v-if="!sessionMoreDetails.isOnDemand && sessionMoreDetails.endTime">
                        <i class="las la-6x la-t-plus-2 la-hourglass-end mr-1"></i>
                        <span class="flags__label">End time: </span>
                        {{ sessionMoreDetails.endTime | formatUnixEpocTimePart }}
                      </div> -->
                    </div>
                  </template>
                </b-col>
                <!-- WATCH & Progress -->
                <!--
              <b-col v-if="!isMoreDetailsLoading" cols="6" class="mb-3">
                <div class="ml-3">
                  <SessionButton v-if="sessionMoreDetails.isLive || sessionMoreDetails.isReplay" renderAs="button" :session="sessionMoreDetails" :forceInteract="sessionMoreDetails.isReplay" class="session__card__footer__action" />
                  <b-progress v-if="sessionMoreDetails.isLive" class="mt-3 session__progress" :value="sessionMoreDetails.progress" :max="sessionMoreDetails.duration" height="0.6em"></b-progress>
                </div>
              </b-col>
              -->
              </b-row>

              <b-row class="no-gutters">
                <b-col cols="12" class="mt-2 mb-5">
                  <div class="session__details__header__button">
                    <FavoriteButton
                      v-if="sessionMoreDetails"
                      :title="sessionMoreDetails.title"
                      :digitalSessionId="sessionId"
                      :scientificSessionId="sessionMoreDetails.scientificSessionId"
                      mode="large"
                      width="18"
                      height="18"
                    />
                  </div>
                </b-col>
              </b-row>

            <b-row>
              <b-col v-if="sessionMoreDetails && sessionMoreDetails.disclaimer && sessionMoreDetails.disclaimer.trim() !== ''" cols="12" class="mt-3 mb-3 px-0">
                <div class="disclaimer__wrapper">
                  <b-alert show variant="light">
                    <span class="font-bold">Disclaimer: </span>
                    <span class="disclaimer__text" v-html="sessionMoreDetails.disclaimer"></span>
                  </b-alert>
                </div>
              </b-col>
            </b-row>
              <b-row
                v-if="
                  isMoreDetailsLoading ||
                  sessionMoreDetails.chairpersons.length > 0 ||
                  sessionMoreDetails.learningObjectives.trim() !== '' ||
                  sessionMoreDetails.moreDetailsLink
                "
                class="no-gutters"
              >
                <b-col cols="12" class="mb-4">
                  <!-- Chairpersons -->
                  <template v-if="isMoreDetailsLoading">
                    <div class="mb-4">
                      <b-skeleton width="30%" class="mb-2"></b-skeleton>
                      <b-skeleton width="50%"></b-skeleton>
                    </div>
                  </template>

                  <template v-if="!isMoreDetailsLoading && sessionMoreDetails.chairpersons.length > 0">
                    <h5>Chairpersons</h5>
                    <p class="card-text mb-4">
                      <span v-for="(chair, index) in this.sessionMoreDetails.chairpersons" :key="index" class="card-text__speaker mb-2 mb-lg-0">
                          <router-link class="btn-link font-light" :to="`/${eventRoute}/speakers/${chair.userId}`">
                        {{ fullNameWithAddress(chair) }}
                        </router-link>
                        <!-- Twitter -->
                        <template v-if="chair.twitterId && chair.twitterUrl">
                          <a :href="chair.twitterUrl" target="_blank" class="">
                            <span class="follow-speaker-twitter mr-1 ml-2"><img height="12" src="~@/assets/images/platform/logo-x.svg" alt="X Logo" /></span>
                            <span class="font-regular">{{ chair.twitterId }}</span>
                          </a>
                        </template>
                      </span>
                    </p>
                  </template>

                  <!-- judge -->
                  <template v-if="isMoreDetailsLoading">
                    <div class="mb-4">
                      <b-skeleton width="30%" class="mb-2"></b-skeleton>
                      <b-skeleton width="50%"></b-skeleton>
                    </div>
                  </template>

                  <template v-if="!isMoreDetailsLoading && sessionMoreDetails.juryAbstract.length > 0">
                    <h5>Jury Members</h5>
                    <p class="card-text">
                      <span v-for="(judge, index) in this.sessionMoreDetails.juryAbstract" :key="index" class="card-text__speaker">
                        {{ fullNameWithAddress(judge) }}
                        <!-- Twitter -->
                        <template v-if="judge.twitterId && judge.twitterUrl">
                          <a :href="judge.twitterUrl" target="_blank">
                            <span class="follow-speaker-twitter mr-1 ml-2"><img height="12" src="~@/assets/images/platform/logo-x.svg" alt="X Logo" /></span>
                            <span class="font-regular">{{ judge.twitterId }}</span>
                          </a>
                        </template>
                      </span>
                    </p>
                  </template>

                  <!-- LearningObjectives -->
                  <template v-if="isMoreDetailsLoading">
                    <div class="mb-4">
                      <b-skeleton width="40%" class="mb-2"></b-skeleton>
                      <b-skeleton width="90%"></b-skeleton>
                      <b-skeleton width="90%"></b-skeleton>
                      <b-skeleton width="90%"></b-skeleton>
                      <b-skeleton width="90%"></b-skeleton>
                    </div>
                  </template>

                  <template v-if="!isMoreDetailsLoading && sessionMoreDetails.learningObjectives.trim() !== ''">
                    <h5>More on the session</h5>
                    <p class="card-text text-white-space-pre-wrap">
                      <span>{{ sessionMoreDetails.learningObjectives | trim }}</span>
                      <span v-if="!preCongressMode && sessionMoreDetails.moreDetailsLink" class="d-block font-weight-bold">
                        <a class="session__details__more_action" :href="sessionMoreDetails.moreDetailsLink">Session and faculty details can be found here.</a>
                      </span>
                    </p>
                  </template>
                </b-col>
              </b-row>

              <!-- Presentations -->
              <template v-if="isMoreDetailsLoading">
                <b-skeleton width="15%" class="mb-2"></b-skeleton>
                <b-skeleton-img no-aspect height="80px" width="100%" class="mb-5"></b-skeleton-img>
                <b-skeleton-img no-aspect height="80px" width="100%" class="mb-1"></b-skeleton-img>
                <b-skeleton-img no-aspect height="80px" width="100%"></b-skeleton-img>
              </template>

              <template v-if="!isMoreDetailsLoading && sessionMoreDetails.presentations && sessionMoreDetails.presentations.length > 0">
                <h5 class="mb-3">Presentations</h5>

                <div v-for="(presentation, index) in sessionMoreDetails.presentations" :key="index" class="session__details__presentation px-4 py-3">
                  <span v-if="!sessionMoreDetails.isOnDemand && presentation.startTime > 0" class="session__details__presentation__start-time font-bold">
                    {{ presentation.startTime | formatUnixEpocTimePart }}
                  </span>
                  <span class="session__details__presentation__title font-black">{{ presentation.title }}</span>    
                    
                  <span v-for="(person, index) in presentation.persons" :key="index" class="session__details__presentation__person" variant="link">
                    {{ roleFormatted(person.role) }}: <router-link class="btn-link font-light" :to="`/${eventRoute}/speakers/${person.userId}`"> {{ fullNameWithAddress(person) }} </router-link>
                    <!-- Twitter -->
                    <template v-if="person.twitterId">
                      <a :href="person.twitterUrl" target="_blank">
                        <span class="follow-speaker-twitter mr-1 ml-2"><img height="12" src="~@/assets/images/platform/logo-x.svg" alt="X Logo" /></span>
                        <span class="font-regular">{{ person.twitterId }}</span>
                      </a>
                    </template>
                  </span>
                  <span v-if="indexAbstracts && presentation.scientificPresentationId && presentation.presentationAbstract" class="font-regular abstract-link d-block text-right mt-3 mt-md-0" @click="onAbstractView(presentation.scientificPresentationId)">
                      <i class="las la-4x la-t-plus-2 la-file-image"></i>
                      View abstract
                  </span>
                </div>
              </template>
            </div>
          </div>
        </b-col>

        <b-col cols="12" class="pt-3">
          <!-- Register Promo -->
          <RegisterPromoWidget />
        </b-col>

        <!-- IndustryBanners Banner(s) -->
        <b-col cols="10" md="12" offset="2" offset-md="0" class="pt-3">
          <IndustryBanners />
        </b-col>
      </b-row>
    </b-col>
    <AbstractModal :presentationId="currentAbstractModalPresentationId" :visible="abstractModalVisible"
    @abstract-modal-close="onAbstractModalClose" @abstract-modal-hidden="onAbstractModalHidden" />
  </b-container>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import { QueryHelper } from 'src/utils/query-helper';
  import { MediaApiService } from 'src/services/media-api';

  import personsMixin from 'src/mixins/personsMixin';
  import openModalMixin from 'src/mixins/openModalMixin';
  import hybridEventMixin from 'src/mixins/hybridEventMixin';

  import EscBanners from 'src/components/Banners/EscBanners';
  import IndustryBanners from 'src/components/Banners/IndustryBanners';
  import FavoriteButton from 'src/components/Buttons/FavoriteButton';
  import RegisterPromoWidget from 'src/components/Widgets/RegisterPromoWidget';
  import SessionButton from 'src/components/Buttons/SessionButton';
  import esc365Mixin from 'src/mixins/esc365Mixin';
  import AbstractModal from 'src/components/Modals/AbstractModal';


  export default {
    name: 'SessionDetails',
    mixins: [personsMixin, openModalMixin, hybridEventMixin, esc365Mixin],
    components: {
      EscBanners,
      IndustryBanners,
      FavoriteButton,
      RegisterPromoWidget,
      SessionButton,
      AbstractModal
    },
    data() {
      return {
        isMoreDetailsLoading: true,
        sessionId: null,
        sessionMoreDetails: null,
        abstractModalVisible: false,
        currentAbstractModalPresentationId: null
      };
    },
    computed: {
      ...mapGetters(['isTimelineExpanded']),
      ...mapState({
        eventId: (state) => state.app.eventId,
        eventRoute: (state) => state.app.eventRoute,
        preCongressMode: (state) => state.app.preCongressMode,
        postCongressMode: (state) => state.app.postCongressMode,
        ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
        indexAbstracts: (state) => state.app.indexAbstracts,
      }),
      showAbstractModal: {
          get() {
            return this.abstractModalVisible;
          },
          set(show) {
            if (this.abstractModalVisible != show) this.abstractModalVisible = show;
          }
        },
    },
    watch: {
      '$route.params.sessionFurl'() {
        this.fetchSession();
      },
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.fromRoute = from;
      });
    },
    mounted() {
      this.fetchSession();
    },
    methods: {
      fetchSession() {
        this.isMoreDetailsLoading = true;
        const sessionFurl = this.$route.params?.sessionFurl;
        const sessionId = QueryHelper.extractSessionId(sessionFurl);
        if (sessionId && sessionId > 0) {
          this.sessionId = sessionId;
          MediaApiService.getSessionMoreDetails(sessionId).then(({ data }) => {
            if (data) {
              this.sessionMoreDetails = data;
              this.isMoreDetailsLoading = false;
            }
          });
        }
      },
      onBackClick() {
        window.history.back();
      },
      categorieImage(categorie) {
        try {
          return require(`@/assets/images/congress/${this.eventId}/${categorie.code}.png`);
        } catch (err) {
          return null;
        }
      },

        onAbstractModalClose() {
        this.currentAbstractModalPresentationId = null;
        this.showAbstractModal = false;
      },
      onAbstractView(e) {
        //console.log(e)
        this.currentAbstractModalPresentationId = e;
        this.showAbstractModal = true;

        // this.$gtm.push({
        //   event: 'viewAbstract',
        //   eventId: this.result.event.id,
        //   title: this.result.title,
        //   presentationId: `P${this.result.presentations}`,
        //   digitalSessionId: `${this.result.digitalSessionIds ? this.result.digitalSessionIds[0] : 0}`,
        //   uniqId: `SESS${this.result.dbId ? this.result.dbId : 0}`,
        // });
      },
      onAbstractModalHidden() {
        this.currentAbstractModalPresentationId = null;
        this.showAbstractModal = false;
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  /*
  .session__details__content__title {
    max-width: 85%;
  }
*/
  .session__details__header__button {
    display: inline-block;
    position: relative;
  }

  .session__details__content__flags {
    font-size: 0.9rem;

    &__inner {
      margin-right: 1.25rem;
      .material-icons-round,
      .material-icons-outlined,
      .material-symbols-rounded {
        font-size: 22px;
      }
    }

    &__inner:last-of-type {
      margin-right: 0;
    }

    &__inner:not(:first-child) {
      border-top: solid 1px white;
    }
  }

  .session__details__presentation {
    background: $secondary;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    padding: 0.6rem 0.8rem;
    border-radius: 6px;
    margin-bottom: 1rem;

    &__title {
      display: block;
    }

    &__person {
      display: block;
    }

    &__start-time {
      display: block;
      color: var(--congress);
      font-size: 0.9rem;
    }

    img {
      vertical-align: baseline;
    }
  }

  .b-skeleton-img {
    margin-bottom: 0.8rem;
  }

  .b-custom-skeleton-wrapper {
    height: 150px;

    & .b-skeleton-img {
      margin-bottom: 0;
    }
  }

  .industry-link {
    margin-left: 10px;
  }

  .follow-speaker .la-twitter {
    color: #1da1f2;
    margin-left: 5px;
    font-size: 0.9em;
  }

  .btn-x {
    color: #343a40;
    background-color: #fff;
    border-color: #343a40;
    border-radius: 50rem !important;
    border: 2px solid;
    &:hover {
      background-color: #eee;
      border-color: #eee;
    }
  }

  .session__card__footer__action {
    color: var(--congress);
    font-size: 0.9rem;

    &:hover,
    :focus {
      text-decoration: none;
    }
  }

  .card-text__speaker {
    display: block;
  }

  .session__details__categorie_img {
    max-height: 4em;
  }

  .session__details__more_action {
    color: var(--congress);
  }
  @media (min-width: 1024px) {
    .btn__back {
      position: sticky;
      top: 140px;
      height: 40px;
    }
    .btn__back button {
      position: absolute;
    }
  }

  .disclaimer__text {
  display: inline;
}
.abstract-link {
  cursor: pointer;
  color: var(--congress);
}
</style>
