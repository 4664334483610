<template>
  <b-container fluid class="happeningNowCarousel p-0" v-if="happeningNowSessionsChunks && happeningNowSessionsChunks.length > 0">
    <div align-h="between" class="d-flex px-3 px-sm-5">
      <div>
        <h2 class="mb-0">Happening now</h2>
      </div>
      <div class="ml-auto">
        <router-link :to="`/${eventRoute}/live`" v-slot="{ href, navigate }">
          <b-button pill :href="href" @click="navigate" variant="dark"
            >View all<span class="material-icons-outlined align-middle ml-2" style="font-size:18px;"> arrow_forward </span></b-button
          >
        </router-link>
      </div>
    </div>
    <div v-if="happeningNowSessionsChunks && happeningNowSessionsChunks.length > 0">
      <b-carousel id="carousel-online" :interval="0" controls fade>
        <b-carousel-slide v-for="(chunk, index) in happeningNowSessionsChunks" :key="index" class="px-5 py-4">
          <b-row>
            <b-col v-for="session in chunk" :key="session.id" cols="12" sm="6" md="6" lg="4" xl="3" class="mb-4 card-deck m-0 p-0">
              <SessionCard
                :currentSession="session"
                :isLive="session.isLive"
                :mode="mode"
                :forceOnlineChannel="true"
                :forceProgress="true"
                :hideWatchButton="false"
                :isLiveSession="true"
              >
              </SessionCard>
            </b-col>
          </b-row>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </b-container>
</template>

<script>
  import SessionCard from 'src/components/Cards/SessionCard';
  import { constants } from 'src/constants';
  import hybridEventMixin from 'src/mixins/hybridEventMixin';
  import { MediaApiService } from 'src/services/media-api';
  import { mapGetters, mapState } from 'vuex';
  
  export default {
    name: 'HappeningNowBlock',
    mixins: [hybridEventMixin],
    props: {
      mode: {
        type: String,
        default: 'Live',
        validator: function (value) {
          // The value must match one of these strings
          return value !== null && ['Live', 'Coming', 'Replay'].indexOf(value) !== -1;
        },
      },
    },
    components: {
      SessionCard,
    },
    data() {
      return {
        sessions: [],
        fetchLiveNowSessionsJob: null,
      };
    },
    watch: {
      isPoolingEnabled(enabled) {
        if (enabled) {
          this.fetchLiveNowSessions();
        }
      },
    },
    beforeDestroy() {
      clearInterval(this.fetchLiveNowSessionsJob);
    },
    mounted() {
      this.fetchLiveNowSessions();
      // Polling live sessions...
      this.fetchLiveNowSessionsJob = setInterval(() => {
        if (this.isPoolingEnabled) {
          this.fetchLiveNowSessions();
        }
      }, this.poolingLiveSessionsInterval);
    },
    computed: {
      ...mapState({
        eventRoute: (state) => state.app.eventRoute,
      }),
      isLive() {
        return this.mode == 'Live';
      },
      sessionsBySlide() {
        let responsivechannelsPerSlide;
        if (this.$screen.xxl) responsivechannelsPerSlide = 4;
        else if (this.$screen.xl) responsivechannelsPerSlide = 4;
        else if (this.$screen.lg) responsivechannelsPerSlide = 3;
        else if (this.$screen.md) responsivechannelsPerSlide = 2;
        else if (this.$screen.sm) responsivechannelsPerSlide = 2;
        else if (this.$screen.xs) responsivechannelsPerSlide = 1;
        return responsivechannelsPerSlide;
      },
      happeningNowSessions() {
        if (this.locationTypeId === constants.EVENT_LOCATION_ONSITE) return this.sessions?.filter((s) => s.isOnsite);
        else this.locationTypeId === constants.EVENT_LOCATION_ONLINE || this.locationTypeId === constants.EVENT_LOCATION_HYBRID;
        return this.sessions?.filter((s) => s.isOnline);
      },
      happeningNowSessionsChunks() {
        return this.chunkArray(this.happeningNowSessions, this.sessionsBySlide);
      },
      poolingLiveSessionsInterval() {
        return parseInt(process.env.VUE_APP_POOLING_LIVE_SESSIONS_INTERVAL);
      },
      ...mapGetters(['isPoolingEnabled']),
    },
    methods: {
      fetchLiveNowSessions() {
        MediaApiService.getAllLiveNowSessions(this.eventId).then(({ data }) => {
          this.isLoading = false;
          if (data) {
            this.sessions = data;
          }
        });
      },
      chunkArray(array, size) {
        const chunkedArray = [];
        for (let i = 0; i < array.length; i += size) {
          chunkedArray.push(array.slice(i, i + size));
        }
        return chunkedArray;
      },
    },
  };
</script>

<style lang="scss">
  .happeningNowCarousel .carousel-control-next,
  .happeningNowCarousel .carousel-control-prev {
    background-color: #000;
    height: 40px;
    width: 36px;
    margin: auto;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: #000;
  }

  .happeningNowCarousel .carousel-control-prev {
    border-radius: 0 50px 50px 0;
  }

  .happeningNowCarousel .carousel-control-next {
    border-radius: 50px 0 0 50px;
  }

  .carousel-item {
    text-align: center;
  }

  .happeningNowCarousel .carousel-caption {
    position: static;
    padding: 0;
    min-width: 100%;
    color: #000;
  }

  @media (max-width: 576px) {
    .carousel-fade .carousel-item {
      padding-left: 1.96rem !important;
      padding-right: 1.96rem !important;
    }
    .happeningNowCarousel > div:first-child {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
</style>
