<template>
  <div>
    <!-- CountDown -->
    <h2 v-if="false" class="mb-3 text-center">{{ this.message }}</h2>
    <div class="text-center mb-0">
      <span class="countDownmessage px-3 py-1"><small>Coming in</small></span>
    </div>
    <VueCountdown :time="remainingTime" @end="onCountdownEnd">
      <template slot-scope="props">
        <div class="row countdown">
          <div class="col-3 countdown__section">
            <div class="countdown__section__value">{{ props.days }}</div>
            <div class="countdown__section__legend">Days</div>
          </div>

          <div class="col-3 countdown__section">
            <div class="countdown__section__value">{{ props.hours }}</div>
            <div class="countdown__section__legend">Hours</div>
          </div>

          <div class="col-3 countdown__section">
            <div class="countdown__section__value">{{ props.minutes }}</div>
            <div class="countdown__section__legend">Minutes</div>
          </div>

          <div class="col-3 countdown__section">
            <div class="countdown__section__value">{{ props.seconds }}</div>
            <div class="countdown__section__legend">Seconds</div>
          </div>
        </div>
      </template>
    </VueCountdown>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import { DateHelper } from 'src/utils/date-helper';
  export default {
    name: 'PreHomeCountDownWidget',
    components: {
      VueCountdown,
    },
    props: {
      message: String,
    },
    data() {
      return {
        remainingTime: 0,
      };
    },
    computed: {
      ...mapState({
        eventStartDate: (state) => state.app.eventStartDate,
      }),
    },
    mounted() {
      const now = DateHelper.browserDateTime();
      const startDate = DateHelper.browserDateTime(this.eventStartDate);
      this.remainingTime = startDate - now;
    },
    methods: {
      onCountdownEnd() {
        this.remainingTime = 0;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  .countdown {
    border: solid 2px #ffffff44;
    border-radius: 6px;
    text-align: center;
    padding-bottom: 18px;
  }

  .countdown__section {
    &:not(:last-child)::after {
      position: absolute;
      top: 12px;
      right: 0;
      content: url('~@/assets/images/platform/ellipsis-v-solid.png');
    }
  }

  .countdown__section__value {
    font-size: 2rem;
  }

  .countdown__section__legend {
    font-size: 0.9rem;
    text-align: center;
    line-height: 0.1em;
  }
</style>
