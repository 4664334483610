<template>
  <Banners :v-if="this.escBanners" :banners="this.escBanners" />
</template>

<script>
  import Banners from 'src/components/Banners/Banners';
  import { mapState } from 'vuex';
  import { DateTime } from 'luxon';

  export default {
    name: 'EscBanners',
    components: { Banners },
    props: {
      filter: {
        type: String,
        default: 'default'
      }
    },
    computed: {
      ...mapState({
        confCmsData: state => state.app.confCmsData
      }),
      escBanners() {
        const now = DateTime.utc();
        const escBanners = this.confCmsData?.banners_blocks?.filter(
          x =>
            x.banner_position == 'top' &&
            DateTime.fromFormat(x.banner_start, 'yyyy-MM-dd HH:mm', { zone: 'utc' }) <= now &&
            (!x.banner_end || DateTime.fromFormat(x.banner_end, 'yyyy-MM-dd HH:mm', { zone: 'utc' }) >= now) &&
            (x.banner_page?.length == 0 || x.banner_page?.includes(this.filter))
        );

        return escBanners;
      }
    }
  };
</script>
