import { constants } from 'src/constants';
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      appVersion: state => state.app.version,
      eventId: state => state.app.eventId,
      locationTypeId: (state) => state.app.locationTypeId,
      locationCity: (state) => state.app.locationCity,
      locationEvent: (state) => state.app.locationEvent,
      showLiveBadge: (state) => state.app.showLiveBadge,
      showChannelOnline: (state) => state.app.showChannelOnline,
    }),
    /*
    showChannelOnline() {
      //console.log("🚀 ~ file: hybridEventMixin.js ~ line 10 ~ showChannelOnline ~ showChannelOnline")
      let displayChannelOnline = null
      if (this.locationTypeId) {
        switch (this.locationTypeId) {
          case constants.EVENT_LOCATION_ONLINE:
            displayChannelOnline = true
            break
          case constants.EVENT_LOCATION_ONSITE:
          case constants.EVENT_LOCATION_HYBRID:
          default:
            displayChannelOnline = false
            break
        }
      }
      return displayChannelOnline
    },
    showLiveBadge() {
      //console.log("🚀 ~ file: hybridEventMixin.js ~ line 26 ~ showLiveBadge ~ showLiveBadge")
      let displayLiveBadge = null
      if (this.locationTypeId) {
        switch (this.locationTypeId) {
          case constants.EVENT_LOCATION_ONLINE:
            displayLiveBadge = true
            break
          case constants.EVENT_LOCATION_ONSITE:
          case constants.EVENT_LOCATION_HYBRID:
          default:
            displayLiveBadge = false
            break
        }
      }
      return displayLiveBadge
    },
    */
    /*
    locationIcon() {
      let locationIcon = ''
      if (this.locationTypeId) {
        switch (this.locationTypeId) {
          case constants.EVENT_LOCATION_ONLINE:
            locationIcon = 'la-tv'
            break
          case constants.EVENT_LOCATION_ONSITE:
          case constants.EVENT_LOCATION_HYBRID:
          default:
            locationIcon = 'la-map-marker'
            break
        }
      }
      return locationIcon
    },
    locationLabel() {
      let locationLabel = ''
      //console.log("🚀 ~ file: hybridEventMixin.js ~ line 32 ~ locationLabel ~ this.locationTypeId", this.locationTypeId)
      if (this.locationTypeId) {
        switch (this.locationTypeId) {
          case constants.EVENT_LOCATION_ONLINE:
            locationLabel = 'Channel'
            break
          case constants.EVENT_LOCATION_ONSITE:
          case constants.EVENT_LOCATION_HYBRID:
          default:
            locationLabel = 'Room'
        }
      }
      return locationLabel
    },
    */
    /*
     locationCity() {
       //console.log("🚀 ~ file: hybridEventMixin.js ~ line 77 ~ locationCity ~ location")
       return this.location?.replace(/[^\w\s]/gi, '').split(' ')[0]?.trim()
 
     }
     */
  },

  methods: {
    getChannelName(channelOnlineName, channelOnsiteName, forceOnlineChannelName) {
      let channelName = ''
      //console.log("🚀 ~ file: hybridEventMixin.js ~ line 32 ~ locationLabel ~ this.locationTypeId", this.locationTypeId)
      if (forceOnlineChannelName) {
        channelName = channelOnlineName
      }
      else if (this.locationTypeId) {
        switch (this.locationTypeId) {
          case constants.EVENT_LOCATION_ONLINE:
            channelName = channelOnlineName
            break
          case constants.EVENT_LOCATION_ONSITE:
          case constants.EVENT_LOCATION_HYBRID:
          default:
            channelName = channelOnsiteName
            break
        }
      }
      return channelName
    },
    getSessionThumbnail(session, forceOnlineChannelThumbnail) {
      if (session && session.thumbnailURL)
        return session.thumbnailURL
      else
        return this.getChannelThumbnail(session.channelCode, session.channelOnSiteCode, forceOnlineChannelThumbnail)
    },
    getChannelThumbnail(channelOnlineCode, channelOnsiteCode, forceOnlineChannelThumbnail) {
      //console.log("🚀 ~ file: hybridEventMixin.js ~ line 103 ~ getChannelThumbnail ~ getChannelThumbnail")
      let channelThumbnail = null
      //console.log("🚀 ~ file: hybridEventMixin.js ~ line 32 ~ locationLabel ~ this.locationTypeId", this.locationTypeId)
      if (forceOnlineChannelThumbnail) {
        channelThumbnail = `${this.publicPath}img/events/${this.eventId}/thumbnails/${channelOnlineCode}.png?v=${this.appVersion}`;
      }
      else if (this.locationTypeId) {
        switch (this.locationTypeId) {
          case constants.EVENT_LOCATION_ONLINE:
            if (channelOnlineCode)
              channelThumbnail = `${this.publicPath}img/events/${this.eventId}/thumbnails/${channelOnlineCode}.png?v=${this.appVersion}`;
            break
          case constants.EVENT_LOCATION_ONSITE:
          case constants.EVENT_LOCATION_HYBRID:
            if (channelOnsiteCode)
              channelThumbnail = `${this.publicPath}img/events/${this.eventId}/thumbnails/${channelOnsiteCode}.png?v=${this.appVersion}`;
            break
        }
      }
      if (!channelThumbnail) return require(`@/assets/images/congress/default-thumbnail.png`);
      else return channelThumbnail
    }
  }
};
