export default {
  methods: {
    fullNameWithAddress(person) {
      let fullNameWithAddr = `${person.firstName} ${person.lastName}`;
      if (person.address && person.address.trim() !== '') fullNameWithAddr += ` (${person.address})`;
      return fullNameWithAddr;
    },
    fullAddressFormatted(address) {
      let addressFormatted;
      if (address && address.trim() !== '') addressFormatted = ` ${address}`;
      return addressFormatted;
    },
    roleFormatted(role)
    {
      let formattedRole = role;
      //if(role && role==='Assigned Presenter' ) return 'Presenter';
      formattedRole = formattedRole.replace('Assigned Presenter','Presenter');
      return formattedRole;
    }
  }
};
