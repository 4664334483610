import { ENABLE_POOLING, DISABLE_POOLING } from 'src/store/actions';
import { SET_POOLING_ENABLED } from 'src/store/mutations';
import { consoleColors } from 'src/utils/console-color';

const initialState = { enabled: true };

const state = { ...initialState };

const getters = {
    isPoolingEnabled(state) {
        return state.enabled;
    }
};

const actions = {
    [ENABLE_POOLING]({ commit }) {
        commit(SET_POOLING_ENABLED, true);
        console.info(`%cPooling enabled`, consoleColors.events);
    },
    [DISABLE_POOLING]({ commit }) {
        commit(SET_POOLING_ENABLED, false)
        console.info(`%cPooling disabled`, consoleColors.events);
    }
};

const mutations = {
    [SET_POOLING_ENABLED](state, enabled) {
        state.enabled = enabled;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
