<template>
  <b-nav-item v-if="widgetData.to" @click="onClick" :href="widgetData.to" class="nav-crossLink">
    <img :src="widgetData.img" alt="" class="" />
  </b-nav-item>
</template>

<script>
  import { mapState } from 'vuex';
  import { constants } from 'src/constants';
  import { SET_IS_LOADING } from 'src/store/mutations';

  export default {
    name: 'CrossLinkWidget',
    props: {},
    computed: {
      ...mapState({
        appVersion: state => state.app.appVersion,
        eventId: state => state.app.eventId,
        preCongressMode: state => state.app.preCongressMode,
        postCongressMode: state => state.app.postCongressMode
      }),
      /*
      showWidget() {
        return this.eventId == constants.HF_2022_EVENT_ID || this.eventId == constants.ACNAP_2022_EVENT_ID;
      },
      */
      widgetData() {
        let crossLinkImageNamePrefix = '';
        let crossLinkToUrl = '';
        let crossLinkToAlt = '';

        if (!this.preCongressMode && !this.postCongressMode) {
          crossLinkImageNamePrefix = 'live-';
        }

        if (this.eventId == constants.HF_2022_EVENT_ID) {
          crossLinkToUrl = '/EuroHeartCare';
          crossLinkToAlt = 'ACNAP 2022';
        } else if (this.eventId == constants.ACNAP_2022_EVENT_ID) {
          crossLinkToUrl = '/Heart-Failure';
          crossLinkToAlt = 'HF 20222';
        }

        return {
          img: `/${process.env.BASE_URL}/img/events/${this.eventId}/${crossLinkImageNamePrefix}cross-link.png?v=${this.appVersion}`,
          to: crossLinkToUrl,
          alt: crossLinkToAlt
        };
      }
    },
    mounted() {},
    methods: {
      onClick() {
        this.$store.commit(SET_IS_LOADING, true);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';
</style>
