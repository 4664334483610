<template>
  <div>
    <template>
      <b-container fluid class="pl-5 pr-5 pb-5 mb-0 eventSummaryInfo">
        <b-row>
          <b-col md="6">
            <div class="mb-3 mt-5 subTitle">About the event</div>
            <div>
              <p v-html="$options.filters.formatStoryRichText(about_text)"></p>
            </div>
            <div class="mt-3" v-if="learn_more_url">
              <a :href="learn_more_url" class="register__link btn-link mt-2" target="_blank">
                Learn more <span class="material-icons-round align-middle"> chevron_right </span>
              </a>
            </div>
          </b-col>
          <b-col md="3" class="ml-md-auto">
            <div class="mb-2 mt-5 subTitle" v-if="remainingTime > 0">Starts in...</div>
            <!-- Countdown -->
            <PreHomeCountDownWidget v-if="countdown_active && remainingTime > 0" :message="countdown_text" />
            <div class="lineSeparator mt-4 mb-4" v-if="remainingTime > 0"></div>
            <div v-if="venue" class="d-flex eventLocation" :style="remainingTime > 0 ? '' : 'margin-top: 3rem'">
              <span class="material-icons-outlined align-middle mr-2"> place </span> {{ venue }}
            </div>
          </b-col>
          <b-col md="2" class="ml-md-auto">
            <div class="mb-3 mt-5 subTitle">Organised by</div>
            <div>
              <img :src="`${publicPath}img/events/${eventId}/organiser-logo.png?v=${this.appVersion}`" class="organiserLogo" />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <!-- Footer -->
    <footer class="footer">
      <b-container fluid class="footer-wrapper">
        <b-row class="footer-nav">
          <b-col cols="12" lg="1" class="footer-logo-wrapper">
            <a href="https://www.escardio.org/" target="_blank">
              <img src="~@/assets/images/platform/esc-logo-acronym-white.png" alt="ESC Logo" />
            </a>
          </b-col>

          <b-col v-for="footerEntry in footer_blocks" :key="footerEntry._uid" cols="12" lg="2" class="footer-link-wrapper">
            <template v-if="footerEntry.link_internal">
              <router-link :to="footerEntry.link_value" class="footer-link">
                {{ footerEntry.link_text }}
              </router-link>
            </template>
            <template v-else>
              <a :href="footerEntry.link_value" class="footer-link" :target="footerEntry.link_new_tab ? '_blank' : '_self'">
                {{ footerEntry.link_text }}
              </a>
            </template>
          </b-col>

          <b-col v-if="footer_terms_and_conditions_link" cols="12" lg="2" class="footer-link-wrapper">
            <a :href="footer_terms_and_conditions_link" class="footer-link" target="_blank">Terms & Conditions</a>
          </b-col>

          <b-col v-if="footer_contact_us_link" cols="12" lg="2" class="footer-link-wrapper">
            <a :href="footer_contact_us_link" class="footer-link" target="_blank">Contact us</a>
          </b-col>

          <b-col cols="12" lg="3" class="footer-link-wrapper">
            <div class="footer-link">
              Follow us on
              <div class="footer-social-link-wrapper">
                <a href="https://www.facebook.com/europeansocietyofcardiology?ref=ts" class="footer-social-link" target="_blank">
                  <img src="~@/assets/images/social/facebook.png" alt="Facebook" />
                </a>
                <a href="https://www.linkedin.com/company/european-society-of-cardiology" class="footer-social-link" target="_blank">
                  <img src="~@/assets/images/social/linkedin.png" alt="LinkedIn" />
                </a>
                <a href="https://twitter.com/escardio" class="footer-social-link" target="_blank">
                  <img src="~@/assets/images/social/x-logo-white.png" alt="Twitter" />
                </a>
                <a href="https://www.youtube.com/user/escardiodotorg" class="footer-social-link" target="_blank">
                  <img src="~@/assets/images/social/youtube.png" alt="Youtube" />
                </a>
                <a href="https://www.instagram.com/europeansocietyofcardiology" class="footer-social-link" target="_blank">
                  <img src="~@/assets/images/social/instagram.png" alt="Instagram" />
                </a>
              </div>
            </div>
          </b-col>
          <b-col v-if="confCmsData && confCmsData.footer_data_privacy" cols="12">
            <div class="data-privacy mt-5">
              <small>
                <span v-html="$options.filters.formatStoryRichTextWithoutEncapsulatingParagraph(confCmsData.footer_data_privacy)"></span>
                <span v-if="footer_terms_and_conditions_link">
                  More information is available in the {{ eventBaseTitle }}
                  <a :href="footer_terms_and_conditions_link" class="footer-link text-decoration-underline" target="_blank">registration terms and conditions</a>.
                </span>
              </small>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import PreHomeCountDownWidget from 'src/components/Widgets/PreHomeCountDownWidget';
  import { DateHelper } from 'src/utils/date-helper';

  export default {
    name: 'Footer',
    components: {
      PreHomeCountDownWidget,
    },
    data() {
      return {
        publicPath: process.env.BASE_URL,
        remainingTime: 0,
      };
    },
    computed: {
      ...mapState({
        confCmsData: (state) => state.app.confCmsData,
        eventId: (state) => state.app.eventId,
        eventBaseTitle: (state) => state.app.eventBaseTitle,
        homeCmsData: (state) => state.app.homeCmsData,
        appVersion: (state) => state.app.version,
        eventStartDate: (state) => state.app.eventStartDate,
      }),
      about_text() {
        return this.homeCmsData?.about_text;
      },
      learn_more_url() {
        return this.homeCmsData?.learn_more_url?.url;
      },
      countdown_active() {
        return this.homeCmsData?.countdown_active;
      },
      countdown_text() {
        return this.homeCmsData?.countdown_text;
      },
      venue() {
        return this.homeCmsData?.venue;
      },
      footer_blocks() {
        return this.confCmsData?.footer_blocks;
      },
      footer_terms_and_conditions_link() {
        return this.confCmsData?.footer_terms_and_conditions_link?.url;
      },
      footer_contact_us_link() {
        return this.confCmsData?.footer_contact_us_link?.url;
      },
    },
    mounted() {
      const now = DateHelper.browserDateTime();
      const startDate = DateHelper.browserDateTime(this.eventStartDate);
      this.remainingTime = startDate - now;
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  .footer {
    z-index: 3;
    padding-top: 10px;

    .footer-logo-wrapper img {
      height: 30px;
    }
  }

  @media (min-width: 992px) {
    .footer-social-link-wrapper {
      display: inline-block;
    }
  }

  .data-privacy {
    font-size: 0.9em;
    margin: 10px;
  }
</style>
