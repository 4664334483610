//import { consoleColors } from 'src/utils/console-color.js';
//import { constants } from 'src/constants';
import { router } from 'src/router/index.js';
import { store } from 'src/store';

import { getDefaultRoute } from 'src/router/default';
import { MediaApiService } from 'src/services/media-api';
import { SET_EVENT_CONFIG } from 'src/store/mutations';

async function redirectToDefaultRoute() {
  const defaultRoute = getDefaultRoute();
  await router.replace(defaultRoute);
}

export async function loadEventConfiguration(to) {
  const eventRoute = to.params?.eventRoute;
  //console.log("🚀 ~ file: eventConfig.js ~ line 17 ~ loadEventConfiguration ~ eventRoute", eventRoute)
  if (!eventRoute || eventRoute.trim() === '') {
    await redirectToDefaultRoute();
    return;
  }

  try {
    const { data } = await MediaApiService.getConfig(eventRoute);

    if (!data) return; // TODO: Redirect to an error page OR Throw ?...
    //console.log("🚀 ~ file: eventConfig.js ~ line 26 ~ loadEventConfiguration ~ data", data)

    // Parsing and propagating event config...
    const eventConfig = {
      eventRoute,
      escEventId: data.escEventId,
      eventId: data.eventId,
      eventBaseTitle: data.basePageTitle,
      eventStartDate: data.eventStartDate,
      eventEndDate: data.eventEndDate,
      preCongressMode: data.preCongressMode,
      postCongressMode: data.postCongressMode,
      ressourcesMovedTo365: data.ressourcesMovedTo365,
      brightcoveAccountId: data.brightcoveAccountId,
      brightcovePlayerId: data.brightcovePlayerId,
      registrationLink: data.registrationLink,
      cmsEndpoint: data.cmsEndpoint,
      cmsCacheVersion: data.cmsCacheVersion,
      widgetsParameters: JSON.parse(data.widgetsParameters),
      industryStandsSearch: data.industryStandsSearch,
      indexAbstracts: data.indexAbstracts,
      indexIndustries: data.indexIndustries,
      indexLiveSessions: data.indexLiveSessions,
      indexOnDemandSessions: data.indexOnDemandSessions,
      indexSpeakers: data.indexSpeakers,
      locationTypeId: data.locationTypeId,
      location: data.location,
      locationType : data.locationType,
      organiserName : data.organiserName,
      eventName : data.eventName,
      locationCity : data.locationCity
    };
    //console.log(eventConfig)
    store.commit(SET_EVENT_CONFIG, eventConfig);
  } catch (err) {
    const response = err?.response;
    console.error('An error has occurred', err);
    if (response && response.status === 404) {
      //console.log('BAD EVENT ROUTE');
      await redirectToDefaultRoute();
      return;
    } else {
      // TODO: Redirect to an error page...
    }
  }
}
