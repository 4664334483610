<template>
  <fragment>
    <fragment v-if="!mobileApp">
      <PreHomeContentV2 />

    </fragment>
    <fragment v-else>
      <MobileAppPreHome v-if="preCongressMode" />
      <MobileAppHappeningNow v-else-if="(!preCongressMode && !postCongressMode) || (postCongressMode && !ressourcesMovedTo365)" />
    </fragment>
  </fragment>
</template>

<script>
  import { mapState } from 'vuex';
  import MobileAppHappeningNow from 'src/views/MobileApp/HappeningNow';
  import PreHomeContentV2 from 'src/views/PreHome/PreHomeV2';
  import MobileAppPreHome from 'src/views/MobileApp/PreHome';
  
  export default {
    name: 'HomeSelector',
    props: {
      mobileApp: {
        type: Boolean,
        default: false
      }
    },
    components: {
      PreHomeContentV2,
      MobileAppPreHome,
      MobileAppHappeningNow,

    },
    data() {
      return {

      };
    },
    computed: {
      ...mapState({
        eventId: state => state.app.eventId,
        isInPreviewMode: state => state.app.isInPreviewMode,
        preCongressMode: state => state.app.preCongressMode,
        postCongressMode: state => state.app.postCongressMode,
        ressourcesMovedTo365: state => state.app.ressourcesMovedTo365,
        eventRoute: state => state.app.eventRoute,
      }),
    }
  };
</script>
