<template>
  <fragment>
    <!-- 
    ========================================================================================================================================================================
    HERO BANNER
    ========================================================================================================================================================================
     -->
    <b-row class="hero__banner">
      <b-col
        v-if="!isMobileAppPage && !isHomePage"
        cols="12"
        class="hero__bg pt-5 px-3 px-md-0 d-flex justify-content-left"
        :style="{ backgroundImage: `url('/congress/img/events/${eventId}/hero-bg.png?v=${this.appVersion}')` }"
      >
        <div class="eventInfo">
          <h1>Programme</h1>
          <div class="eventDate mt-3">Browse the sessions, bookmark your favourites and start the journey to your congress.</div>
          <div class="eventDate">Please note that the programme times are displayed in local times for {{ locationEvent }}.</div>
          <div v-if="this.confCmsData && this.confCmsData.paag_doc && this.confCmsData.paag_doc.filename">
            <b-button variant="link" :href="this.confCmsData.paag_doc.filename" class="d-inline-flex align-items-center mt-3 px-0"
              ><span class="material-icons-round align-middle mr-2"> system_update_alt </span> Download the Programme at a glance</b-button
            >
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- 
    ========================================================================================================================================================================
    END HERO BANNER
    ========================================================================================================================================================================
     -->

    <b-container fluid class="px-lg-5" :class="isTimelineExpanded ? 'd-block d-md-none' : 'd-block'">
      <b-row no-gutters>
        <!-- ESC Banner(s) -->
        <b-col cols="10" md="12" offset="2" offset-md="0">
          <EscBanners />
        </b-col>

        <!-- Search -->

        <b-col cols="12" :lg="hasBoostedTerms || hasTrending || hasPopularity ? 9 : 12" class="pt-3">
          <Search :searchDocType="'DigitalSession'" @is-loading="OnLoading" />
        </b-col>

        <b-col cols="12" class="pt-3">
          <!-- Register Promo -->
          <RegisterPromoWidget />
        </b-col>

        <!-- IndustryBanners Banner(s) -->
        <b-col cols="10" md="12" offset="2" offset-md="0" class="pt-3">
          <IndustryBanners />
        </b-col>
      </b-row>

      <v-tour v-if="!isMobile" name="search-tour" :steps="steps" :callbacks="callbacks" :active="isTourActive"></v-tour>
    </b-container>

    <!-- Suggestions -->
    <RecommendationBlock />

    <!-- NEW CARDS FROM SLIDER -->
    <div class="authoredSlidesBlock px-0">
      <h3 class="px-5 mb-3">You may also be interested in</h3>
      <AuthoredSlidesBlock />
    </div>
  </fragment>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import openModalMixin from 'src/mixins/openModalMixin';
  import toursManagerMixin from 'src/mixins/toursManagerMixin';
  import EscBanners from 'src/components/Banners/EscBanners';
  import IndustryBanners from 'src/components/Banners/IndustryBanners';
  import RegisterPromoWidget from 'src/components/Widgets/RegisterPromoWidget';
  import RecommendationBlock from 'src/components/Blocks/RecommendationBlock.vue';
  import Search from 'src/components/Search/Search';
  import { constants } from 'src/constants';
  import AuthoredSlidesBlock from 'src/components/Blocks/AuthoredSlidesBlock.vue';

  export default {
    name: 'Overview',
    mixins: [openModalMixin, toursManagerMixin],
    components: {
      EscBanners,
      IndustryBanners,
      Search,
      RegisterPromoWidget,
      RecommendationBlock,
      AuthoredSlidesBlock,
    },
    data() {
      return {
        isTourActive: false,
        steps: [
          {
            target: '[data-v-step-search="0"]',
            header: {
              //title: '<b>Type your search</b>'
            },
            content: `Search for authors, speakers, title or keywords.`,
            params: {
              placement: 'top',
              enableScrolling: false,
              highlight: true,
            },
          },
          {
            target: '[data-v-step-search="1"]', // We're using document.querySelector() under the hood
            header: {
              //title: '<b>Welcome to search in the programme 🙂</b>'
            },
            content: `Filter the sessions by category. The number in brackets shows the number of sessions per option.`,
            params: {
              placement: 'bottom',
              enableScrolling: false,
              highlight: true,
            },
          },
          {
            target: '[data-v-step-search="2"]',
            header: {
              //title: '<b>Search by day, types and tracks and topics</b>'
            },
            content: `Select a date and discover the daily schedule. In brackets, the number of sessions per day.`,
            params: {
              placement: 'top',
              highlight: true,
              enableScrolling: false,
            },
          },
          {
            target: '[data-v-step-search="3"]',
            header: {
              //title: '<b>Session details</b>'
            },
            content: `Change your results’ presentation.`,
            params: {
              placement: 'bottom',
              highlight: true,
              enableScrolling: false,
            },
          },
          {
            target: '[data-v-step-search="4"]',
            header: {
              //title: '<b>Session of interest</b>'
            },
            content: `Click on the icon to add a session to My Programme. Only available when you’re signed in.`,
            params: {
              placement: 'top',
              highlight: true,
              enableScrolling: false,
            },
          },
        ],
        callbacks: {
          onSkip: () => this.onTourCompleted('search'),
          onFinish: () => this.onTourCompleted('search'),
        },
        userRecos: [],
        trendingRecos: [],
        popularRecos: [],
        hasTrending: false,
        hasBoostedTerms: false,
        hasPopularity: false,
        selectedRecommendationType: null,
        selectedContentType: null,
        recommendationData: null,
      };
    },
    computed: {
      ...mapState({
        eventId: (state) => state.app.eventId,
        eventRoute: (state) => state.app.eventRoute,
        appVersion: (state) => state.app.version,
        locationEvent: (state) => state.app.locationEvent,
        preCongressMode: (state) => state.app.preCongressMode,
        postCongressMode: (state) => state.app.postCongressMode,
        confCmsData: (state) => state.app.confCmsData,
      }),
      ...mapGetters(['isTimelineExpanded', 'isLogged', 'currentUser', 'appVersion']),

      isMobileAppPage() {
        const pagesWithoutTimeline = [
          `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_PRE_HOME}`,
          `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_HAPPENING_NOW}`,
          `${constants.ROUTE_MOBILE_APP}/${constants.ROUTE_MOBILE_APP_INTERACTIVITY}`,
        ];
        return pagesWithoutTimeline.map((x) => `/${this.eventRoute}/${x}`).includes(this.$route.path);
      },
      isMobile() {
        return this.$screen.breakpoint === 'xs' || this.$screen.breakpoint === 'sm' || this.$screen.breakpoint === 'md'; // window.innerWidth <= 768; // Détecte les écrans de 768px de large ou moins comme étant des appareils mobiles
      },
      isHomePage() {
        return this.$route.path === `/${this.eventRoute}` || this.$route.path === `/${this.eventRoute}/` || this.$route.path === `/${this.eventRoute}/home`;
      },
    },
    async mounted() {},
    methods: {
      hasUserRecos() {
        return this.recommendationData.BoostedTerms && this.recommendationData.BoostedTerms.Sessions && this.recommendationData.BoostedTerms.Sessions.length > 0;
      },
      hasTrendingRecos() {
        return this.recommendationData.Trending && this.recommendationData.Trending.Sessions && this.recommendationData.Trending.Sessions.length > 0;
      },
      hasPopularRecos() {
        return this.recommendationData.Popularity && this.recommendationData.Popularity.Sessions && this.recommendationData.Popularity.Sessions.length > 0;
      },
      filterRecos(recos) {
        return recos.filter((obj, pos, arr) => {
          return obj['content'] !== null && obj['content'] !== '' && arr.map((mapObj) => mapObj['content']).indexOf(obj['content']) === pos;
        });
      },
      sanitizeId(rawId) {
        if (!rawId) return '#';
        return rawId.substring(1, rawId.length);
      },
      extractId(rawcontent) {
        if (!rawcontent) return '#';
        return parseFloat(rawcontent);
      },
      extractTitle(rawcontent) {
        if (!rawcontent) return '#';
        return rawcontent.replace(parseFloat(rawcontent), '');
      },
      OnLoading(isLoading) {
        if (!isLoading) {
          this.$nextTick(() => {
            if (!this.isMobile && !this.isTourActive) {
              this.isTourActive = true;
              if (!this.tours || !this.tours.search) this.$tours['search-tour'].start();
            }
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';

  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .v-step__header {
    background: #000;
  }

  .v-step {
    z-index: 1;
  }

  /*TEST RECOMMENDATION CARD*/
  .card {
    border: 0;
  }

  .card-body {
    padding: 0.75rem !important;
  }

  .card-body a {
    display: flex;
  }

  .card-body a:hover {
    text-decoration: none;
  }

  .card-text {
    margin-bottom: 0;
    font-size: 0.9em;
    line-height: 1.25;
  }

  .search__card__footer__action i {
    align-self: center;
  }

  ::v-deep .nav-pills .nav-link {
    font-size: 0.83em;
  }

  ::v-deep .card-header {
    border-bottom: 0;
    background: transparent;
  }

  ::v-deep .tab-pane {
    padding: 0 !important;
  }

  .authoredSlidesBlock {
    background-color: #fff;
  }
</style>
