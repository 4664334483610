<template>
  <b-overlay :show="isLoading">
    <!-- Brightcove Player -->
    <video-js
      :id="brightcovePlayerId"
      :data-account="this.accountId"
      :data-player="this.playerId"
      :data-video-id="this.videoId"
      data-embed="default"
      controls=""
      class="embed-responsive embed-responsive-16by9"
    >
    </video-js>
  </b-overlay>
</template>

<script>
export default {
  name: 'BrightcovePlayer',
  data() {
    return {
      isLoading: true,
      script: null,
      brightcovePlayerId: this.$uuid.v1(),
      brightcovePlayer: null,
    };
  },
  props: {
    accountId: String,
    playerId: String,
    videoId: String,
  },
  computed: {
    scriptUrl() {
      return `https://players.brightcove.net/${this.accountId}/${this.playerId}_default/index.min.js`;
    },
  },
  mounted() {
    this.script = document.createElement('script');
    this.script.onload = this.onScriptLoaded;
    this.script.setAttribute('src', this.scriptUrl);

    document.head.appendChild(this.script);
  },
  beforeDestroy() {
    if (this.brightcovePlayer) this.brightcovePlayer.dispose();

    document.head.removeChild(this.script);
  },
  methods: {
    onScriptLoaded() {
      const self = this;
      // eslint-disable-next-line no-undef
      videojs.getPlayer(this.brightcovePlayerId).ready(function () {
        self.brightcovePlayer = this;

        self.brightcovePlayer.on('loadedmetadata', function () {
          // +++ Get the browser language +++
          let browser_language = navigator.language || navigator.userLanguage; // IE <= 10;
          browser_language = browser_language.substr(0, 2);
          //console.log(`browser_language ${browser_language}`);
          // +++ Get the captions +++
          let tracks = self.brightcovePlayer.textTracks();
  
          // +++ Loop through captions +++          
          let track_language;
          let track_found;
          let fallback_track;
          for (var i = 0; i < tracks.length; i++) {
            track_language = tracks[i].language.substr(0, 2);

            // +++ Set the default caption language +++
            // When the caption language equals the browser language, then set it as default
            if (track_language) {
              if(track_language ==='en')
                fallback_track = tracks[i];
              if (track_language === browser_language) {
                tracks[i].mode = 'showing';
                track_found = true;
                //console.log(`browser subtitle ${track_language} activated`);
              } else {
                tracks[i].mode = 'disabled';
              }
            }

            if(!track_found && fallback_track)
            {
              fallback_track.mode = 'showing';
              //console.log(`default subtitle ${track_language} activated`);
            }
          }        
        });

        self.isLoading = false;
        self.$emit('loaded');
      });
    },
  },
};
</script>

<style lang="scss">
.video-js {
  height: 100%;
  border-radius: 0.25rem !important;
}

.b-skeleton-img {
  height: 100%;
  border-radius: 0.25rem !important;
}
</style>