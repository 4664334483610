<template>
  <div v-if="isLarge">
    <b-button pill class="btn-outline-congress" @click.stop.prevent="onFavClick">
      <svg ref="self" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" class="fill-congress" @click.stop.prevent="onFavClick">
        <use v-if="isFavorite" xlink:href="~@/assets/images/platform/Bookmark-Full.svg#Layer_1" />
        <use v-else xlink:href="~@/assets/images/platform/Bookmark.svg#Layer_1" />
        Added
      </svg>
      <span v-if="isFavorite"> Remove from My Programme </span>
      <span v-else> Add to My Programme </span>
    </b-button>
  </div>
  <div v-else>
    <svg ref="self" :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" class="fill-congress" @click.stop.prevent="onFavClick">
      <use v-if="isFavorite" xlink:href="~@/assets/images/platform/Bookmark-Full.svg#Layer_1" />
      <use v-else xlink:href="~@/assets/images/platform/Bookmark.svg#Layer_1" />
    </svg>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { ADD_FAV, REM_FAV } from 'src/store/actions';

  export default {
    name: 'FavoriteButton',
    props: {
      title: String,
      digitalSessionId: Number,
      scientificSessionId: Number,

      width: {
        type: String,
        default: '38',
      },
      height: {
        type: String,
        default: '38',
      },
      mode: {
        type: String,
        default: 'small',
        validator: function (value) {
          // The value must match one of these strings
          return value !== null && ['small', 'large'].indexOf(value) !== -1;
        },
      },
    },
    computed: {
      ...mapState({
        eventId: (state) => state.app.eventId,
        favs: (state) => state.auth.favs,
      }),
      isFavorite() {
        return this.favs.find((x) => x.digitalSessionId == this.digitalSessionId) !== undefined;
      },
      isLarge() {
        return this.mode == 'large';
      },
    },
    mounted() {
      //if (!this.title) console.warn('No title is configured for this button, it may affect analytics');
    },
    methods: {
      onFavClick() {
        let eventDetails = { eventId: this.eventId, digitalSessionId: this.digitalSessionId };
        if (!this.isFavorite) {
          this.$store.dispatch(ADD_FAV, eventDetails);
          this.$emit('favorite-add', eventDetails);
        } else {
          this.$store.dispatch(REM_FAV, eventDetails);
          this.$emit('favorite-remove', eventDetails);
        }

        //console.log('scientificSessionId', JSON.stringify(this.scientificSessionId));
        /* DataLayer */
        this.$gtm.push({
          event: 'onFavClick',
          eventId: this.eventId,
          title: this.title,
          digitalSessionId: `S${this.digitalSessionId}`,
          uniqId: `SESS${this.scientificSessionId}`,
          action: !this.isFavorite ? 'add' : 'remove',
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';
</style>
