<template>
  <b-overlay :show="isLoading" class="embed-responsive embed-responsive-1by1">
    <!-- PigeonWidget -->
    <iframe :src="pigeonUrl" class="embed-responsive-item" @load="onIframeLoaded" :class="{ 'd-none': isLoading }"></iframe>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PigeonWidget',
  props: {
    pigeonId: String
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters([ 'currentUser', 'fullName' ]),
    pigeonUrl() {
      return `https://pigeonhole.at/${this.pigeonId}&phEmail=${this.currentUser.email}&phName=${this.fullName}`;
    }
  },
  methods: {
    onIframeLoaded() {
      this.isLoading = false;
      this.$emit('loaded');
    }
  }
}
</script>

<style lang="scss" scoped>
.embed-responsive {
  height: 100%;
  border-radius: 0.25rem !important;
}

.b-skeleton-img {
  height: 100%;
  border-radius: 0.25rem !important;
}
</style>