<template>
  <!-- Consent Banner -->
  <b-row class="mt-3 mx-auto consentSwitch">
    <b-col cols="10" offset="1">
      <b-card class="border-0">
        <b-card-text class="card-title mb-3">
          <b>Networking feature</b>
          <b-form-checkbox v-model="networkingChatAgreement" :disabled="isAgreementLoading" @change="onSwitchChange" switch size="lg" class="float-right" />
        </b-card-text>
        <b-card-text>
          By enabling the networking feature, I agree and understand that:
          <ul>
            <li>I will be visible in the networking search for people to message me (chat) in one-to-one conversations.</li>
            <li>other delegates will see my name, specialty, place of work, and social media follow links that I've provided through My ESC account.</li>
            <li>the networking feature does not enable users to contact each other by other means of communication (email, phone, etc).</li>
            <li>it facilitates contact through social media for those who have shared their handles through their My ESC account</li>
          </ul>
        </b-card-text>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex';
import { MediaApiService } from 'src/services/media-api';
import { SET_CHAT_PARTICIPANT_CONFIG } from 'src/store/mutations';

export default {
  name: 'NetworkingConsent',
  data() {
    return {
      networkingChatAgreement: false,
      isAgreementLoading: false,
    };
  },
  computed: {
    ...mapState({
      chatParticipantConfig: (state) => state.app.chatParticipantConfig,
    }),
  },
  mounted() {
    this.networkingChatAgreement = this.chatParticipantConfig?.networkingChatAgreement ?? false;
  },
  methods: {
    async onSwitchChange(value) {
      this.isAgreementLoading = true;

      let alterResStatus = null;
      try {
        const { status } = await MediaApiService.alterChatAgreement({ networkingChatAgreement: value });
        alterResStatus = status;
      } catch (err) {
        console.error(err);
      } finally {
        if (alterResStatus == 200) {
          const _chatParticipantConfig = { ...this.chatParticipantConfig, networkingChatAgreement: value };
          this.$store.commit(SET_CHAT_PARTICIPANT_CONFIG, _chatParticipantConfig);
        } else {
          this.networkingChatAgreement = !value;
        }

        this.isAgreementLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/theme';

@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';
</style>
