<template>
  <div class="session-item">
      <b-row class="card session__card px-4 py-3 d-block">
        <b-row>
          <b-col class="header">
            <span v-if="result.source && result.source.startTimeUnixEpoc" class="search__card__header__date session__time font-bold">
              {{ result.source.startTimeUnixEpoc | formatUnixEpocDayPart }} • {{ result.source.startTimeUnixEpoc | formatUnixEpocTimePart }}
            </span>
            <span v-else class="search__card__header__date">On Demand</span>

            <div v-if="!isSponsoredSessionType(result.source)">
              <h4 class="card-title">{{ result.source.title }}</h4>
            </div>
            <div v-else>
              <div class="sponsored-session">
                <div class="sponsored-front">
                  <div class="card-title">Industry Session</div>
                </div>
                <div class="sponsored-back">
                  <h4 class="card-title">{{ result.source.title }}</h4>
                </div>
              </div>
            </div>

            <div class="d-block text-left lh-1">
              <div class="session__type font-regular d-block d-lg-flex">
                <div class="d-inline-flex align-items-center mr-3">
                  <span class="material-icons-outlined mr-1"> co_present </span> <span>{{ result.source.type }}</span>
                </div>
                <div class="d-inline-flex align-items-center mr-3">
                  <span class="material-icons-outlined mr-1"> access_time </span> <span>{{ calculateDuration() }}</span>
                </div>
                <div class="d-inline-flex align-items-center mr-3">
                  <span class="material-icons-outlined mr-1" v-if="result.source.roomDisplayName"> place </span>
                  <span>{{ this.showRoom(result.source.roomDisplayName, result.source.channel) }}</span>
                </div>
                <div class="d-inline-flex align-items-center mr-3">
                  <span class="material-icons-round mr-1" v-if="result.source.channel"> cast </span>
                  <span>{{ this.showChannelRoom(result.source.roomDisplayName, result.source.channel) }}</span>
                </div>
              </div>
            </div>
          </b-col>

          <b-col
            cols="2"
            xl="1"
            v-if="result.source.digitalSessionIds && result.source.digitalSessionIds && result.source.title"
            class="search__card__header__button"
          >
            <FavoriteButton
              :title="result.title"
              :digitalSessionId="parseInt(result.source.digitalSessionIds[0].substring(1))"
              :scientificSessionId="parseInt(result.source.dbId)"
              width="20"
              height="20"
              class="favoriteBtn pt-0"
            />
          </b-col>
        </b-row>
        <template v-if="!ressourcesMovedTo365">
          <b-row class="no-gutters">
            <b-col cols="12" xl="" order="1" order-xl="1" class="session__progress">
              <b-progress
                v-if="session.isLive"
                class="mt-3 search__card__body__progress session__progress"
                :value="session.progress"
                :max="session.duration"
                height="0.6em"
              ></b-progress>
            </b-col>
            <b-col v-if="(session.isLive || session.isInInterlude) && session.channelId || session.isReplay" cols="12" xl="1" order="2" order-xl="2" class="text-right text-xl-right pt-3 pt-md-0 pb-1 pb-xl-0 pl-xl-3 position-static ml-auto">
              <SessionButton  :session="session" :forceInteract="session.isReplay" class="session__card__footer__action" />
            </b-col>
            <b-col
              v-else
              xl="session-btn"
              order="2"
              order-xl="2"
              class="text-right text-xl-right pt-md-0 pb-1 pb-xl-0 pl-xl-3 position-static ml-auto"
            >
              <router-link :to="`/${eventRoute}/sessions/${result.source.furl}`" class="session__card__footer__action card-link stretched-link">
                See details <i class="las la-4x la-t-plus-2 la-arrow-right"></i
              ></router-link>
            </b-col>
          </b-row>
        </template>
        <template v-else>
        <b-col cols xl="session-btn" order="2" order-xl="2" class="text-right text-xl-right pt-3 pt-md-0 pb-1 pb-xl-0 pl-xl-3 position-static ml-auto">
        <a
          v-if="result.source.dbId"    
          :href="`${esc365SessionUrl(result.source.dbId)}`"
          class="session__card__footer__action card-link stretched-link">
          See details <i class="las la-4x la-t-plus-2 la-arrow-right"></i
        ></a>
        </b-col>
      </template>
      </b-row>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import { HtmlHelper } from 'src/utils/html-helper';
  import FavoriteButton from 'src/components/Buttons/FavoriteButton';
  import SessionButton from 'src/components/Buttons/SessionButton';
  import esc365Mixin from 'src/mixins/esc365Mixin';
  import hybridEventMixin from 'src/mixins/hybridEventMixin';

  export default {
    name: 'SearchResultsTimeline',
    mixins: [esc365Mixin, hybridEventMixin],
    components: {
      FavoriteButton,
      SessionButton,
    },
    props: {
      result: Object,
      serverTime: Number,
      query: String,
      docType: String,
    },
    data() {
      return {
        highlight: Object,
        presentationsSplit: '',
        speakers: '',
        role: '',
        fulltext: this.query,
      };
    },

    mounted() {
      this.$emit('is-loading', false);
    },

    /*
watch: {
  result() {
    if (this.result.source.presentations) this.presentationsSplit = this.result?.source?.presentations[0]?.split(',')[0];
  },
  query() {
    this.fulltext = this.query;
    this.updateRoles();
  },
},
*/
    computed: {
      ...mapState({
        eventRoute: (state) => state.app.eventRoute,
        ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
      }),

      session() {
        let sessionResult = {};
        //console.log('this.serverTime ' + DateHelper.browserDateTime(this.serverTime));
        sessionResult.startTime = this.result.source.startTimeUnixEpoc;
        sessionResult.endTime = this.result.source.endTimeUnixEpoc;
        sessionResult.id = this.result.source.digitalSessionId;
        sessionResult.externalLink = null;
        sessionResult.rooms =
          this.result.source.roomDisplayName || this.result.source.channel ? this.result.source.roomDisplayName + ' ' + this.result.source.channel : 'On demand';
        sessionResult.hasExternalLink = this.result.source.externalLink && this.result.source.externalLink !== '';
        sessionResult.platformFullVideoId = this.result.source.replayVideoId;
        sessionResult.isInInterlude = false; //this.isInInterlude;
        sessionResult.isLive = sessionResult?.startTime <= this.serverTime && sessionResult?.endTime > this.serverTime;
        sessionResult.isReplay =
          sessionResult?.startTime < this.serverTime &&
          sessionResult?.endTime < this.serverTime &&
          sessionResult?.platformFullVideoId &&
          sessionResult?.platformFullVideoId !== undefined &&
          sessionResult?.platformFullVideoId !== null &&
          sessionResult?.platformFullVideoId !== '';
        sessionResult.duration = sessionResult?.endTime - sessionResult?.startTime;
        sessionResult.durationMinutes = sessionResult?.duration / (60 * 1000);
        sessionResult.progress = this.serverTime - sessionResult?.startTime;
        sessionResult.scientificSessionId = this.result.source.dbId; //this.result.data.scientific_session_id?.raw;
        sessionResult.channelId = this.result.source.channelId;
        return sessionResult;
      },
    },
    methods: {
      /*
  updateRoles() {
    if (this.fulltext) {
      this.fulltext = this.fulltext.toLowerCase();
      this.speakers = this.result.source.contributors?.find((x) => x.fullname.toLowerCase().includes(this.fulltext));

      this.role = this.speakers ? this.speakers.role : 'Speakers';
      return this.role;
    } else {
      return '';
    }
  },
  */
      extractTopicFromSnippet(rawTopics) {
        let rawTopicsList = rawTopics;
        return rawTopicsList;
      },
      showRoom(room, channel) {
        let rst = 'On Demand';
        if (room || channel) {
          rst = room ? room : '';
        }

        return rst;
      },
      showChannelRoom(room, channel) {
        let rst = '';
        if (channel) {
          channel = channel ? 'Online' : '';
          rst = channel;
        }

        return rst;
      },

      formatContentSnippet(rawContentSnippet) {
        return HtmlHelper.decodeHTMLEntities(rawContentSnippet);
      },
      navigateToSession(event) {
        if (event.target.closest('.session__favorite__button, .search__card__footer__action')) {
          return;
        }
        this.$router.push(`/${this.eventRoute}${this.result.source.digitalUrl}`);
      },
      calculateDuration() {
        if (this.result.source.startTime && this.result.source.endTime) {
          const start = new Date(this.result.source.startTime);
          const end = new Date(this.result.source.endTime);
          const diffInMs = end - start;
          const diffInMinutes = diffInMs / 60000;
          return `${diffInMinutes} min.`;
        }
      },

      isSponsoredSessionType(session) {
        if (session.processType === 'Industry' && this.docType != 'DigitalSessionIndustry') {
          return true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .session__link {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    width: 100%;
  }

  .card {
    flex-direction: unset;
    padding: 5px;
    min-height: 90px;
    cursor: pointer;
  }

  .search__card {
    display: flex;
    height: 100%;
    border-radius: 3px;
    background: white;
    border: solid 1px $grey-lighter;
  }

  .search__card__header {
    padding: 12px 18px 1.2rem 18px;
  }

  .search__card__header__date {
    font-size: 0.8rem;
    color: $grey;
  }

  .search__card__header__button {
    display: inline-block;
    cursor: pointer;
    position: relative;
    height: 40px;
  }

  .favoriteBtn {
    text-align: end;
    padding: 8px;
  }

  .search__card__body {
    padding: 0 18px 1rem 12px;
    min-height: 210px;
  }

  .search__card__footer {
    margin-top: auto;
    padding: 8px;
    width: 100%;
    text-align: right;
  }

  .search__card__footer__action {
    color: var(--congress);
    font-size: 0.9rem;

    &:hover,
    :focus {
      text-decoration: none;
    }
  }


  .search__card__body__subtitle {
    font-size: 0.8em;
  }

  .search__card__body__duration {
    font-size: 0.85em;
    color: $grey;
  }

  .session__card__header_live-now {
    margin-left: 40px;
    color: $secondary;
    background-color: var(--congress);
    font-size: 0.6em;
    text-transform: uppercase;
    text-align: center;
    padding: 2px 8px;
    border-radius: 8px;
  }

.search__card__body__snippets {
    font-size: 0.8em;
  }

  ::v-deep em {
    font-style: italic !important;
  }
  ::v-deep blockquote em {
    font-style: italic;
    font-weight: bold;
  }


  /*ONBOARDING TOUR*/
  .search__card__header__button.v-tour__target--highlighted {
    border-radius: 0.5rem;
  }

  .v-step__header,
  .v-step,
  .v-step__arrow--dark {
    background: #000;
  }

  /* sponsored sessions reveal on hover */
  .session-item .sponsored-session {
    background-color: transparent;
  }

  .session-item .sponsored-session .sponsored-back {
    /*position: absolute;*/
    display: none;
    opacity: 0;
    /* animation */
    /*
    transform: scale(0);
    transition: all 1s ease-in-out;
    */
  }

  .session-item:hover .sponsored-session .sponsored-back,
  .session-item:focus .sponsored-session .sponsored-back {
    display: block;
    opacity: 1;
    /* animation */
    /*
    transform: scale(1);
    transition: all 0.25s ease-in-out;
    */
  }

  .session-item .sponsored-session .sponsored-front {
    /*position: absolute;*/
    display: block;
    opacity: 1;
    /* animation */
    /*
    transform: scale(1);
    transition: all 1s ease-in-out;
    */
  }

  .session-item:hover .sponsored-session .sponsored-front,
  .session-item:focus .sponsored-session .sponsored-front {
    display: none;
    opacity: 0;
    /* animation */
    /*
  transform: scale(0);
  transition: all 0.25s ease-in-out;
  */
  }
</style>
