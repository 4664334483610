import { constants } from 'src/constants';

export default {
  data() {
    return {
      tours: {
        search: false,
        overview: false,
        nav:false,
        searchCalendar:false,
        schedule: false,
        navLive: false,
      }
    };
  },
  mounted() {
    try {
      var toursState = JSON.parse(window.localStorage.getItem(constants.LOCAL_TOURS_KEY));
      if (toursState && toursState !== null) {
        this.tours.search = toursState.search;
        this.tours.overview = toursState.overview;
        this.tours.nav = toursState.nav;
        this.tours.searchCalendar = toursState.searchCalendar;
        this.tours.schedule = toursState.schedule;
        this.tours.navLive = toursState.navLive;

      }
    }
    catch (e) {
      this.tours.search = true;
      this.tours.overview = true;
      this.tours.nav = true;
      this.tours.searchCalendar = true;
      this.tours.schedule = true;
      this.tours.navLive = true;
    }
  },
  methods: {
    onTourCompleted(tour) {
      this.tours[tour] = true;
      try {
        window.localStorage.setItem(constants.LOCAL_TOURS_KEY, JSON.stringify(this.tours));
      }
      catch (e) {
        return
      }
    }
  }
};
