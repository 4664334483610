<template>
  <b-card :title="session.title" class="session__card">
    <router-link v-if="!ressourcesMovedTo365" :to="`/${eventRoute}/sessions/${session.furl}`" class="d-block stretched-link" style="height: 0"></router-link>
    <a v-else :href="`${esc365SessionUrl(session.scientificSessionId)}`" class="d-bloc stretched-link" style="height: 0"></a>
    <template #header>
      <div class="d-flex pb-2">
        <div class="session__time font-bold" v-if="!session.isOnDemand">
          {{ session.startTime | formatUnixEpocDayPart }} • {{ session.startTime | formatUnixEpocTimePart }} -
          {{ session.endTime | formatUnixEpocTimePart }}
        </div>
        <div class="session__time" v-else-if="session.isOnDemand">On Demand</div>
        <span class="session__favorite__button">
          <FavoriteButton :title="session.title" :digitalSessionId="session.id" :scientificSessionId="session.scientificSessionId" width="20" height="20" />
        </span>
      </div>
      <div class="d-block text-left lh-1">
        <div class="session__type d-inline-flex align-items-center font-regular">
          <span class="mr-1 material-icons-outlined"> co_present </span> <span>{{ session.sessionType }}</span>
        </div>
      </div>
      <div class="d-block text-left lh-1">
        <div class="session__channel d-inline-flex align-items-center font-regular mr-2">
          <span class="mr-1 material-icons-outlined"> place </span><span> {{ session.channelOnSiteDisplayName }}</span>
        </div>

        <div v-if="session.channel" class="session__location--online d-inline-flex align-items-center font-regular">
          <span class="mr-1 material-icons-round"> cast </span> <span> Online </span>
        </div>
      </div>
    </template>
    <div class="session__subtitle lh-sm" v-if="session.subTitle">
      {{ session.subTitle }}
    </div>

    <!-- <div class="session__time" v-if="isLive">({{ currentSession.duration }}mn)</div> -->

    <template #footer>
      <b-row v-if="!ressourcesMovedTo365" class="no-gutters">
        <b-col v-if="session.isLive" cols="12" xl order="1" order-xl="1" class="session__progress">
          <b-progress v-if="session.isLive" :value="session.progress" :max="session.duration" height="0.6em"></b-progress>
        </b-col>

        <b-col
          v-if="(session.isLive && session.channel) || session.isReplay"
          cols="12"
          xl="session-btn"
          order="1"
          order-xl="2"
          class="text-center text-xl-right position-static ml-auto"
        >
          <SessionButton :session="session" :forceInteract="session.isReplay" class="d-inline-block" />
        </b-col>
        <b-col v-else cols="12" xl="session-btn" order="2" order-xl="2" class="text-center text-xl-right pt-3 pt-md-0 pb-1 pb-xl-0 pl-xl-3 position-static ml-auto">
          <router-link :to="`/${eventRoute}/sessions/${session.furl}`" class="session_details text-nowrap">
            See details <i class="las la-4x la-t-plus-2 la-arrow-right"></i
          ></router-link>
        </b-col>
      </b-row>
      <b-row v-else class="no-gutters">
        <b-col cols="12" xl="session-btn" order="2" order-xl="2" class="text-center text-xl-right pt-3 pt-md-0 pb-1 pb-xl-0 pl-xl-3 position-static ml-auto">
        <a v-if="session.scientificSessionId" :href="`${esc365SessionUrl(session.scientificSessionId)}`" class="session_details text-nowrap">
          See details <i class="las la-4x la-t-plus-2 la-arrow-right"></i
        ></a>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
  import { mapState } from 'vuex';
  import FavoriteButton from 'src/components/Buttons/FavoriteButton';
  import SessionButton from 'src/components/Buttons/SessionButton';
  import esc365Mixin from 'src/mixins/esc365Mixin';
  import hybridEventMixin from 'src/mixins/hybridEventMixin';

  export default {
    name: 'ListSessionCard',
    mixins: [esc365Mixin, hybridEventMixin],
    components: {
      FavoriteButton,
      SessionButton,
    },
    props: {
      session: Object,
    },
    mounted() {},
    computed: {
      ...mapState({
        eventRoute: (state) => state.app.eventRoute,
        ressourcesMovedTo365: (state) => state.app.ressourcesMovedTo365,
      }),
      sessionDetailsUrl() {
        return `/${this.eventRoute}/sessions/${this.session.furl}`;
      },
    },
    methods: {
      onFavoriteRemove(e) {
        this.$emit('favorite-remove', e);
      },

      navigateToSession(event) {
        if (event.target.closest('.session__favorite__button, .search__card__footer__action')) {
          return;
        }
        this.$router.push(`/${this.eventRoute}/sessions/${this.session.digitalUrl}`);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
  @import 'node_modules/bootstrap/scss/_functions';
  @import 'node_modules/bootstrap/scss/_variables';
  @import 'node_modules/bootstrap/scss/mixins/_breakpoints';

  .card-title {
    color: #000;
  }

  .session__subtitle {
    font-size: 0.8em;
  }

  .session__progress {
    margin: auto;
  }

  .session_details {
    color: var(--congress);
  }

  .session_details:focus,
  .session_details:hover {
    color: var(--congress-dark);
    text-decoration: none;
  }

  .card.session__card .card-header {
    display: block;
  }
  .card.session__card {
    border-radius: 2rem;
    border: 0;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    bottom: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .card-img-top {
    border-radius: 2rem;
  }

  .session__card {
    cursor: pointer;
  }

  .session__card .card-header {
    display: flex;
    background: #fff;
    border: 0;
    padding-top: 1rem;
    padding-bottom: 0;
  }

  .session__card .session__time {
    font-size: 0.9rem;
    color: var(--congress);
  }

  .session__card .session__favorite__button {
    margin-left: auto;
    cursor: pointer;
    z-index: 100;
  }

  .session__card .material-icons-outlined,
  .session__card .material-icons-round {
    font-size: 16px;
  }

  .card-title {
    font-family: 'Interstate WGL Black';
    font-size: 1.2em;
  }

  .session__card .card-body {
    padding-top: 0.5rem;
  }

  .card-text p {
    margin-bottom: 0;
  }

  .card-footer {
    padding-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1.4rem;
  }

  @include media-breakpoint-up(xl) {
    .col-xl-session-btn {
      flex: 0 0 88px;
    }
  }
</style>
