<template>
  <b-modal id="abstract-modal" size="xl" centered  v-model="show" @show="onShow()" @hide="onHide()" @hidden="onHidden()" :no-close-on-esc="false" hide-footer no-fade>
    <template #modal-header>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <!-- close -->
            <button type="button" aria-label="Close" class="close" @click="close()">×</button>
            <h3 class="abstract__content__title">
              <!-- Title -->
              <template v-if="isLoading">
                <b-skeleton height="30px" width="100%"></b-skeleton>
              </template>

              <template v-if="!isLoading && abstract.title">
                {{ abstract.title }}
              </template>
            </h3>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template v-if="isLoading || (presentationId && abstract)">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <!-- Authors -->
            <h5 class="abstract__details__content__title">
              <template v-if="isLoading">
                <b-skeleton height="30px" width="200px"></b-skeleton>
              </template>
              <template v-if="!isLoading && abstract.authors"> Authors </template>
            </h5>
            <div class="abstract_authors mb-3">
              <template v-if="isLoading">
                <b-skeleton v-for="n in 2" :key="n" height="30px" width="100%"></b-skeleton>
              </template>

              <template v-if="!isLoading && abstract.authors">
                <span v-html="abstract.authors"></span>
              </template>
            </div>
            <!-- Content -->
            <h5 class="abstract__details__content__title">
              <template v-if="isLoading">
                <b-skeleton height="30px" width="200px"></b-skeleton>
              </template>
              <template v-else> Abstract </template>
            </h5>
            <div class="abstract_content">
              <template v-if="isLoading">
                <b-skeleton v-for="n in 10" :key="n" height="30px" width="100%"></b-skeleton>
              </template>
              <template v-else>
                <span v-if="!abstract.isUnderEmbargo" v-html="abstractContentFormatted"></span>
                <span v-else> This abstract is under embargo until {{ abstract.embargoDate | formatUnixEpocDayPart }} - {{ abstract.embargoDate | formatUnixEpocTimePart }} </span>
              </template>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { HtmlHelper } from 'src/utils/html-helper';
import { MediaApiService } from 'src/services/media-api';

export default {
  name: 'MediaModal',
  props: {
    presentationId: Number,
    visible: Boolean,
  },
  components: {},
  data() {
    return {
      isLoading: true,
      abstract: null,
    };
  },
  computed: {
    ...mapState({}),
    show: {
      get() {
        return this.visible;
      }
      /*,
      set(show) {
        if (this.visible != show) {
          if (!show) {
            //this.close();
          } else {
            this.visible = show;
          }
        }
      },*/
    },
    abstractContentFormatted: {
      get() {
        return HtmlHelper.decodeHTMLEntities(this.abstract.content);
      },
    },
  },
  methods: {
    close() {
      let eventDetails = { presentationId: this.presentationId };
      this.$emit('abstract-modal-close', eventDetails);
    },
    onShow() {
      //console.log('onShow');
      if (this.presentationId) {
        this.isLoading = true;
        this.fecthAbstract();
      }
    },
    onHide() {
      //console.log('onHide');
    },
    onHidden() {
      //console.log('onHidden');
      let eventDetails = { presentationId: this.presentationId };
      this.$emit('abstract-modal-hidden', eventDetails);
    },
    fecthAbstract() {
      MediaApiService.getPresentationAbstract(this.presentationId).then(({ data }) => {
        this.isLoading = false;
        if (data) {
          //console.log('fecthAbstract' + JSON.stringify(data));
          this.abstract = data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/_functions';
@import 'node_modules/bootstrap/scss/_variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

@import '~@/styles/theme';

::v-deep #abstract-modal .modal-header {
  border-bottom: none;
  padding: 2rem 0 1rem 2rem;
}

::v-deep #abstract-modal .modal-body {
  height: 80vh; /* max 80% of the viewport height */
  overflow-y: auto;
  padding: 1rem 2rem;
}
::v-deep #abstract-modal .modal-body .image {
  margin:auto;
    text-align: center;
}
::v-deep #abstract-modal .modal-body .table-grid table{
  margin:auto;
}
::v-deep #abstract-modal .modal-body .table-legend {
  margin:auto;
  text-align: center;
}
.abstract__content__title {
  font-size: 1.5em;
}

.abstract__details__content__title {
  font-size: 1.2em;
}

.abstract_authors,
.abstract_content {
  font-size: 0.8em;
}

/*
::v-deep #abstract-modal .abstract_content br+br
{
  display: none;
}
*/
</style>
