<template>
  <fragment v-if="preCongressMode && homeCmsData && homeCmsData.quizz_active">
    <!-- Quiz -->
    <div class="quiz-hero d-xl-none d-lg-block d-md-block d-sm-block bv-d-xs-down-block text-center mt-3">
      <h5>{{ homeCmsData.quizz_text }}</h5>
      <p>
        <b-button variant="outline-light" :href="homeCmsData.quizz_link.url" target="_blank"
          >{{ homeCmsData.quizz_link_text }}
          <i class="las la-long-arrow-alt-right"></i>
        </b-button>
      </p>
    </div>
  </fragment>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'TakeQuizzMobileWidget',
    computed: {
      ...mapState({
        homeCmsData: state => state.app.homeCmsData,
        preCongressMode: (state) => state.app.preCongressMode
      })
    },
    mounted() {
      // If needed...
    }
  };
</script>

<style lang="scss" scoped>
  @import '~@/styles/theme';
</style>
