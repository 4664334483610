import Vue from 'vue';
import Vuex from 'vuex';

import app from 'src/store/modules/app';
import auth from 'src/store/modules/auth';
import pooling from 'src/store/modules/pooling';
import modal from 'src/store/modules/modal';
import scroll from 'src/store/modules/scroll';
import partner from 'src/store/modules/partner';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    app,
    auth,
    pooling,
    modal,
    scroll,
    partner
  }
});
